import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async getDropdownListProcedure() {
    try {
      const response = await axios.get('oneGate/procedure/getAllProcedures')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return mapDropdown(data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getDropdownListProcess(params) {
    try {
      const response = await axios.get(`oneGate/process/findByProcedureId/${params.procedureId}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return mapDropdown(data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getDropdownListStepProcess(params) {
    try {
      const response = await axios.get(`oneGate/stepProcess/getStepByProcessId/${params.processId}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return mapDropdown(data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getTemplateFiles({ params, body }) {
    try {
      const response = await axios.post('oneGate/template-files/getTemplateFiles', body, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        // const { records, total } = data.data
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getMediaIdsByTemplateId(templateId) {
    try {
      const response = await axios.get(`oneGate/media-file/getAllByTemplateId/${templateId}`)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async downloadTemplate(params) {
    try {
      const response = await axios.get('oneGate/template-files/download', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async saveTemplateFile(body) {
    try {
      const response = await axios.post('oneGate/template-files/createTemplateFile', body)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async updateTemplateFile(body) {
    try {
      const response = await axios.put(`oneGate/template-files/updateTemplateFile/${body.id}`, body)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async deleteTemplateFile(id) {
    try {
      const response = await axios.delete(`oneGate/template-files/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async uploadTemplateFile({ formData, type }) {
    try {
      const response = await axios.post(
        'oneGate/template-files/uploadTemplateFile',
        formData, // Truyền `formData` trực tiếp
        {
          params: { type }, // Truyền `type` qua query params
          headers: {
            'Content-Type': 'multipart/form-data', // Đặt Content-Type phù hợp với upload file
          },
        },
      )

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  // eslint-disable-next-line consistent-return
  async deleteListMediaId(mediaIds) {
    try {
      const response = await axios.post('uni/media/deleteListMediaId', mediaIds)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async deleteMediaFile(mediaFileId) {
    try {
      const response = await axios.delete(`oneGate/media-file/${mediaFileId}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
  },
}
