import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    allSemesters: [],
    allTrainingSystems: [],
    listTrainingSystemCourses: [],
    listCourseMajors: [],
    listCourseMajorClasses: [],
    listCourseMajorProgramme: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    allSemesters: state => state.allSemesters,
    allTrainingSystems: state => state.allTrainingSystems,
    listTrainingSystemCourses: state => state.listTrainingSystemCourses,
    listCourseMajors: state => state.listCourseMajors,
    listCourseMajorClasses: state => state.listCourseMajorClasses,
    listCourseMajorProgramme: state => state.listCourseMajorProgramme,
  },
  mutations: {
    SET_ALL: (state, { dataTrainingSystem, dataSemester }) => {
      state.allTrainingSystems = dataTrainingSystem
      state.allSemesters = dataSemester
    },
    SET_LISTTRAININGSYSTEMCOURSES: (state, { data }) => {
      state.listTrainingSystemCourses = data
    },
    SET_LISTCOURSEMAJORS: (state, { data }) => {
      state.listCourseMajors = data
    },
    SET_LISTCOURSEMAJORCLASSES: (state, { data }) => {
      state.listCourseMajorClasses = data
    },
    SET_LISTCOURSEMAJORPROGRAMME: (state, { data }) => {
      state.listCourseMajorProgramme = data
    },
    SET_DATA: (state, { data }) => {
      state.dataLists = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportStudentMajorStatus', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            data: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async begin({ commit }) {
      try {
        const rsTrainingSystem = await axios.get('uni/exemptions/training_systems')
        const rsSemester = await axios.get('/uni/reports/reportClassReceiptCourseSemester/semesters')
        if (rsTrainingSystem.data.code === ApiCode.SUCCESS && rsSemester.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', {
            dataTrainingSystem: rsTrainingSystem.data.data.rs,
            dataSemester: rsSemester.data.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListTrainingSystemCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/exemptions/courses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTTRAININGSYSTEMCOURSES', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListCourseMajors({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajors', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTCOURSEMAJORS', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListCourseMajorClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajorsClasses', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTCOURSEMAJORCLASSES', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getListCourseMajorProgramme({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajorProgramme', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LISTCOURSEMAJORPROGRAMME', {
            data: response.data.data.rs,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
