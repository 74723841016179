<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'
import api from './api'

export default {
  name: 'ProgrammeSubjectImport',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    ToastificationContent,
  },

  props: {
  },
  data() {
    return {
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'creditClassCode', label: 'Mã lớp độc lập', sortable: false, type: 'string',
          },
          {
            field: 'usernameTeacher', label: 'Tài khoản Giảng viên', sortable: false, type: 'string',
          },
          {
            field: 'roomCode', label: 'Mã phòng học', sortable: false, type: 'string',
          },
          {
            field: 'dateStart', label: 'Ngày áp dụng', sortable: false, type: 'string',
          },
          {
            field: 'dayTurn', label: 'Thứ - Tiết', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      dataExcelHandle: [],
      serverParams: {
        perPage: 10,
        page: 1,
      },
      arrCode: [],
      totalRecords: 0,
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      total: 'rooms/total',
    }),
  },

  watch: {

  },
  methods: {
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            !excellist[0].hasOwnProperty('MA_LOP_DOC_LAP')
              || !excellist[0].hasOwnProperty('TAI_KHOAN_GIANG_VIEN')
              || !excellist[0].hasOwnProperty('MA_PHONG_HOC')
              || !excellist[0].hasOwnProperty('NGAY_AP_DUNG')
              || !excellist[0].hasOwnProperty('THU_TIET')
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            const obj = {
              stt: k + 1,
              creditClassCode: item.MA_LOP_DOC_LAP,
              usernameTeacher: item.TAI_KHOAN_GIANG_VIEN,
              roomCode: item.MA_PHONG_HOC,
              dateStart: item.NGAY_AP_DUNG,
              dayTurn: item.THU_TIET,
            }
            this.dataExcel.push(obj)
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length

          // insert ban ghi
        } catch (e) {
          return alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
    },
    toFindDuplicates(array) {
      return array.filter((item, index) => array.indexOf(item) !== index)
    },
    toRemoveDuplicate(array) {
      return [...new Set(array)]
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    async importSchedule() {
      const data = this.dataExcel
      await this.handleImportSchedule(data)
    },

    async handleImportSchedule(data) {
      this.loading = true
      try {
        const res = await api.importScheduler(data)
        if (res.code === 1) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success', '')
          this.resetInputFile()
          this.$emit('succeed')
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', '')
          this.resetInputFile()
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      } finally {
        this.loading = false
      }
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 170 },
        { index: 3, width: 130 },
        { index: 4, width: 130 },
        { index: 5, width: 130 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_LOP_DOC_LAP',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 2,
            value: 'TAI_KHOAN_GIANG_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 3,
            value: 'MA_PHONG_HOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 4,
            value: 'NGAY_AP_DUNG',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
          {
            index: 5,
            value: 'THU_TIET',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
            },
          },
        ],
      }

      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')

      workbook.save('template.xlsx')
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

  },
}
</script>

<style scoped>

</style>
