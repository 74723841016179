<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckbox, BFormRadio, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import apiMain from '@/views/ninhbinh/hluv-import-score/api'

export default {
  name: 'Student',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    BFormInput,
    ToastificationContent,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'creditClassCode', label: 'Mã lớp độc lập(*)', sortable: false, type: 'string',
          },
          {
            field: 'studentCode', label: 'Mã SV(*)', sortable: false, type: 'string',
          },
          {
            field: 'studentName', label: 'Tên(*)', sortable: false, type: 'string',
          },
          {
            field: 'birthday', label: 'Ngày sinh', sortable: false, type: 'string',
          },
          {
            field: 'score1', label: 'HS2', sortable: false, type: 'string',
          },
          {
            field: 'score2', label: 'HS3', sortable: false, type: 'string',
          },
          {
            field: 'score3', label: 'HS5', sortable: false, type: 'string',
          },
          {
            field: 'subjectCode', label: 'Mã môn học', sortable: false, type: 'string',
          },
          {
            field: 'subjectName', label: 'Tên môn học', sortable: false, type: 'string',
          },
          {
            field: 'TC', label: 'Tín chỉ', sortable: false, type: 'string',
          },
          {
            field: 'classCode', label: 'Lớp ổn định', sortable: false, type: 'string',
          },
          {
            field: 'semesterCode', label: 'Học kỳ', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 20,
      },
      loading: false,
      courseId: null,
    }
  },
  computed: {
  },
  async created() {
  },
  methods: {
    async importExcel(e) {
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const wsname = workbook.SheetNames[0]
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })

          if (worksheet.length === 0) {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }

          // Kiểm tra định dạng file
          const requiredKeys = [
            'malopDL',
            'MaSV',
            'Hovaten',
            'DOB',
            'HS2',
            'HS3',
            'HS5',
            'Mamon',
            'TenMon',
            'TC',
            'Lopondinh',
            'HocKy',
          ]
          const isValidFormat = requiredKeys.every(key => worksheet[0].hasOwnProperty(key))
          if (!isValidFormat) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          // Xử lý dữ liệu
          this.dataExcel = worksheet.map((item, index) => ({
            stt: index + 1,
            creditClassCode: item.malopDL,
            studentCode: item.MaSV,
            studentName: item.Hovaten,
            birthday: item.DOB,
            score1: item.HS2,
            score2: item.HS3,
            score3: item.HS5,
            subjectCode: item.Mamon,
            subjectName: item.TenMon,
            TC: item.TC,
            classCode: item.Lopondinh,
            semesterCode: item.HocKy,
          }))

          // Hiển thị dữ liệu lên bảng
          this.table.items = this.dataExcel.slice(0, this.serverParams.perPage) // Hiển thị tối đa 10 dòng
          this.totalRecords = this.dataExcel.length
        } catch (e) {
          alert(`Read failure! ${e}`)
        }
      }

      fileReader.readAsBinaryString(files[0])
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    async importData() {
      try {
        this.loading = true
        const body = {
          courseId: this.courseId,
          data: this.dataExcel,
        }
        const res = await apiMain.importScoreDhhl(body)
        if (res.isSuccessful) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success', 'Thêm dữ liệu thành công')
          this.resetInputFile()
        } else {
          this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger', res.message)
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      } finally {
        this.loading = false
      }
    },

    // handle even
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    onPageChange(params) {
      this.serverParams.page = params.currentPage
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

  },
}
</script>

<style type="text/css">
.form_file{
  width: auto !important;
}
</style>
