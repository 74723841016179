<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="conductCriteriaSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm tiêu chí đánh giá' : 'Cập nhật tiêu chí đánh giá'"
      size="lg"
      :no-close-on-backdrop="true"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên tiêu chí đánh giá <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên tiêu chí đánh giá"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model.trim="targetItem.name"
                  name="name"
                  placeholder="Nhập tên tiêu chí đánh giá"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã tiêu chí đánh giá <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã tiêu chí đánh giá"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model.trim="targetItem.code"
                  name="code"
                  placeholder="Nhập mã tiêu chí đánh giá"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="maxScore">
              <template v-slot:label>
                Điểm tối đa cho tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm tối đa cho tiêu chí"
                rules="required"
              >
                <b-form-input
                  id="maxScore"
                  v-model.trim="targetItem.maxScore"
                  name="maxScore"
                  placeholder="Điểm tối đa cho tiêu chí"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('conductCriteriaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { Flag } from '@/const/flag'
import { STATUSES } from '@/const/status'
import api from './api'

export default {
  name: 'ConductCriteriaSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        maxScore: '',
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        maxScore: '',
        status: Flag.ACTIVE,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await api.createConductCriteria(this.targetItem)
            : await api.updateConductCriteria(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('conductCriteriaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
