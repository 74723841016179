import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/hbh_examination_schedulers',
    name: 'examinationSchedulerHbh',
    component: () => import('@/views/cdsphoabinh/examination-scheduler/ExaminationSchedulerHbh.vue'),
    meta: {
      pageTitle: 'Dự kiến lịch thi',
      breadcrumb: [
        {
          text: 'Quản lý thi',
          active: false,
        },
        {
          text: 'Dự kiến lịch thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.HBH_EXAMINATION_SCHEDULER,
    },
  },
]
