<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row class="mt-1">
              <b-col cols="3">
                <b-form-group label-for="code">
                  <b-form-input
                    id="code"
                    v-model="params.dossierCode"
                    placeholder="Nhập mã hồ sơ"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <v-select
                    v-model="params.procedureId"
                    :options="procedures"
                    placeholder="Thủ tục"
                    :reduce="option => option.id"
                    label="name"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <v-select
                    v-model="params.procedureTypeId"
                    placeholder="Loại thủ tục"
                    label="name"
                    :options="procedureType"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <v-select
                    v-model="params.status"
                    placeholder="Trạng thái"
                    :options="statuses"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="fetchData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" /> Lọc
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="mt-1">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ params.itemsPerPage * (params.page - 1) + 1 }}
                  đến {{
                    (params.itemsPerPage * (params.page) >= totalRows) ? totalRows : params.itemsPerPage * (params.page)
                  }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                class="my-table"
                style-class="vgt-table striped bordered research-table"
                row-style-class="vgt-row"
                :columns="columns"
                :rows="dossiers"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                line-numbers
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'dateSubmit'">
                    {{ formatDate(props.row.dateSubmit) }}
                  </span>

                  <span v-else-if="props.column.field === 'dateReceive'">
                    {{ formatDate(props.row.dateReceive) }}
                  </span>

                  <span v-else-if="props.column.field === 'type'">
                    {{ getTypeName(props.row.type) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.detailsDossierModal
                      variant="success"
                      class="btn-icon"
                      size="sm"
                      @click="viewDetail(props.row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb- mt-1">
                      <span class="text-nowrap"> Hiển thị {{ params.itemsPerPage * (params.page - 1) + 1 }}
                        đến {{ params.itemsPerPage * (params.page) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="params.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="params.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <DossierDetail
      ref="detailsDossierForm"
      :dossier-detail="currentDossier"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BForm, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { handleCatch, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import axios from '@/libs/axios'
import { DOSSIER_STATUS } from '@/const/status'
import moment from 'moment'
import vSelect from 'vue-select'
import { DossierStatus } from '@/const/dossier-status'
import DossierDetail from './DossierDetail.vue'

export default {
  name: 'Procedure',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BForm,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    DossierDetail,
    vSelect,
  },
  data() {
    return {
      departmentSelected: undefined,
      isLoading: false,
      params: {
        page: 1,
        itemsPerPage: 10,
        dossierCode: null,
        procedureId: null,
        procedureTypeId: null,
        status: null,
      },
      totalRows: 0,
      dossiers: [],
      statuses: DOSSIER_STATUS.filter(status => status.value !== DossierStatus.DRAFT),
      procedures: [],
      procedureType: [],
      currentDossier: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã hồ sơ',
          field: 'dossierCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên thủ tục',
          field: 'procedureName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên sinh viên',
          field: 'studentName',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nộp hồ sơ',
          field: 'dateSubmit',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày trả kết quả',
          field: 'dateReceive',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mô tả',
          field: 'description',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Lý do từ chối',
          field: 'rejectReason',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thông tin cần bổ sung',
          field: 'additionalRequirements',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Hành động',
          field: 'action',
          width: '100px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.OG_DOSSIER)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.OG_DOSSIER)
    },
  },
  async created() {
    try {
      await Promise.all([this.fetchData(), this.fetchProcedures(), this.fetchTypeProcedures()])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
  methods: {
    isSystemAdmin,
    async fetchData() {
      this.isLoading = true
      try {
        const response = await axios.get('oneGate/dossiers', { params: this.params })
        this.dossiers = response.data.data.records
        this.totalRows = response.data.data.total
        // eslint-disable-next-line no-restricted-syntax
        for (const dossier of this.dossiers) {
          if (dossier.id === this.currentDossier.id) {
            this.currentDossier = dossier
            break
          }
        }
      } catch (e) {
        handleCatch(e)
      } finally {
        this.isLoading = false
      }
    },
    async fetchProcedures() {
      try {
        const response = await axios.get('oneGate/procedure/getAllProcedures')
        this.procedures = response.data.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async fetchTypeProcedures() {
      try {
        const response = await axios.get('oneGate/typeProcedure/getAllTypeProcedures')
        this.procedureType = response.data.data
      } catch (e) {
        handleCatch(e)
      }
    },
    viewDetail(dossier) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = dossier
      this.currentDossier = rest
    },
    onUpdateStatus(dossier) {
      this.currentDossier = dossier
    },
    updateParams(newProps) {
      this.params = { ...this.params, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ page: param.currentPage })
      await this.fetchData()
    },
    async onPerPageChange(param) {
      this.updateParams({ itemsPerPage: param.currentPerPage })
      await this.fetchData()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getTypeName(id) {
      return this.procedureType.find(type => type.value === id).label
    },
    async onSucceed() {
      await this.fetchData()
    },
    formatDate(date) {
      return date ? moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('YYYY/MM/DD HH:mm:ss') : ''
    },
  },
}
</script>

<style scoped>

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
