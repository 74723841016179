<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row>
      <b-col cols="12">
        <b-button
          class="mb-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="sendStudyResult"
        >
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Gửi kết quả học tập cho phụ huynh
                </span>
        </b-button>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <vue-good-table
              :columns="columns"
              :rows="curriculumVitaeLearnings"
              :pagination-options="paginationOptions"
              :line-numbers="true"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'countedGpa'">
                  <template v-if="props.row.countedGpa === 1">
                    <feather-icon
                      icon="CheckIcon"
                      size="20"
                    />
                  </template>
                  <template v-else>
                    <feather-icon
                      icon="XIcon"
                      size="20"
                    />
                  </template>
                </span>
                <!-- Column: Common -->
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị 1 đến </span>
                    <b-form-select
                      v-model="itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BButton,
  BCard, BCardBody, BCol, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CurriculumVitaeLearning',
  components: {
    BButton,
    VueGoodTable,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormSelect,
    BPagination,
  },
  props: {
    curriculumVitaeLearnings: {
      type: Array,
      default: () => [],
    },
    studentClassId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học phần',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học phần',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Mã học phần',
          field: 'subjectCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã học phần',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số tín chỉ',
          field: 'totalCreditNum',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm thi L1',
          field: 'firstScore',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm thi L2',
          field: 'secondScore',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tính điểm',
          field: 'countedGpa',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm TB',
          field: 'averagePoint',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm chữ',
          field: 'alphabetPoint',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          sortable: true,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      sendMailStudyResultForParent: 'curriculumVitae/sendMailStudyResult',
    }),
    async sendStudyResult() {
      this.isLoading = true
      try {
        const response = await this.sendMailStudyResultForParent(this.studentClassId)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
