<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Hệ đào tạo"
                  label-for="trainingSystem"
                >
                  <v-select
                    id="trainingSystem"
                    v-model="filterExport.trainingSystemId"
                    label="name"
                    :options="trainingSystems"
                    :reduce="option => option.id"
                    :clearable="false"
                    @input="onTrainingSystemsChange"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="course"
                >
                  <v-select
                    id="course"
                    v-model="filterExport.courseId"
                    label="name"
                    :options="courses"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Ngành học"
                  label-for="majorId"
                >
                  <v-select
                    label="name"
                    v-model="filterExport.majorId"
                    :options="allMajors"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Năm"
                  label-for="year"
                >
                  <v-select
                    v-model="filterExport.yearId"
                    label="name"
                    :options="years"
                    :reduce="option => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="text-danger">Chọn đầy đủ khóa học và ngành học để lấy báo cáo tổng hợp kết quả rèn luyện theo khóa; năm học và ngành học để lấy báo cáo tổng hợp kết quả rèn luyện theo năm </p>
              </b-col>
            </b-row>
            
            <b-row>
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportExcel('excel')"
                    class="mr-1"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportPdf('pdf')"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất pdf
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'StudentConductReport',
  components: {
    BPagination,
    BFormSelect,
    BButton,
    BCard,
    BRow,
    BForm,
    BOverlay,
    vSelect,
    BFormGroup,
    BCol,
    BContainer,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filterExport: {
        trainingSystemId: null,
        courseId: null,
        majorId: null,
        yearId: null,
      },
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên lớp',
          field: 'className',
          thClass: 'text-center',
        },
        {
          label: 'Tổng số',
          field: 'tongSo',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Xuất sắc',
          field: 'xuatSac',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tốt',
          field: 'tot',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Khá',
          field: 'kha',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'TB Khá',
          field: 'tbKha',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trung bình',
          field: 'trungBinh',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Yếu',
          field: 'yeu',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Kém',
          field: 'kem',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'studentConduct/trainingSystems',
      courses: 'studentConduct/courses',
      allMajors: 'studentConduct/allMajors',
      years: 'studentConduct/years',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    isValid() {
      if (!this.filterExport.yearId && !this.filterExport.majorId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn năm và ngành học!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!this.filterExport.courseId && !this.filterExport.majorId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn khóa và ngành học!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      return true
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId }),
        this.getMajorsByOrganizationId({ organizationId: this.user.orgId }),
        this.getYears(),
      ])
      if (this.trainingSystems.length > 0) {
        this.trainingSystemId = this.trainingSystems[0].id
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'studentConduct/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'studentConduct/getCoursesByTrainingSystemId',
      getMajorsByOrganizationId: 'studentConduct/getMajorsByOrganizationId',
      getYears: 'studentConduct/getYears',
      downloadPdfByYear: 'studentConduct/downloadPdfByYear',
      downloadPdfByCourse: 'studentConduct/downloadPdfByCourse',
    }),
    async onTrainingSystemsChange(event) {
      this.filterExport.courseId = null
      await this.getCoursesByTrainingSystemId({
        organizationId: getUser().orgId,
        trainingSystemId: event,
      })
    },
    updateParams(newProps) {
      this.filterExport = { ...this.filterExport, ...newProps }
    },
    async exportExcel(type) {
      this.filterExport.type = type;
      const data = this.filterExport.courseId ?
        await this.downloadPdfByCourse(this.filterExport)
        : await this.downloadPdfByYear(this.filterExport);
      
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ket_qua_ren_luyen.xls`); // Customize filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportPdf(type) {
      this.filterExport.type = type
      const data = this.filterExport.courseId ?
        await this.downloadPdfByCourse(this.filterExport)
        : await this.downloadPdfByYear(this.filterExport);
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      window.open(url, '_blank')
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
