var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"scholarshipFundCriteriaSaveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"scholarshipFundCriteriaSaveModal","body-class":"position-static","centered":"","title":_vm.isCreated ? 'Thêm tiêu chí' : 'Cập nhật tiêu chí',"no-close-on-backdrop":true},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isCreated),expression:"isCreated"},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.onSave}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu và tiếp tục ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('scholarshipFundCriteriaSaveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Tên tiêu chí","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Tên tiêu chí","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Nhập tên tiêu chí","state":_vm.getElementState(errors)},model:{value:(_vm.targetScholarshipFundCriteria.name),callback:function ($$v) {_vm.$set(_vm.targetScholarshipFundCriteria, "name", $$v)},expression:"targetScholarshipFundCriteria.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Trạng thái","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statusOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.targetScholarshipFundCriteria.status),callback:function ($$v) {_vm.$set(_vm.targetScholarshipFundCriteria, "status", $$v)},expression:"targetScholarshipFundCriteria.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Thứ tự","label-for":"orderNo"}},[_c('validation-provider',{attrs:{"name":"Thứ tự","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNo","name":"orderNo","placeholder":"Nhập thứ tự","state":_vm.getElementState(errors)},model:{value:(_vm.targetScholarshipFundCriteria.orderNo),callback:function ($$v) {_vm.$set(_vm.targetScholarshipFundCriteria, "orderNo", $$v)},expression:"targetScholarshipFundCriteria.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-overlay',{attrs:{"variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","no-wrap":"","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }