<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="templateFileSaveModal"
      body-class="position-static"
      centered
      modal-class="custom-modal-size"
      :title="isCreated ? 'Thêm mới' : 'Cập nhật'"
      :no-close-on-backdrop="false"
      no-close-on-esc
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã tài liệu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã tài liệu"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Mã tài liệu"
                  :disabled="!isCreated"
                  :state="getElementState(errors)"
                  aria-describedby="input-1-live-feedback"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên tài liệu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên tài liệu"
                rules="required"
              >
                <b-form-input
                  id="des"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Tên tài liệu"
                  :state="getElementState(errors)"
                  aria-describedby="input-1-live-feedback"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="procedureId">
              <template v-slot:label>
                Thủ tục <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thủ tục"
                vid="procedureId"
                rules="required"
              >
                <v-select
                  v-model="targetItem.procedureId"
                  label="label"
                  :options="listProcedure"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                  @input="onChangeProcedure"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="processId">
              <template v-slot:label>
                Quy trình <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Quy trình"
                vid="processId"
                rules="required"
              >
                <v-select
                  v-model="targetItem.processId"
                  label="label"
                  :options="listProcessByProcedureId"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                  @input="onChangeProcess"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="stepId">
              <template v-slot:label>
                Bước xử lý <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Bước xử lý"
                vid="stepId"
                rules="required"
              >
                <v-select
                  v-model="targetItem.stepId"
                  label="label"
                  :options="listStepByProcessId"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="type">
              <template v-slot:label>
                Loại tài liệu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại tài liệu "
                vid="type"
                rules="required"
              >
                <v-select
                  v-model="targetItem.type"
                  label="label"
                  :options="fileTypeOptions"
                  :disabled="!isCreated"
                  :reduce="option => option.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  id="status"
                  v-model="targetItem.status"
                  :reduce="option => option.value"
                  :options="statusOptions"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="description">
              <template v-slot:label>
                Mô tả tài liệu
              </template>
              <validation-provider
                name="Mô tả tài liệu"
              >
                <b-form-input
                  id="des-file"
                  v-model="targetItem.description"
                  name="description"
                  placeholder="Mô tả"
                  aria-describedby="input-1-live-feedback"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-form-group>
              <validation-provider
                name="linking"
              >
                <b-form-checkbox
                  v-model="targetItem.isRequired"
                  :value="1"
                  :unchecked-value="0"
                >
                  Người nộp cần upload file đính kèm
                </b-form-checkbox>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="File"
              label-for="file"
            >
              <b-form-file
                ref="fileInput"
                multiple
                accept=".pdf, .docx, .doc"
                :placeholder="placeholder"
                no-file-name
                :reset="true"
                @change="uploadFile"
              />
              <template #file-name>
                <span>{{ placeholder }}</span>
              </template>
            </b-form-group>

            <div
              v-if="uploadedFiles.length"
              class="uploaded-files mt-2"
            >
              <h5 class="uploaded-files-title">
                Danh sách file đã tải lên:
              </h5>
              <ul class="uploaded-files-list list-group">
                <li
                  v-for="file in uploadedFiles"
                  :key="file.mediaId"
                  class="uploaded-files-item list-group-item d-flex justify-content-between align-items-center"
                >
                  <div class="uploaded-file-info d-flex align-items-center">
                    <feather-icon
                      icon="FileIcon"
                      class="mr-2"
                    />
                    <span
                      class="uploaded-file-name"
                      :title="'Bấm vào để tải về ' + file.name"
                      @click="onDownload(file.objectPath)"
                    >{{ file.name }}</span>
                  </div>
                  <b-button
                    title="Xóa"
                    placement="bottom"
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                    @click="removeFile(file)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="handleSaveData"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="handleSaveData('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('templateFileSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { STATUSES, TEMPLATE_FILE_TYPE } from '@/const/status'
import Ripple from 'vue-ripple-directive'
import api from '@/views/one-gate/template-files/template-file-api'
import { TemplateFileStatus } from '@/const/dossier-status'

export default {
  name: 'FormActionTemplate',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BOverlay,
    BModal,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      templateType: [],
      mediaIds: [],
      uploadedFiles: [],
      nameFile: null,
      placeholder: 'Chọn file để tải tài liệu...',
      required,
      targetItem: {
        name: '',
        code: '',
        type: '',
        description: '',
        procedureName: null,
        procedureId: null,
        processName: null,
        processId: null,
        stepId: null,
        stepName: null,
        mediaIds: [],
        status: 1,
        orderNo: 1,
        isRequired: 1,
      },
      listProcedure: [],
      listProcessByProcedureId: [],
      listStepByProcessId: [],
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    isCreated() {
      return !this.item
    },
    fileTypeOptions() {
      return TEMPLATE_FILE_TYPE
    },
    statusOptions() {
      return STATUSES
    },
  },
  mounted() {
    const style = document.createElement('style')
    style.innerHTML = `
      .custom-modal-size .modal-dialog {
        max-width: 70% !important;
        margin: auto !important;
      }
    `
    document.head.appendChild(style)
    this.customStyleElement = style
  },
  methods: {
    // handle even
    async handleSaveData(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          this.targetItem.mediaIds = this.mediaIds.length > 0 ? this.mediaIds : this.targetItem.mediaIds
          if (this.targetItem.type !== 1 && this.targetItem.mediaIds.length === 0 && this.mediaIds.length === 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Yêu cầu upload file biểu mẫu',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            return
          }
          const response = this.isCreated
            ? await api.saveTemplateFile(this.targetItem)
            : await api.updateTemplateFile(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('templateFileSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onChangeProcedure(event) {
      // this.targetItem.procedureId = event
      this.listProcessByProcedureId = await api.getDropdownListProcess({
        procedureId: event,
      })
    },
    async onChangeProcess(event) {
      // this.targetItem.processId = event
      this.listStepByProcessId = await api.getDropdownListStepProcess({
        processId: event,
      })
    },
    // async onChangeStep(event) {
    //   this.targetItem.stepId = event
    // },
    async onShow() {
      this.$refs.saveFormRef.reset()
      this.targetItem = {}
      this.templateType = {}
      this.uploadedFiles = []
      this.targetItem = this.item ? { ...this.item } : {}
      this.targetItem.type = this.isCreated ? this.fileTypeOptions[0].value : this.fileTypeOptions[this.targetItem.type - 1].value
      this.targetItem.status = this.isCreated ? this.statusOptions[0].value : this.statusOptions[this.targetItem.status === 0 ? this.targetItem.status : (this.targetItem.status - 1)].value
      //  0 không yêu cầu upload, 1 yêu cầu upload
      if (this.targetItem.mediaIds === undefined) {
        this.targetItem.mediaIds = []
      }
      this.targetItem.isRequired = this.isCreated ? 1 : this.item.isRequired
      await Promise.all([
        await this.getDataDropdown(),
        await this.getMediaIdsByTemplateId(this.targetItem.id),
      ])
    },
    async getDataDropdown() {
      this.listProcedure = await api.getDropdownListProcedure()
      if (this.targetItem.id) {
        this.listProcessByProcedureId = await api.getDropdownListProcess({ procedureId: this.targetItem.procedureId })
        this.listStepByProcessId = await api.getDropdownListStepProcess({ processId: this.targetItem.processId })
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        description: '',
        type: '',
        procedureId: null,
        processId: null,
        stepId: null,
        mediaIds: [],
        status: 1,
        orderNo: 1,
      }
    },
    async uploadFile(e) {
      this.isLoading = true
      const formData = new FormData()
      const { files } = e.target

      // Check if no files are selected
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        this.isLoading = false
        return
      }

      // Append each file to the FormData object
      Array.from(files).forEach(file => {
        formData.append('files', file)
      })

      const { type } = this.targetItem

      try {
        // Call the API to upload files
        this.mediaIds = await api.uploadTemplateFile({ formData, type })

        if (this.mediaIds.length > 0) {
          Array.from(files).forEach((file, index) => {
            this.uploadedFiles.push({
              id: this.mediaIds[index],
              name: file.name,
              mediaFileId: null,
              objectPath: null,
            })
          })

          this.showToast('Upload file thành công', 'CheckIcon', 'success')
          // Optionally refresh data or perform other actions
          // await this.getInitData()
        } else {
          this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger')
        }
      } catch (error) {
        this.showToast('Có lỗi xảy ra khi upload file', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async removeFile(mediaFile) {
      this.isLoading = true
      if (this.isCreated) {
        api.deleteListMediaId([mediaFile.id]).then(() => {
          this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== mediaFile.id)
          this.targetItem.mediaIds = this.targetItem.mediaIds.filter(file => file.id !== mediaFile.id)
          this.showToast('Đã xóa file thành công', 'CheckIcon', 'success')
        }).catch(() => {
          this.showToast('Có lỗi xảy ra khi xoá file', 'XCircleIcon', 'danger')
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        await api.deleteMediaFile(mediaFile.mappingId).then(() => {
          this.uploadedFiles = this.uploadedFiles.filter(file => file.id !== mediaFile.id)
          this.targetItem.mediaIds = this.targetItem.mediaIds.filter(file => file.id !== mediaFile.id)
          this.showToast('Đã xóa file thành công', 'CheckIcon', 'success')
        }).catch(() => {
          this.showToast('Có lỗi xảy ra khi xoá file', 'XCircleIcon', 'danger')
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    async getMediaIdsByTemplateId(templateId) {
      if (templateId) {
        await api.getMediaIdsByTemplateId(templateId).then(response => {
          response.forEach(data => {
            this.targetItem.mediaIds.push(data.mediaId)
            this.uploadedFiles.push({
              id: data.mediaId,
              name: data.name,
              mappingId: data.id,
              objectPath: data.objectPath,
            })
          })
        })
      }
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = TemplateFileStatus.TEMPLATE_ATTACHMENT_DOSSIER
        this.url = await api.downloadTemplate({ objectPath, type })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

.uploaded-files-list {
  transition: all 0.3s ease;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.uploaded-files-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.uploaded-files-item:hover {
  background-color: #f1f1f1;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.uploaded-file-info {
  display: flex;
  align-items: center;
}

.uploaded-file-name {
  font-size: 1rem;
  font-weight: 500;
}

.btn-icon:hover {
  background-color: #c82333 !important;
  transform: scale(1.1);
}

.uploaded-files-list {
  max-height: 250px; /* Chiều cao tối đa */
  overflow-y: auto; /* Thêm thanh cuộn dọc */
}

.uploaded-files-item {
  padding: 10px; /* Khoảng cách trong mỗi item */
}

.uploaded-files-list::-webkit-scrollbar {
  width: 8px; /* Độ rộng của thanh cuộn */
}

.uploaded-files-list::-webkit-scrollbar-thumb {
  background-color: #888; /* Màu của thanh cuộn */
  border-radius: 4px;
}

.uploaded-files-list::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Màu của thanh cuộn khi hover */
}

.uploaded-file-name {
  cursor: pointer;
  color: blue;
}

.uploaded-file-name:hover {
  text-decoration: underline;
}
</style>
