import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    courses: [],
    allMajors: [],
    years: [],
    dataSource: [],
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    courses: state => state.courses,
    allMajors: state => state.allMajors,
    years: state => state.years,
    dataSource: state => state.dataSource,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, data) => { state.trainingSystems = data },
    SET_COURSES: (state, data) => { state.courses = data },
    SET_MAJORS: (state, data) => { state.allMajors = data },
    SET_YEARS: (state, data) => { state.years = data },
    SET_DATA_SOURCES: (state, data) => { state.dataSource = data },
  },
  actions: {
    async getTrainingSystemsByOrganizationId({ commit }, params) {
      try {
        const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TRAINING_SYSTEMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getMajorsByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/majors/getByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAJORS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getYears({ commit }, params) {
      try {
        const response = await axios.get('categories/factYears/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdfByYear({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportConductByYear',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadPdfByCourse({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/reports/reportConductByCourse',
          responseType: 'blob',
          params,
        })
        return responseStudentInfo.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async getReportDataSource({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/getStudentsRetakeExamSecondTime', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_SOURCES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
