import { render, staticRenderFns } from "./FormActionTemplate.vue?vue&type=template&id=6000c1fe&scoped=true&"
import script from "./FormActionTemplate.vue?vue&type=script&lang=js&"
export * from "./FormActionTemplate.vue?vue&type=script&lang=js&"
import style0 from "./FormActionTemplate.vue?vue&type=style&index=0&id=6000c1fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6000c1fe",
  null
  
)

export default component.exports