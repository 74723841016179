import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async getCertificateImplements(params) {
    try {
      const response = await axios.get('certificates/certificate_implements', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { total, records } = data.data
        const mapData = records.map(item => ({
          value: item.id,
          label: item.name,
        }))
        return {
          total,
          records: mapData,
        }
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCertificateProcessType(params) {
    try {
      const response = await axios.get('certificates/certificate-process-types', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { total, records } = data.data
        const mapData = records.map(item => ({
          value: item.id,
          label: item.name,
        }))
        return {
          total,
          records: mapData,
        }
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  // eslint-disable-next-line consistent-return
  async getCourseSemestersByCourseId(params) {
    try {
      const response = await axios.get('certificates/course_semester', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: item.code,
          yearId: item.yearId,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getAllCredentials(params) {
    try {
      const response = await axios.get('certificates/credentials', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCredentialAllConditions(params) {
    try {
      const response = await axios.get('certificates/credential_conditions', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCredentialAllSamples(params) {
    try {
      const response = await axios.get('certificates/credential_samples', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getAllCertificateBlanks() {
    try {
      const response = await axios.get('certificates/certificate-blanks/getAll')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getAllCredentialTypes() {
    try {
      const response = await axios.get('certificates/credential_types/all')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: item.description,
          code: item.code ? item.code : null,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getScholarships(params) {
    try {
      const response = await axios.get('uni/factScholarships', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getCreditClassRoomsByCreditClass(params) {
    try {
      const response = await axios.get(ApiExtRoutes.creditClassRoomByCreditClass, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getTrainingSystems(params) {
    try {
      const response = await axios.get(ApiExtRoutes.trainingSystem, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getCourses(params) {
    try {
      const response = await axios.get(`${ApiExtRoutes.course}`, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        const mapData = mapDropdown(records)
        const mapDataYear = records.map(item => ({
          value: item.id,
          label: item.startDate.substring(0, 4),
        }))
        return {
          courses: mapData,
          courseYears: mapDataYear,
        }
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getCourseSemesters(params) {
    try {
      const response = await axios.get('uni/course/semesters', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getCourseSemestersNew(params) {
    try {
      const response = await axios.get(`${ApiExtRoutes.courseSemester}`, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: item.name,
          status: item.status,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getDepartments(params) {
    try {
      const response = await axios.get(ApiExtRoutes.department, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getMajors(params) {
    try {
      const response = await axios.get(`${ApiExtRoutes.major}`, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        const mapData = mapDropdown(records)
        const mapDataCode = records.map(item => ({
          value: item.id,
          label: item.code,
        }))
        return {
          majors: mapData,
          majorsCode: mapDataCode,
        }
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getSubjects(params) {
    try {
      const response = await axios.get(ApiExtRoutes.subject, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: `${item.name} [${item.code}]`,
          shouldAddPracticeClass: item.shouldAddPracticeClass,
          code: item.code ? item.code : null,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  // eslint-disable-next-line consistent-return
  async getClasses(params) {
    try {
      const response = await axios.get(ApiExtRoutes.class, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getRooms(params) {
    try {
      const response = await axios.get(ApiExtRoutes.rooms, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getCreditClassRooms(params) {
    try {
      const response = await axios.get(ApiExtRoutes.creditClassRoom, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getTeachers(params) {
    try {
      const response = await axios.get(ApiExtRoutes.teacher, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getTeacherTeams(params) {
    try {
      const response = await axios.get(ApiExtRoutes.teacherTeams, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getConsultants(params) {
    try {
      const response = await axios.get(ApiExtRoutes.teacher, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getDays() {
    try {
      const response = await axios.get(ApiExtRoutes.days)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const records = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getTurns() {
    try {
      const response = await axios.get(ApiExtRoutes.turns)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const records = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getStudentClasses({ commit }, params) {
    try {
      const response = await axios.get(ApiExtRoutes.student, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.studentClassId,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getCreditClasses(params) {
    try {
      const response = await axios.get(ApiExtRoutes.creditClass, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        const mapData = records.map(item => ({
          value: item.id,
          label: item.code,
        }))
        const mapData2 = records.map(item => ({
          value: item.id,
          label: item.startDate,
        }))
        return {
          creditClass: mapData,
          creditClassDate: mapData2
        }
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getBuildings(params) {
    try {
      const response = await axios.get(ApiExtRoutes.building, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getProgrammes(params) {
    try {
      const response = await axios.get(ApiExtRoutes.programme, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getProgrammeSubjects(params) {
    try {
      const response = await axios.get(ApiExtRoutes.programmeSubject, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.subjectId,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getTitles(params) {
    try {
      const response = await axios.get(ApiExtRoutes.title, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCreditClassTeachers(params) {
    try {
      const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getTeacherByCreditClass(params) {
    try {
      const response = await axios.get(ApiExtRoutes.creditClassTeacher, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.teacherId,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getProgrammeSubjectGroups(params) {
    try {
      const response = await axios.get(ApiExtRoutes.programmeSubjectGroup, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getArticleCategories(params) {
    try {
      const response = await axios.get(ApiExtRoutes.articleCategories, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return mapDropdown(data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getTeachersByCreditClass(params) {
    try {
      const response = await axios.get(ApiExtRoutes.teachersByCreditClass, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getSchoolYears(params) {
    try {
      const response = await axios.get(ApiExtRoutes.year, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getExaminationSchedulers(params) {
    try {
      const response = await axios.get(ApiExtRoutes.examinationScheduler, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getExaminationSchedulerSubjects(params) {
    try {
      const response = await axios.get(ApiExtRoutes.examinationSchedulerSubject, { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        const mapOptionalData = records.map(item => ({
          value: item.id,
          label: item.startDate,
          isSeparateClass: item.isSeparateClass,
        }))
        const mapData = mapDropdown(records)
        return {
          examinationSchedulerSubjectDates: mapOptionalData,
          examinationSchedulerSubjects: mapData
        }
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactDisabilities({ commit }) {
    try {
      const response = await axios.get('categories/fact_disabilities')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactDistricts(params) {
    try {
      const response = await axios.get('categories/fact_districts', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.code,
          label: item.name,
          provinceCode: item.provinceCode,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactEthnics(params) {
    try {
      const response = await axios.get('categories/factEthnics', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.code,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactNationalities(params) {
    try {
      const response = await axios.get('categories/factNationalities', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactProvinces({ commit }, parameters) {
    try {
      const response = await axios.get('categories/factProvinces', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.code,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getFactReligions(params) {
    try {
      const response = await axios.get('categories/factReligions', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.code,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getDeductLevels(parameters) {
    try {
      const response = await axios.get('uni/deduct/level', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          id: item.id,
          value: item.value,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getOrganization(parameters) {
    try {
      const response = await axios.get('categories/factOrganizations/selection', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async getParentOrganization(params) {
    try {
      const response = await axios.get('categories/factOrganizations/selection', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return mapDropdown(records)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getAdmissionDeductLevels() {
    try {
      const response = await axios.get('admissions/admission_deduct_levels')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data.map(item => ({
          id: item.id,
          value: item.value,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
  },

}
