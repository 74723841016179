import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  async getDataSources(params) {
    try {
      const response = await axios.get('uni/conducts', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async createConduct(params) {
    try {
      const response = await axios.post('uni/conducts', params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async updateConduct(params) {
    try {
      const response = await axios.put(`uni/conducts/${params.id}`, params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async deleteConduct(id) {
    try {
      const response = await axios.delete(`uni/conducts/${id}`)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async getTrainingSystemsByOrganizationId(params) {
    try {
      const response = await axios.get('uni/trainingSystems/getByOrganizationId', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCoursesByTrainingSystemId(params) {
    try {
      const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getCourseSemestersByCourseId(params){
    try {
      const response = await axios.get('certificates/course_semester', {params})
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        const mapData = records.map(item => ({
          value: item.id,
          label: item.code,
          yearId: item.yearId,
        }))
        return mapData;
      }
    } catch (e) {
      handleCatch(e)
    }
  },
}
