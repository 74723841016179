<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckbox, BFormRadio, BFormInput, BTab, BCardText, BTabs,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import apiMain from '@/views/ninhbinh/importCertificate/api'

export default {
  name: 'Student',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    BFormInput,
    BTab,
    BCardText,
    BTabs,
    ToastificationContent,
  },
  data() {
    return {
      user: getUser(),
      tableFields: [
        {
          field: 'stt', label: 'STT', sortable: false, type: 'string',
        },
        {
          field: 'studentCode', label: 'Mã SV(*)', sortable: false, type: 'string',
        },
        {
          field: 'studentName', label: 'Tên(*)', sortable: false, type: 'string',
        },
      ],
      fileData: {
        fileName: '',
        items: [],
      },
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
      activeTab: 1,
    }
  },
  computed: {
    paginatedItems() {
      const start = this.serverParams.perPage * (this.serverParams.page - 1)
      const end = Math.min(this.fileData.items.length, start + this.serverParams.perPage)
      return this.fileData.items.slice(start, end)
    },
  },
  methods: {
    async importExcel(event) {
      const file = event.target.files[0]
      if (!file || !/\.(xls|xlsx)$/i.test(file.name)) {
        return this.showToast('File không hợp lệ', 'XCircleIcon', 'danger', '')
      }

      this.fileData.fileName = file.name
      this.readFile(file)
    },

    readFile(file) {
      const fileReader = new FileReader()
      fileReader.onload = async event => {
        try {
          const workbook = XLSX.read(event.target.result, { type: 'binary' })
          const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 0, defval: '' })

          if (!worksheet.length) {
            return this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
          }

          const requiredKeys = ['MaSV', 'Hovaten']
          if (!requiredKeys.every(key => worksheet[0][key])) {
            return this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
          }

          this.fileData.items = worksheet.map((row, index) => ({
            stt: index + 1,
            studentCode: row.MaSV,
            studentName: row.Hovaten,
          }))
          this.totalRecords = this.fileData.items.length
        } catch (error) {
          this.showToast(`Lỗi đọc file: ${error.message}`, 'XCircleIcon', 'danger', '')
        }
      }

      fileReader.readAsBinaryString(file)
    },

    async importData(type) {
      if (!this.fileData.items.length) {
        return this.showToast('Không có dữ liệu để import', 'XCircleIcon', 'danger', '')
      }

      try {
        this.loading = true
        const response = type === 1
          ? await apiMain.importDefenseCertificates({ body: this.fileData.items })
          : await apiMain.importPhysicalCertificates({ body: this.fileData.items })

        const message = response.isSuccessful ? 'Lưu thành công' : `Lỗi: ${response.message}`
        const variant = response.isSuccessful ? 'success' : 'danger'

        this.showToast(message, response.isSuccessful ? 'CheckIcon' : 'XCircleIcon', variant, '')
        if (response.isSuccessful) this.resetFileInput()
      } catch (error) {
        this.showToast(`[${error.code}] ${error.message}`, 'XCircleIcon', 'danger', '')
      } finally {
        this.loading = false
      }
    },

    resetFileInput() {
      this.$refs.inputFileRef.reset()
      this.fileData = { fileName: '', items: [] }
      this.totalRecords = 0
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title, icon, variant, text,
        },
      })
    },

    handlePageChange({ currentPage }) {
      this.serverParams.page = currentPage
    },

    handlePerPageChange({ currentPerPage }) {
      this.serverParams.perPage = currentPerPage
    },
  },
}
</script>
