import Vue from 'vue'

// axios
import axios from 'axios'
import { isUserLoggedIn } from '@/auth/utils'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://api-qldt.hluv.edu.vn/api/v1/',
  // baseURL: 'https://api-demo.daihocso.com.vn/api/v1/',
  baseURL: process.env.VUE_APP_ROOT_API,
  // baseURL: 'http://localhost:8080/api/v1/',
  timeout: 60000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
