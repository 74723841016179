<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div class=" text-right">
                  <b-button
                    v-if="creatable"
                    v-b-modal.templateFileSaveModal
                    variant="success"
                    class="ml-md-1 mt-1 mt-md-0 text-right"
                    @click="onCreateTemplateFile"
                  >
                    <feather-icon icon="PlusIcon" />
                    Thêm mới
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <!-- Per Page -->
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-1">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ total }} bản ghi
                </span>
              </div>
              <vue-good-table
                mode="remote"
                :columns="table.fields"
                :rows="table.rows"
                :pagination-options="{enabled: true}"
                :total-rows="total"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <!-- Column: Action -->
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'type'">
                    {{ getTemplateByValue(props.row.type) }}
                  </span>
                  <span v-else-if="props.column.field === 'action'">
                    <!--                                   <b-button v-if="updatable" v-b-tooltip.hover title="Chỉnh sửa" placement="bottom"-->
                    <!--                                             variant="primary" class="btn-icon" style="margin-left: 6px" size="sm"-->
                    <!--                                             @click="editItem(props.row)">-->
                    <!--                                        <feather-icon icon="EditIcon"/>-->
                    <!--                                    </b-button>-->
                    <b-button
                      v-if="updatable"
                      v-b-modal.templateFileSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="editItem(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      v-b-tooltip.hover
                      title="Xóa"
                      placement="bottom"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="deleteItem(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>
<!--                  <span v-else-if="props.column.field === 'download'">-->
<!--                    <b-button-->
<!--                      v-if="props.row.mediaCode"-->
<!--                      v-b-tooltip.hover.top="'Tải về'"-->
<!--                      variant="success"-->
<!--                      class="btn-icon"-->
<!--                      style="margin-left: 6px;"-->
<!--                      size="sm"-->
<!--                      @click="onDownload(props.row.mediaCode)"-->
<!--                    >-->
<!--                      <feather-icon icon="DownloadIcon" />-->
<!--                    </b-button>-->
<!--                  </span>-->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ total }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="total"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <FormActionTemplate
        ref="saveFormRef"
        :item="currentTemplate"
        @succeed="saveDataSuccess"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BContainer, BFormGroup,
  BFormSelect,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-good-table.scss'
import DatePicker from 'vue2-datepicker'
import FormActionTemplate from '@/views/one-gate/template-files/FormActionTemplate.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { STATUSES, TEMPLATE_FILE_TYPE } from '@/const/status'
import api from './template-file-api'

export default {
  name: 'Index',
  components: {
    BFormGroup,
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,

    DatePicker,
    VueGoodTable,
    vSelect,
    FormActionTemplate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        code: null,
        status: 1,
        sort: null,
      },
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '2%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'Mã tài liệu',
            field: 'code',
            width: '10%',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Tên tài liệu',
            width: '20%',
            field: 'name',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Loại tài liệu',
            width: '10%',
            field: 'type',
            thClass: 'text-center',
            tdClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: TEMPLATE_FILE_TYPE.map(status => ({ value: status.value, text: status.label })),
              placeholder: 'Chọn loại tài liệu',
            },
            sortable: false,
          },
          {
            label: 'Thủ tục',
            width: '10%',
            field: 'procedureName',
            thClass: 'text-center',
            tdClass: 'text-center',
            // filterOptions: {
            //   enabled: true,
            //   trigger: 'enter',
            //   filterDropdownItems: this.listProcedure.map(proc => ({ value: proc.id, text: proc.name })),
            //   placeholder: 'Chọn thủ tục',
            // },
            sortable: false,
          },
          {
            label: 'Quy trình',
            width: '10%',
            field: 'processName',
            thClass: 'text-center',
            tdClass: 'text-center',
            // filterOptions: {
            //   enabled: true,
            //   trigger: 'enter',
            // },
            sortable: false,
          },
          {
            field: 'status',
            width: '7%',
            label: 'Trạng thái',
            sortable: false,
            thClass: 'text-center',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
              placeholder: 'Chọn trạng thái',
            },
          },
          // {
          //   label: 'Tải về',
          //   field: 'download',
          //   thClass: 'text-center',
          //   tdClass: 'text-center',
          //   width: '5%',
          //   sortable: false,
          // },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      templateFiles: [],
      total: 0,
      url: '',
      listProcedure: [],
      itemsDisplay: [],
      dataSendForm: {},
      currentTemplate: undefined,
    }
  },
  computed: {
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.OG_TEMPLATE_RESULT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.OG_TEMPLATE_RESULT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.OG_TEMPLATE_RESULT)
    },
  },
  async created() {
    // init data FROM API
    await this.loadItems()
    // await this.initializeData()
  },
  methods: {
    // handle even
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    getTemplateByValue(value) {
      return TEMPLATE_FILE_TYPE.find(item => item.value === value).label
    },
    getIdTemplate(value) {
      return TEMPLATE_FILE_TYPE.find(item => item.value === value).value
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        const param = {
          currentPage: this.serverParams.page,
          itemsPerPage: this.serverParams.perPage,
        }
        const requestData = {
          code: this.serverParams.columnFilters.code,
          name: this.serverParams.columnFilters.name,
          type: this.serverParams.columnFilters.type,
          status: this.serverParams.columnFilters.status,
        }

        const { records, total } = await api.getTemplateFiles({ params: param, body: requestData })
        this.table.rows = records
        this.total = total
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'warning')
      } finally {
        this.isLoading = false
      }
    },
    onCreateTemplateFile() {
      this.currentTemplate = undefined
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = this.getIdTemplate(1)
        this.url = await api.downloadTemplate({ objectPath, type })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async deleteItem(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa tài liệu</span>?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await api.deleteTemplateFile(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.loadItems()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    async editItem(item) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = item
      this.currentTemplate = rest
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    async saveDataSuccess() {
      await this.loadItems()
    },
  },
}
</script>

<style scoped>

</style>
