import AuthError from '@/libs/errors/auth-error'
import CustomError from '@/libs/errors/custom-error'
import { getUser } from '@/auth/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export const handleCatch = e => {
  if (e.code === 'ECONNABORTED') throw new CustomError(-1, 'Đã hết thời gian xử lý')
  const response = e?.response
  if (response) {
    const { data } = response
    const {
      code,
      message,
    } = data
    switch (response.status) {
      case 401:
        throw new AuthError(code, message)
      default:
        throw new CustomError(code, message)
    }
  }
}
export const mapDropdown = e => {
  const mapData = e.map(item => ({
    value: item.id,
    label: item.name,
    code: item.code ? item.code : null,
  }))
  return mapData
}

export const hasPermissionForResource = (permission, resource) => {
  const user = getUser()
  if (!user) return false
  const { abilities } = user
  return abilities.findIndex(ability => (ability.action === permission && ability.subject === resource)) > -1
}

export const isSystemAdmin = () => hasPermissionForResource(PermissionCode.MANAGE, ResourceCode.ALL)

export const getSemesterLevel = semesterOrder => {
  let semesterLevel = 0
  if (semesterOrder <= 9) {
    if (semesterOrder % 3 === 1) {
      semesterLevel = 1
    } else if (semesterOrder % 3 === 2) {
      semesterLevel = 2
    } else {
      semesterLevel = 3
    }
  } else if (semesterOrder === 10) {
    semesterLevel = 1
  } else if (semesterOrder === 11) {
    semesterLevel = 2
  } else {
    semesterLevel = 3
  }
  return semesterLevel
}

export const removeVietnamese = str => {
  if (!str) return ''
  let value
  value = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  value = value.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  value = value.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  value = value.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  value = value.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  value = value.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  value = value.replace(/đ/g, 'd')
  value = value.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  value = value.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  value = value.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  value = value.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  value = value.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  value = value.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  value = value.replace(/Đ/g, 'D')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  value = value.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  value = value.replace(/\u02C6|\u0306|\u031B/g, '') // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  value = value.replace(/ + /g, '')
  value = value.replace(/\s/g, '')
  value = value.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  value = value.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, '')
  return value
}

export const getIconFile = filename => {
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'xls':
    case 'xlsx':
      return require('@/assets/images/icons/file-icons/xls.png')
    case 'doc':
    case 'docx':
      return require('@/assets/images/icons/file-icons/doc.png')
    case 'pdf':
      return require('@/assets/images/icons/file-icons/pdf.png')
    default:
      return require('@/assets/images/icons/file-icons/unknown.png')
  }
}

export default {
  handleCatch,
  mapDropdown,
  hasPermissionForResource,
  isSystemAdmin,
  removeVietnamese,
  getIconFile,
}
