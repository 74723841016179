<template>
  <validation-observer
    ref="creditClassStudentSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="creditClassStudentSaveModal"
      centered
      no-close-on-backdrop
      body-class="position-static"
      size="lg"
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            v-if="isCreated"
            cols="12"
          >
            <b-form-group label-for="studentCode">
              <template v-slot:label>
                Mã số sinh viên
              </template>
              <b-form-input
                id="studentCode"
                v-model.trim="studentCode"
                name="studentCode"
                placeholder="Mã số sinh viên"
                @keyup.enter="onChangeStudentCode"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Hệ đào tạo <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hệ đào tạo"
                rules="required"
              >
                <v-select
                  v-model="targetData.trainingSystemId"
                  label="name"
                  :options="trainingSystems"
                  :reduce="option => option.id"
                  :disabled="!isCreated"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa"
                rules="required"
              >
                <v-select
                  v-model="targetData.courseId"
                  label="name"
                  :options="courses"
                  :reduce="option => option.id"
                  :disabled="!isCreated"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="departmentId">
              <template v-slot:label>
                Khoa/Bộ môn <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khoa/Bộ môn"
                rules="required"
              >
                <v-select
                  v-model="targetData.departmentId"
                  :options="departments"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="majorId">
              <template v-slot:label>
                Chuyên ngành <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chuyên ngành"
                rules="required"
              >
                <v-select
                  v-model="targetData.majorId"
                  :options="majors"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="classId">
              <template v-slot:label>
                Lớp cố định <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Lớp cố định"
                rules="required"
              >
                <v-select
                  v-model="targetData.classId"
                  :options="classes"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="studentClassId">
              <template v-slot:label>
                Sinh viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Sinh viên"
                rules="required"
              >
                <v-select
                  v-model="targetData.studentClassId"
                  :options="studentClasses"
                  :reduce="option => option.value"
                  :disabled="!isCreated"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái học"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="3"
          >
            <b-form-group
              v-show="!isCreated"
              label="Số tiết vắng"
              label-for="absentTime"
            >
              <b-form-input
                id="absentTime"
                v-model="targetData.absentTime"
                name="absentTime"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="5"
          >
            <b-form-checkbox
              v-model="targetData.isEligible"
              value="1"
              unchecked-value="0"
              class="mt-0"
              @change="changeIsEligible"
            >
              Đủ điều kiện thi
            </b-form-checkbox>
            <b-form-group
              v-if="!isDHHL"
              label-for="code"
            >
              <validation-provider
                #default="{ errors }"
                name="Lý do cấm thi"
                vid="reasonBan"
                :rules="targetData.isEligible === 0 ? 'required' : ''"
              >
                <b-form-input
                  id="reasonBan"
                  v-model="targetData.reasonBan"
                  name="reasonBan"
                  placeholder="Lý do cấm thi..."
                  :state="getElementState(errors)"
                  :disabled="targetData.isEligible === '1'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="isDHHL"
              label-for="code"
            >
              <v-select
                v-model="targetData.reasonBan"
                :disabled="targetData.isEligible === '1'"
                :options="statusStudentCreditClass"
                :reduce="option => option.label"
              />
            </b-form-group>

          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('creditClassStudentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import { STUDENT_CREDIT_CLASS_STATUS } from '@/const/hluv/student-credit-class-status'
import api from './api'

export const Attr = Object.freeze({
  store: 'creditClassStudent',
})
export default {
  name: 'CreditClassStudentSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        trainingSystemId: null,
        courseId: null,
        departmentId: null,
        majorId: null,
        classId: null,
        studentClassId: null,
        absentTime: 0,
        isEligible: 1,
        orderNo: null,
        status: 1,
      },
      trainingSystems: [],
      courses: [],
      studentCode: null,
      required,
      user: getUser(),
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: `${Attr.store}/statuses`,
      eligible: `${Attr.store}/eligible`,
      resourceName: `${Attr.store}/resourceName`,
      maxOrderNo: `${Attr.store}/maxOrderNo`,
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      studentClasses: 'dropdown/studentClasses',
      studentByCode: 'student/studentByCode',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
    isDHHL() {
      return this.user.orgCode === 'DHHL'
    },
    statusStudentCreditClass() {
      return STUDENT_CREDIT_CLASS_STATUS
    },
  },
  watch: {
    'targetData.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          this.courses = await api.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.targetData.courseId = this.courses[0].id
          } else {
            this.courses = []
            this.targetData.courseId = null
            this.targetData.classId = null
            this.setClasses([])
            this.targetData.studentClassId = null
            this.setStudentsClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.targetData.classId = null
        this.setClasses([])
        this.targetData.studentClassId = null
        this.setStudentsClasses([])
      }
    },
    'targetData.courseId': async function (val) {
      if (this.isCreated) {
        if (val && this.targetData.majorId) {
          this.isLoading = true
          try {
            await this.getClasses({ courseId: val, majorId: this.targetData.majorId })
            if (this.classes.length > 0) {
              this.targetData.classId = this.classes[0].value
            } else {
              this.targetData.classId = null
              this.setClasses([])
              this.targetData.studentClassId = null
              this.setStudentsClasses([])
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        } else {
          this.targetData.classId = null
          this.setClasses([])
          this.targetData.studentClassId = null
          this.setStudentsClasses([])
        }
      }
    },
    'targetData.departmentId': async function (val) {
      if (this.isCreated) {
        if (val) {
          this.isLoading = true
          try {
            await this.getMajors({ departmentId: val })
            if (this.majors.length > 0) {
              this.targetData.majorId = this.majors[0].value
            } else {
              this.targetData.majorId = null
              this.setMajors([])
              this.targetData.classId = null
              this.setClasses([])
              this.targetData.studentClassId = null
              this.setStudentsClasses([])
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        } else {
          this.targetData.majorId = null
          this.setMajors([])
          this.targetData.classId = null
          this.setClasses([])
          this.targetData.studentClassId = null
          this.setStudentsClasses([])
        }
      }
    },
    'targetData.majorId': async function (val) {
      if (this.isCreated) {
        if (val && this.targetData.courseId) {
          this.isLoading = true
          try {
            await this.getClasses({ courseId: this.targetData.courseId, majorId: val })
            if (this.classes.length > 0) {
              this.targetData.classId = this.classes[0].value
            } else {
              this.targetData.classId = null
              this.setClasses([])
              this.targetData.studentClassId = null
              this.setStudentsClasses([])
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        } else {
          this.targetData.classId = null
          this.setClasses([])
          this.targetData.studentClassId = null
          this.setStudentsClasses([])
        }
      }
    },
    'targetData.classId': async function (val) {
      if (this.isCreated) {
        if (val) {
          this.isLoading = true
          try {
            await this.getStudentClasses({ classId: val })
            if (this.studentClasses.length > 0) {
              this.targetData.studentClassId = this.studentClasses[0].value
            } else {
              this.targetData.studentClassId = null
              this.setStudentsClasses([])
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        } else {
          this.targetData.studentClassId = null
          this.setStudentsClasses([])
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      setMajors: 'dropdown/SET_MAJORS',
      setClasses: 'dropdown/SET_CLASSES',
      setStudentsClasses: 'dropdown/SET_STUDENT_CLASSES',
    }),
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
      getMaxOrderNo: `${Attr.store}/getMaxOrderNo`,
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      getStudentClasses: 'dropdown/getStudentClasses',
      getCourses: 'dropdown/getCourses',
      getStudentByCode: 'student/getStudentByCode',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.setClasses([])
      const cUser = getUser()
      const userOrgId = (cUser.orgId ? cUser.orgId : 0)
      this.isLoading = true
      try {
        const [trainingSystemResponse] = await Promise.all([
          api.getTrainingSystemsByOrganizationId(),
          this.getDepartments({ organizationId: userOrgId }),
        ])
        this.trainingSystems = [...trainingSystemResponse]
        if (this.mainData) {
          this.targetData = { ...this.mainData }
        }
        if (this.isCreated) {
          if (this.trainingSystems.length > 0) {
            this.targetData.trainingSystemId = this.trainingSystems[0].id
          }
          if (this.departments.length > 0) {
            this.targetData.departmentId = this.departments[0].value
          }
          this.targetData.orderNo = this.maxOrderNo + 1
        } else {
          await Promise.all([
            this.getMajors({ departmentId: this.targetData.departmentId }),
            this.getClasses({ majorId: this.targetData.majorId }),
            this.getStudentClasses({ classId: this.targetData.classId }),
          ])
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .creditClassStudentSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        trainingSystemId: null,
        courseId: null,
        creditClassId: this.extData.creditClassId || this.$route.params.id,
        departmentId: null,
        majorId: null,
        classId: null,
        studentClassId: null,
        absentTime: 0,
        isEligible: 1,
        orderNo: null,
        status: 1,
      }
      this.studentCode = null
    },
    async onChangeStudentCode() {
      if (this.studentCode) {
        this.isLoading = true
        try {
          await this.getStudentByCode({ code: this.studentCode.trim() })
          if (this.studentByCode) {
            this.targetData.trainingSystemId = this.studentByCode.trainingSystemId
            this.targetData.courseId = this.studentByCode.courseId
            this.targetData.departmentId = this.studentByCode.departmentId
            this.targetData.majorId = this.studentByCode.majorId
            this.targetData.classId = this.studentByCode.classId
            this.targetData.studentClassId = this.studentByCode.studentClassId
          } else {
            this.targetData.trainingSystemId = null
            this.targetData.courseId = null
            this.targetData.departmentId = null
            this.targetData.majorId = null
            this.targetData.classId = null
            this.targetData.studentClassId = null
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .creditClassStudentSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('creditClassStudentSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    changeIsEligible() {
      if (this.targetData.isEligible === '1') {
        this.targetData.reasonBan = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
