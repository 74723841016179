import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { DIPLOMA_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.diplomaStudent,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    maxOrderNo: 0,
    statuses: DIPLOMA_STATUSES,
    resourceName: {
      fullName: 'Sinh viên đăng ký chương trình 2',
      shortName: 'SV',
    },
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    maxOrderNo: state => state.maxOrderNo,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadPdf({ commit }, params) {
      try {
        const response = await axios({
          method: 'get',
          url: 'uni/diplomas/students/reportStudentDiploma',
          responseType: 'blob',
          params,
        })
        return response.data
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
