<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                        <span class="text-nowrap"> Hiển thị {{
                            serverParams.itemsPerPage * (serverParams.page - 1) + 1
                          }}
                          đến {{
                            (serverParams.itemsPerPage * (serverParams.page) >= total) ? total : serverParams.itemsPerPage * (serverParams.page)
                          }} &nbsp; của {{
                            total
                          }} bản ghi
                        </span>
              </div>
              <vue-good-table mode="remote"
                              class="my-table"
                              style-class="vgt-table striped bordered research-table"
                              row-style-class="vgt-row" @on-page-change="onPageChange"
                              @on-sort-change="onSortChange"
                              @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                              :columns="table.fields"
                              line-numbers
                              :rows="table.items" :pagination-options="{enabled: true}" :totalRows="totalRecords">
                <div slot="emptystate" style="text-align: center; font-weight: bold">
                  Không có bản ghi nào !
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Action -->
                  <template
                      v-if="props.column.field === 'isRecipient' || props.column.field === 'isStaff' || props.column.field === 'isDefault'">
                    <b-form-checkbox
                        v-model="props.row[props.column.field]"
                        :value="1"
                        :unchecked-value="0"
                        @change="updateStaffProcess(props.row, props.column.field, props.row[props.column.field])"
                    >
                    </b-form-checkbox>
                  </template>
                  <span v-else-if="props.column.field === 'status'">
                                      {{ getStatusName(props.row.status) }}
                            </span>

                  <span v-else-if="props.column.field === 'departmentId'">
                                      {{ getDepartmentName(props.row.departmentId) }}
                            </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap"> Hiển thị {{
                                        serverParams.itemsPerPage * (serverParams.page - 1) + 1
                                      }}
                                      đến {{
                                        (serverParams.itemsPerPage * (serverParams.page) >= total) ? total : serverParams.itemsPerPage * (serverParams.page)
                                      }} &nbsp; của {{
                                        total
                                      }} bản ghi
                                    </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                          v-model="serverParams.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                          :value="1"
                          :total-rows="totalRecords"
                          :per-page="serverParams.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormSelect, BPagination, BRow, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'

import {VueGoodTable} from 'vue-good-table'
import {STATUSES} from '@/const/status'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import {getUser} from '@/auth/utils'
import {handleCatch, hasPermissionForResource, isSystemAdmin} from '@/utils'
import {PermissionCode, ResourceCode} from '@/const/code'
import axios from '@/libs/axios'
import {ApiCode} from '@/const/api'

const IS_NOT_STAFF = 0
const IS_STAFF = 1
const IS_NOT_DEFAULT = 0
const IS_DEFAULT = 1
const IS_NOT_RECIPIENT = 0
const IS_RECIPIENT = 1


export default {
  name: 'Employee',
  components: {
    BOverlay,
    BFormCheckbox,
    BCard,
    BCol,
    BFormSelect,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {},
  data() {
    return {
      user: getUser(),
      serverParams: {
        page: 1,
        itemsPerPage: 10,
        searchTerm: null,
        departmentId: null,
        status: null,
      },
      total: 0,
      departments: [],
      totalRecords: 0,
      itemsDisplay: 0,
      items: [],
      statuses: [],
      table: {
        fields: [
          {
            field: 'fullName',
            label: 'Tên nhân viên',
            sortable: false,
            type: 'string',
            filterOptions: {
              placeholder: 'Tên...',
              enabled: true,
              trigger: 'enter',
            },
          },
          {
            field: 'username', label: 'Tài khoản', sortable: false, type: 'string',
          },
          {
            field: 'titleName', label: 'Chức danh', sortable: false, type: 'string',
          },
          {
            field: 'departmentId',
            label: 'Khoa/bộ môn',
            sortable: false,
            type: 'string',
            filterOptions: {
              placeholder: 'Tất cả',
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: [],
            },
          },
          {
            label: 'Trạng thái',
            field: 'status',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
              filterDropdownItems: STATUSES.map(status => ({value: status.value, text: status.label})),
              placeholder: 'Chọn trạng thái',
            },
            sortable: false,
            thClass: 'text-center',
          },
          {
            field: 'isRecipient', label: 'Bộ phận một cửa', sortable: false, type: 'string',
          },
          {
            field: 'isStaff', label: 'Nhân viên xử lý', sortable: false, type: 'string',
          },
          {
            field: 'isDefault', label: 'Nhân viên xử lý mặc định', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      dataSendForm: {},
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.OG_STAFF_PROCESS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.OG_STAFF_PROCESS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.OG_STAFF_PROCESS)
    },
  },
  async created() {
    await Promise.all([this.fetchData(), this.fetchDepartments()])
  },
  methods: {
    async fetchDepartments() {
      try {
        const response = await axios.get('uni/departments/getByOrganizationId')
        this.departments = response.data.data
        const optDepartment = this.departments.map(item => ({value: item.id, text: item.name}))
        this.$set(this.table.fields[3].filterOptions, 'filterDropdownItems', optDepartment)
      } catch (e) {
        handleCatch(e)
      }
    },
    async fetchData() {
      this.loading = true
      try {
        const response = await axios.get('oneGate/staff-process/getOneGateEmployees', {params: this.serverParams})
        this.table.items = response.data.data.records
        this.totalRecords = response.data.data.total
        this.total = response.data.data.total
      } catch (e) {
        handleCatch(e)
      } finally {
        this.loading = false
      }
    },
    openModal() {
      this.dataSendForm = undefined
      this.$bvModal.show('modal-employee')
    },
    async confirmAction(message, employeeName) {
      const result = await this.$swal({
        title: `Bạn có muốn xoá phân công <span class="text-danger">${employeeName}</span> khỏi danh sách ${message}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      return result.isConfirmed
    },
    messageConfirm(field) {
      let message = 'Nhân viên xử lý'
      if (field === 'isDefault') {
        message = 'Nhân viên xử lý mặc định'
      } else if (field === 'isRecipient') {
        message = 'Nhân viên bộ phận một cửa'
      }
      return message
    },
    async updateStaffProcess(row, field, value) {
      if (value === 0) {
        const message = this.messageConfirm(field)
        const confirmed = await this.confirmAction(message, row.fullName)
        if (!confirmed) {
          await this.fetchData()
          return
        }
      }
      this.loading = true
      const payload = {
        employeeId: row.id,
        departmentId: row.departmentId,
        [field]: value,
      }
      if (field === 'isStaff' && value === IS_NOT_STAFF) {
        row.isDefault = IS_NOT_DEFAULT
        row.isRecipient = IS_NOT_RECIPIENT
      } else if (field === 'isDefault' && value === IS_DEFAULT) {
        row.isStaff = IS_STAFF
      } else if (field === 'isRecipient' && value === IS_RECIPIENT) {
        row.isStaff = IS_STAFF
      }
      try {
        const response = await axios.post('oneGate/staff-process', payload)
        const {code, message} = response.data
        if (code === ApiCode.SUCCESS) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Cập nhật thành công!',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          await this.fetchData()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: message || 'Đã có lỗi xảy ra!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (error) {
        const responseErr = error.response
        if (responseErr && responseErr.data && responseErr.data.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: responseErr.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Đã có lỗi xảy ra!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.loading = false
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    getDepartmentName(id) {
      if (!id) return ''
      const found = this.departments.find(department => department.id === id)
      return found ? found.name : ''
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    // handle pagination
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps}
    },

    async onPageChange(params) {
      this.updateParams({page: params.currentPage})
      await this.fetchData()
    },

    async onPerPageChange(params) {
      this.updateParams({itemsPerPage: params.currentPerPage})
      await this.fetchData()
    },

    async onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      await this.fetchData()
    },

    async onColumnFilter(params) {
      const {columnFilters} = params
      if (Object.hasOwn(columnFilters, 'fullName')) this.updateParams({searchTerm: columnFilters.fullName.trim()})
      if (Object.hasOwn(columnFilters, 'departmentId')) this.updateParams({departmentId: columnFilters.departmentId})
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({status: columnFilters.status})
      await this.fetchData()
    },
  },
}
</script>
