<template>
  <validation-observer
    ref="detailsDossierForm"
  >
    <b-modal
      id="detailsDossierModal"
      body-class="position-static"
      modal-class="custom-modal-size"
      centered
      title="Chi tiết hồ sơ"
      no-close-on-backdrop
      no-close-on-esc
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <!-- Mã thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="code"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width m-auto">Mã hồ sơ:</strong>
                  <div class="ml-2 value-align">
                    <label class="m-auto">{{ mappingDataDetail.code }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Tên thủ tục -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="name"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Tên thủ tục:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ mappingDataDetail.procedureName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Phí -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Phí/Lệ phí:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ formatCurrency(mappingDataDetail.fees) }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <!-- Quy trình -->
            <b-col
              sm="12"
              md="4"
              lg="4"
              xl="4"
            >
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Quy trình:</strong>
                  <div class="ml-2">
                    <label class="m-auto">{{ mappingDataDetail.processName }}</label>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <!--Mở lại sau khi có phần thanh toán-->
            <div v-show="false">
              <!-- Thông tin ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <div class="d-flex align-items-center">
                  <strong class="label-width">Thông tin ngân hàng:</strong>
                  <div class="ml-2">
                    <validation-provider name="Thông tin ngân hàng">
                      <b-form-input
                        id="bankName"
                        v-model="mappingDataDetail.bankName"
                        name="bankName"
                        placeholder="Thông tin ngân hàng"
                        aria-describedby="input-1-live-feedback"
                      />
                    </validation-provider>
                  </div>
                </div>
              </b-col>

              <!-- Mã ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Mã ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="bankCode"
                        v-model="mappingDataDetail.bankCode"
                        placeholder="Nhập mã ngân hàng"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Số tài khoản ngân hàng -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Số tài khoản ngân hàng:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountNumber"
                        v-model="mappingDataDetail.accountNumber"
                        placeholder="Nhập số tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>

              <!-- Họ tên chủ tài khoản -->
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label-for="fees"
                  class="d-flex align-items-center"
                >
                  <div class="d-inline-flex">
                    <strong class="label-width">Họ tên chủ tài khoản:</strong>
                    <div class="ml-2">
                      <b-form-input
                        id="accountHolderName"
                        v-model="mappingDataDetail.accountHolderName"
                        placeholder="Nhập họ tên chủ tài khoản"
                      />
                    </div>
                  </div>
                </b-form-group>
              </b-col>
            </div>
            <!-- Thành phần hồ sơ -->
            <b-col cols="12">
              <b-form-group
                label-for="fees"
                class="d-flex align-items-center"
              >
                <div class="d-inline-flex">
                  <strong class="label-width">Lịch sử hồ sơ:</strong>
                  <div class="ml-2">
                    <b-button
                      v-b-modal.historyDossierModal
                      variant="primary"
                      class="btn-icon"
                      title="Xem lịch sử hồ sơ"
                    >
                      <feather-icon icon="EyeIcon" />
                      Xem lịch sử
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="documentDetails">
                <div>
                  <strong class="label-width">Hồ sơ đính kèm:</strong>
                  <div class="mt-1">
                    <b-table
                      :key="tableKey"
                      :items="mappingDataDetail.listStepDocument"
                      :fields="documentFields"
                      responsive
                      striped
                      hover
                    >
                      <template #cell(templateName)="row">
                        <div
                          :title="row.item.mediaFiles[0].templateName"
                          class="template-name"
                        >
                          <span
                            v-show="row.item.mediaFiles[0].isRequired === 1"
                            class="text-danger"
                          >(*)</span>
                          {{ row.item.mediaFiles[0].templateName }}
                        </div>
                      </template>
                      <template #cell(quantity)="row">
                        <div class="quantity text-center d-inline-flex">
                          {{ row.item.mediaFiles.filter(file => file.mediaId).length }}
                        </div>
                      </template>
                      <template #cell(upload)="row">
                        <div class="d-inline-flex flex-column">
                          <!-- Hiển thị danh sách file đã upload -->
                          <template v-if="row.item.mediaFiles && row.item.mediaFiles.length">
                            <div
                              v-for="(file, index) in row.item.mediaFiles"
                              :key="index"
                              class="d-flex align-items-center mb-1 justify-content-between"
                            >
                              <span
                                v-if="file.mediaCode"
                                v-b-tooltip.hover.top="'Click để tải file đã upload'"
                                class="text-primary cursor-pointer"
                                @click="onDownload(file.mediaCode)"
                              >
                                {{ file.mediaName }}
                              </span>
                              <span
                                v-else
                                class="text-muted cursor-not-allowed"
                              >
                                {{ file.mediaName }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </b-form>

      <!-- Modal Footer -->
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-if="isAcceptButtonVisible"
            variant="success"
            class="btn-icon"
            title="Tiếp nhận"
            style="margin-right: 6px;"
            @click="handleAccept"
          >
            <feather-icon icon="PlusIcon" />
            Tiếp nhận
          </b-button>
          <b-button
            v-if="isRejectButtonVisible"
            variant="danger"
            class="btn-icon"
            title="Từ chối"
            style="margin-right: 6px;"
            @click="showRejectModal"
          >Từ chối
          </b-button>
          <b-button
            v-if="isRequestButtonVisible"
            variant="info"
            class="btn-icon"
            title="Yêu cầu bổ sung hồ sơ"
            style="margin-right: 6px;"
            @click="showRequestModal"
          >Yêu cầu bổ sung hồ sơ
          </b-button>
          <b-button
            v-if="isHandleCompleteButtonVisible"
            variant="primary"
            class="btn-icon"
            title="Chuyển bước"
            style="margin-right: 6px;"
            @click="handleComplete"
          >Chuyển bước
          </b-button>
          <b-button
            v-if="isHandleReturnResultButtonVisible"
            variant="success"
            class="btn-icon"
            title="Trả hồ sơ"
            style="margin-right: 6px;"
            @click="handleReturnResult"
          >Trả hồ sơ
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="$bvModal.hide('detailsDossierModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Đóng
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="rejectReasonModal"
        centered
        title="Nhập lý do từ chối"
        size="md"
        :no-close-on-backdrop="true"
        @hide="clearReason"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="reasonRejection">
                <template v-slot:label>
                  Lý do từ chối <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Lý do từ chối"
                  rules="required"
                >
                  <b-form-input
                    id="reasonRejection"
                    v-model="reason"
                    placeholder="Nhập lý do từ chối"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="submitRejectReason"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('rejectReasonModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
        <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          :show="isLoading"
        />
      </b-modal>
    </validation-observer>
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="requestDossierModal"
        centered
        title="Nhập hồ sơ bổ sung"
        size="md"
        :no-close-on-backdrop="true"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="requestDossierModal">
                <template v-slot:label>
                  Hồ sơ bổ sung <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Hồ sơ bổ sung"
                  rules="required"
                >
                  <b-form-input
                    id="requestDossierModal"
                    v-model="additionalRequirements"
                    placeholder="Nhập hồ sơ cần bổ sung"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="submitRequestDossier"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('requestDossierModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
        <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          :show="isLoading"
        />
      </b-modal>
    </validation-observer>
    <Result
      ref="dossierSaveForm"
      :data-send-form="dataSendForm"
      @succeed="onSucceed"
    />
    <HistoryDossier
      ref="historyDossierForm"
      :item="dossierDetail"
      @succeed="onSucceed"
    />
  </validation-observer>
</template>

<script>
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BTable,
  BOverlay,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { TemplateFileStatus, DossierStatus } from '@/const/dossier-status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import HistoryDossier from '@/views/one-gate/history-dossier/HistoryDossier.vue'
import Result from '@/views/one-gate/dossier-result/Result.vue'

export default {
  name: 'FormDetailsDossier',
  components: {
    HistoryDossier,
    BModal,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BTable,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    Result,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    dossierDetail: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isLoading: true,
      targetItem: {},
      reason: '',
      additionalRequirements: '',
      bankInfo: {
        bankName: '', bankCode: '', accountNumber: '', accountHolderName: '',
      },
      documentFields: [
        { key: 'templateName', label: 'Tên giấy tờ', thStyle: { width: '350px' } },
        {
          key: 'quantity', label: 'Số lượng', thStyle: { width: '15%', textAlign: 'center' }, tdClass: 'text-center',
        },
        {
          key: 'upload',
          label: 'Đính kèm file thông tin',
          thStyle: { width: '50%', textAlign: 'center' },
          tdClass: 'text-center',
        },
      ],
      listProcessByProcedureId: [],
      listInfoDepartment: [],
      mappingDataDetail: {
        id: null,
        procedureId: null,
        procedureName: null,
        procedureType: null,
        processId: null,
        processName: null,
        fees: null,
        code: null,
        status: null,
        bankName: null,
        bankCode: null,
        bankAccountNo: null,
        bankAccountHolderName: null,
        listStepDocument: [],
      },
      isEnable: true,
      tableKey: Date.now(),
      fileNumber: 0,
      dataMapping: {
        dossierId: null,
        procedureId: null,
        processId: null,
        bankName: '',
        bankCode: '',
        accountNumber: '',
        accountHolderName: '',
        status: null,
        listMedia: [],
      },
      sessionMedia: [],
      checkError: null,
      dataSendForm: {
        listStepDocument: [],
        dossierId: null,
        stepId: null,
      },
    }
  },
  computed: {
    DossierStatus() {
      return DossierStatus
    },
    isAcceptButtonVisible() {
      return this.dossierDetail.canUpdate && (this.dossierDetail.statusStepProcess === DossierStatus.WAITING_FOR_RECEPTION || this.dossierDetail.status === DossierStatus.ADDITION_DOSSIER) && this.dossierDetail.internalStatus !== DossierStatus.COMPLETED
    },
    isRejectButtonVisible() {
      return this.dossierDetail.canUpdate && (this.dossierDetail.statusStepProcess === DossierStatus.WAITING_FOR_PROCESSING && this.dossierDetail.statusStepProcess !== DossierStatus.REJECT) && this.dossierDetail.internalStatus !== DossierStatus.COMPLETED
    },
    isRequestButtonVisible() {
      return this.dossierDetail.canUpdate && (this.dossierDetail.statusStepProcess === DossierStatus.WAITING_FOR_PROCESSING && this.dossierDetail.statusStepProcess !== DossierStatus.REQUIRED_ADDITION_DOCUMENTS) && this.dossierDetail.internalStatus !== DossierStatus.COMPLETED
    },
    isHandleCompleteButtonVisible() {
      return this.dossierDetail.canUpdate && (this.dossierDetail.statusStepProcess === DossierStatus.WAITING_FOR_PROCESSING && this.dossierDetail.statusStepProcess !== DossierStatus.COMPLETED) && this.dossierDetail.internalStatus !== DossierStatus.COMPLETED
    },
    isHandleReturnResultButtonVisible() {
      return this.dossierDetail.canUpdate && this.dossierDetail.status !== DossierStatus.COMPLETED && this.dossierDetail.internalStatus === DossierStatus.COMPLETED
    },
  },
  mounted() {
    const style = document.createElement('style')
    style.innerHTML = `
      .custom-modal-size .modal-dialog {
        max-width: 70% !important;
        margin: auto !important;
      }
    `
    document.head.appendChild(style)
    this.customStyleElement = style
  },
  beforeDestroy() {
    // Cleanup: Remove the dynamically added style element
    if (this.customStyleElement) {
      document.head.removeChild(this.customStyleElement)
    }
  },
  methods: {
    showRejectModal() {
      this.$bvModal.show('rejectReasonModal')
    },
    showRequestModal() {
      this.$bvModal.show('requestDossierModal')
    },
    clearReason() {
      this.reason = ''
    },
    // eslint-disable-next-line consistent-return
    async getDetailDossier(params) {
      try {
        const response = await axios.get(`oneGate/dossiers/getDossier/${params}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line consistent-return
    async downloadTemplate(params) {
      try {
        const response = await axios.get('oneGate/template-files/download', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTemplateResult(params) {
      try {
        const response = await axios.get('oneGate/template-files/selectTemplateResult', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async onShow() {
      await this.fetchDetailDossier(this.dossierDetail.id)
      await this.fetchTemplateResult(this.dossierDetail.processId, this.dossierDetail.currentStepId)
    },
    onHide() {
      this.tableKey = Date.now()
    },
    async handleAccept() {
      this.targetItem = {
        ...this.dossierDetail,
        statusStepProcess: DossierStatus.WAITING_FOR_PROCESSING,
      }
      await this.onSave('hide')
    },
    async handleComplete() {
      this.targetItem = {
        ...this.dossierDetail,
        statusStepProcess: DossierStatus.COMPLETED,
      }
      await this.onSave('hide')
    },
    async handleReturnResult() {
      this.targetItem = {
        ...this.dossierDetail,
      }
      await this.onSaveDossier('hide')
    },
    async submitRejectReason() {
      if (!this.reason.trim()) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập lý do từ chối',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.targetItem = {
        ...this.dossierDetail,
        statusStepProcess: DossierStatus.REJECT,
        reasonStepProcess: this.reason,
      }
      await this.onSave('hide', 'rejectReasonModal')
    },
    async submitRequestDossier() {
      if (!this.additionalRequirements.trim()) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập hồ sơ cần bổ sung',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.targetItem = {
        ...this.dossierDetail,
        statusStepProcess: DossierStatus.REQUIRED_ADDITION_DOCUMENTS,
        additionalRequirements: this.additionalRequirements,
      }
      await this.onSave('hide', 'requestDossierModal')
    },
    async updateStepProcessDossier(params) {
      try {
        const response = await axios.put('oneGate/steps-process-dossiers', {
          dossierId: params.id,
          status: params.statusStepProcess,
          reason: params.reasonStepProcess,
          stepId: params.currentStepId,
          additionalRequirements: params.additionalRequirements,
          listMedia: [],
        })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDossier(id) {
      try {
        const response = await axios.put(`oneGate/dossiers/${id}`, {
          status: DossierStatus.COMPLETED,
        })
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async onSave(type = null, modalId = null) {
      this.isLoading = true
      try {
        const {
          statusStepProcess, reasonStepProcess, id, currentStepId, additionalRequirements,
        } = this.targetItem
        const response = await this.updateStepProcessDossier({
          statusStepProcess, reasonStepProcess, id, currentStepId, additionalRequirements,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide(modalId)
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSaveDossier(type = null, modalId = null) {
      this.isLoading = true
      try {
        const response = await this.updateDossier(this.targetItem.id)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide(modalId)
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        const type = TemplateFileStatus.TEMPLATE_ATTACHMENT_DOSSIER
        this.url = await this.downloadTemplate({ objectPath, type })
        if (this.url) window.open(this.url, '_blank')
      } catch (e) {
        this.showToast(e.message, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async fetchTemplateResult(processId, stepId) {
      this.isLoading = true
      try {
        const params = {
          processId,
          stepId,
        }
        const res = await this.getTemplateResult(params)
        this.dataSendForm.listStepDocument = res.data.result
      } catch (error) {
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    async fetchDetailDossier(dossierId) {
      this.isLoading = true
      try {
        const res = await this.getDetailDossier(dossierId)
        this.mappingDataDetail = res.data
        this.mappingDataDetail.listStepDocument = res.data.dossierFiles
        this.dataSendForm.dossierId = this.dossierDetail.id
        this.dataSendForm.stepId = this.dossierDetail.currentStepId

        // Initialize listMedia if it doesn't exist
        if (!this.mappingDataDetail.listMedia) {
          this.mappingDataDetail.listMedia = []
        }

        // Initialize listMedia if it doesn't exist
        if (!this.mappingDataDetail.listStepDocument.mediaFiles) {
          this.mappingDataDetail.listStepDocument.mediaFiles = []
        }

        // Map through listStepDocument and push to listMedia
        this.mappingDataDetail.listStepDocument.forEach(
          data => {
            data.mediaFiles.forEach(mediaFile => {
              this.dataMapping.listMedia.push({
                title: `${mediaFile.name}`,
                mediaId: `${mediaFile.dossierMediaId}`,
              })
            })
          },
        )
      } catch (error) {
        this.showToast(error.message || 'Lỗi hệ thống!', 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
    formatCurrency(value) {
      return value ? `${value.toLocaleString('vi-VN')} VND` : '0 VND'
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: { title, icon, variant },
      })
    },
    onSucceed() {
      this.$emit('succeed')
    },
  },
}
</script>
<style scoped>

.v-select span {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.v-select {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.step-name {
  width: 70%;
}

.media-code {
  width: 15%;
}

.quantity {
  width: 15%;
}

label {
  font-size: 14px !important; /* Set font size */
  color: #5e5873 !important; /* Set font color */
}

.label-width {
  width: 150px;
  text-align: left;
  font-size: 14px !important;
  color: #5e5873 !important;
  min-width: 150px;
  margin-bottom: 1rem;
}

.value-align {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.step-name,
.d-inline-flex span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  max-height: 4.5em;
}

</style>
