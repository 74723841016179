<template>
  <b-row class="match-height m-2">
    <b-col
      cols="12"
    >
      <b-form>
        <b-row>
          <b-col cols="3">
            <b-form-group>
              <v-select
                id="trainingSystemId"
                v-model="filter.trainingSystemId"
                :options="trainingSystems"
                :reduce="option => option.value"
                placeholder="Hệ đào tạo"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <v-select
                id="yearId"
                label="name"
                v-model="filter.yearId"
                :options="years"
                :reduce="option => option.id"
                placeholder="Năm học"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group>
              <v-select
                id="semesterId"
                v-model="filter.semesterId"
                :options="semesters"
                :reduce="option => option.value"
                placeholder="Học kỳ"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col
      lg="6"
      cols="12"
      v-if="this.filterFake.show && creatableOverview"
    >
      <students-card-chart
        :title="'Tổng quát Sinh viên'"
        :filter="filterFake"
      />
    </b-col>
    <b-col
      lg="6"
      cols="12"
      v-if="this.filterFake.show && creatableOverview"
    >
      <employees-card-chart
        :title="'Tổng quát Cán bộ/Giảng viên'"
        :filter="filterFake"/>
    </b-col>
    <b-col cols="6" v-if="this.filterFake.show && creatable1">
      <employees-by-department-bar-chart :filter="filterFake"/>
    </b-col>
    <b-col cols="6" v-if="this.filterFake.show && creatable2">
      <classes-by-courses-and-department-bar-chart :filter="filterFake"/>
    </b-col>
    <b-col cols="6" v-if="this.filterFake.show && creatable3">
      <student-by-major-and-courses-line-area-chart :filter="filterFake" />
    </b-col>
    <b-col cols="6" v-if="this.filterFake.show && creatable5">
      <k-l-t-n-by-department-echart-bar :filter="filterFake"/>
    </b-col>
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable6">-->
<!--      <scores-by-subject-polar-area-chart :filter="filterFake" />-->
<!--    </b-col>-->
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable7">-->
<!--      <student-by-item-apex-chart :filter="filterFake" />-->
<!--    </b-col>-->
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable7">-->
<!--      <student-award-by-item-bar-chart-horizontal :filter="filterFake"/>-->
<!--    </b-col>-->
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable7">-->
<!--      <student-t-c-s-p-by-item-bar-chart-horizontal :filter="filterFake"/>-->
<!--    </b-col>-->
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable9">-->
<!--      <student-suspend-horizontal-bar-chart :filter="filterFake" />-->
<!--    </b-col>-->
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable10">-->
<!--      <credit-class-by-subject-type-horizontal-bar-chart :filter="filterFake" />-->
<!--    </b-col>-->
    <b-col cols="6" v-if="this.filterFake.show && creatable11">
      <student-scholarship-horizontal-bar-chart :filter="filterFake" />
    </b-col>
<!--    <b-col cols="6" v-if="this.filterFake.show && creatable4">-->
<!--      <lectures-and-credit-class-echart-bar :filter="filterFake"/>-->
<!--    </b-col>-->
    <b-col cols="12" v-if="this.filterFake.show && creatable12">
      <scores-by-credit-class-apex-chart :filter="filterFake" />
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCol, BForm, BFormGroup, BLink, BRow } from 'bootstrap-vue'
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { SEMESTER_NAME } from "@/const/status";
import Ripple from 'vue-ripple-directive'
import { getUser } from "@/auth/utils";
import StudentByMajorAndCoursesLineAreaChart
  from "@/views/admin/category/charts/StudentByMajorAndCoursesLineAreaChart.vue";
import EmployeesByDepartmentBarChart from "@/views/admin/category/charts/EmployeesByDepartmentBarChart.vue";
import LecturesAndCreditClassEchartBar from "@/views/admin/category/charts/LecturesAndCreditClassEchartBar.vue";
import ChartjsPolarAreaChart from "@/views/admin/category/charts/ScoresBySubjectPolarAreaChart.vue";
import ScoresBySubjectPolarAreaChart from "@/views/admin/category/charts/ScoresBySubjectPolarAreaChart.vue";
import KLTNByDepartmentEchartBar from "@/views/admin/category/charts/KLTNByDepartmentEchartBar.vue";
import StudentSuspendHorizontalBarChart from "@/views/admin/category/charts/StudentSuspendHorizontalBarChart.vue";
import ScoresByCreditClassApexChart from "@/views/admin/category/charts/ScoresByCreditClassApexChart.vue";
import ClassesByDepartmentBarChart from "@/views/admin/category/charts/ClassesByCoursesAndDepartmentBarChart.vue";
import ClassesByCoursesAndDepartmentBarChart
  from "@/views/admin/category/charts/ClassesByCoursesAndDepartmentBarChart.vue";
import CreditClassBySubjectTypeHorizontalBarChart
  from "@/views/admin/category/charts/CreditClassBySubjectTypeHorizontalBarChart.vue";
import StudentScholarshipHorizontalBarChart
  from "@/views/admin/category/charts/StudentScholarshipHorizontalBarChart.vue";
import { hasPermissionForResource, isSystemAdmin } from "@/utils";
import { PermissionCode, ResourceCode } from "@/const/code";
import CardChart from "@/views/admin/category/charts/component/CardChart.vue";
import StudentOverview from "@/views/admin/category/charts/StudentOverview.vue";
import EmployeesOverview from "@/views/admin/category/charts/EmployeesOverview.vue";
import StudentsCardChart from '@/views/admin/category/charts/StudentsCardChart.vue'
import EmployeesCardChart from '@/views/admin/category/charts/EmployeesCardChart.vue'
import StudentByItemApexChart from '@/views/admin/category/charts/StudentByItemApexChart.vue'
import StudentAwardByItemBarChartHorizontal from '@/views/admin/category/charts/StudentAwardByItemBarChartHorizontal.vue'
import StudentTCSPByItemBarChartHorizontal from '@/views/admin/category/charts/StudentTCSPByItemBarChartHorizontal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    StudentAwardByItemBarChartHorizontal,
    StudentTCSPByItemBarChartHorizontal,
    StudentByItemApexChart,
    EmployeesCardChart,
    StudentsCardChart,
    EmployeesOverview,
    StudentOverview,
    CardChart,
    StudentScholarshipHorizontalBarChart,
    CreditClassBySubjectTypeHorizontalBarChart,
    ClassesByCoursesAndDepartmentBarChart,
    ClassesByDepartmentBarChart,
    ScoresByCreditClassApexChart,
    StudentSuspendHorizontalBarChart,
    KLTNByDepartmentEchartBar,
    ScoresBySubjectPolarAreaChart,
    ChartjsPolarAreaChart,
    LecturesAndCreditClassEchartBar,
    EmployeesByDepartmentBarChart,
    StudentByMajorAndCoursesLineAreaChart,
    BButton, BForm, BFormGroup, vSelect,
    BRow,
    BCol,
    BLink,
  },
  data() {
    return {
      user: getUser(),
      semesters: [],
      filter: {
        organizationId: null,
        trainingSystemId: null,
        yearId: null,
        semesterId: null,
      },
      filterFake: {}
    }
  },
  computed: {
    ...mapGetters( {
      trainingSystems: 'dropdown/trainingSystems',
      years: 'reviewBoard/years',
    } ),
    creatableOverview() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_STUDENTS_OVERVIEW, ResourceCode.CHART_EMPLOYEES_OVERVIEW)
    },
    creatable1() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_EMPLOYEES_BY_DEPARTMENT)
    },
    creatable2() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_CLASS_BY_COURSES_AND_DEPARTMENT)
    },
    creatable3() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_STUDENT_BY_MAJOR_AND_COURSES)
    },
    creatable4() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_TEACHER_AND_CREDIT_CLASS_BY_DEPARTMENT)
    },
    creatable5() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_STUDENT_BY_SUBJECT_TYPE)
    },
    creatable6() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_POINT_BY_SUBJECT)
    },
    creatable7() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_STUDENT_BY_ITEM)
    },
    creatable9() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_SUSPEND_STUDENT)
    },
    creatable10() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_CREDIT_CLASS_BY_SUBJECT_TYPE)
    },
    creatable11() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_STUDENT_SCHOLARSHIP_BY_DEPARTMENT)
    },
    creatable12() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CHART_SCORE_BY_CREDIT_CLASS)
    },
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getYears: 'reviewBoard/getYears',
    }),
    isSystemAdmin,
    isTodayInRange(startDate, endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)

      const today = new Date()

      return today >= start && today <= end
    },
    async getDataFromStore() {
      this.isLoading = true
      this.semesters = SEMESTER_NAME
      try {
        await Promise.all([
          this.getTrainingSystems(),
          this.getYears(),
        ])
        this.filter.organizationId = this.user.orgId
        this.filter.trainingSystemId = this.trainingSystems[0]?.id || 1
        this.filter.semesterId = 2
        if (this.years != null) {
          const foundYear = this.years.find(year => this.isTodayInRange(year.startDate, year.endDate))
          if (foundYear) {
            this.filter.yearId = foundYear.id
          }
        }
        let currentMonth = new Date().getMonth()
        if (currentMonth < 8 ) {
          this.filter.semesterId = 1;
        }
        if (currentMonth >= 8 && currentMonth <= 12) {
          this.filter.semesterId = 2;
        }
        this.filterFake = this.filter
        this.filterFake.show = true
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}

</script>
