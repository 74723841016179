<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import DatePicker from 'vue2-datepicker'
import FormDocument from '@/views/documents/FormDocument.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,

    DatePicker,
    VueGoodTable,
    vSelect,
    FormDocument,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'Tiêu đề file',
            field: 'title',
            filterOptions: {
              enabled: true,
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Tên file',
            field: 'mediaName',
            sortable: false,
          },
          {
            label: 'Tải về',
            field: 'download',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
        ],
        rows: [],
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      itemsDisplay: [],
      dataSendForm: {},
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      documents: 'documents/documents',
      total: 'documents/total',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.DOCUMENTS)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.DOCUMENTS)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.DOCUMENTS)
    },
  },
  async created() {
    // init data FROM API
    await this.loadItems()
  },
  methods: {
    ...mapActions({
      getDocuments: 'documents/getDocuments',
      deleteDocument: 'documents/deleteDocument',

    }),
    // handle even
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        const param = {
          currentPage: this.serverParams.page,
          itemsPerPage: this.serverParams.perPage,
          search: this.serverParams.columnFilters.title ? this.serverParams.columnFilters.title : null,
        }
        await this.getDocuments(param)
        this.table.rows = this.documents
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'warning')
      } finally {
        this.isLoading = false
      }
    },
    async deleteItem(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa tài liệu</span>?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDocument(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.loadItems()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    async editItem(data) {
      this.$bvModal.show('modal-document')
      this.dataSendForm = data
    },

    openModal() {
      this.dataSendForm = {
        id: null,
        rowNum: null,
        name: '',
        mediaId: null,
        mediaName: '',
        url: '',
        vgt_id: 0,
        originalIndex: 0,
      }
      this.$refs['modal-document'].show()
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    async saveDataSuccess(data) {
      if (data) {
        this.$bvModal.hide('modal-document')
        await this.loadItems()
      }
    },
  },
}
</script>

<style scoped>

</style>
