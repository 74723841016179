import useJwt from '@/auth/jwt/useJwt'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import {
  setIdToken,
  setLoginType, setRefreshToken, setToken, setUser,
} from '@/auth/utils'
import { LOGIN_TYPE } from '@/const/type'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    SET_TOKEN(state, data) {
      setToken(data)
    },
    SET_REFRESH_TOKEN(state, data) {
      setRefreshToken(data)
    },
    SET_USER(state, user) {
      setUser(user)
    },
    SET_LOGIN_TYPE(state, data) {
      setLoginType(data)
    },
    SET_ID_TOKEN(state, data) {
      setIdToken(data)
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await useJwt.login(credentials)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { accessToken, refreshToken } = data.data
          commit('SET_TOKEN', accessToken)
          commit('SET_REFRESH_TOKEN', refreshToken)
          commit('SET_LOGIN_TYPE', LOGIN_TYPE.NORMAL)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async verify({ commit }) {
      try {
        const response = await useJwt.verify()
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { accessToken, refreshToken, user } = data.data
          commit('SET_TOKEN', accessToken)
          commit('SET_REFRESH_TOKEN', refreshToken)
          commit('SET_USER', user)
          return JSON.parse(user)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async verifySso({ commit }) {
      try {
        const response = await useJwt.verifySso()
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const user = data.data
          commit('SET_USER', user)
          return JSON.parse(user)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async setOrganizationForSystemAdmin({ commit }, params) {
      try {
        const response = await axios.put('uni/employees/updateForSystemAdmin', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message, organizationId: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async forgotPassword({ commit }, params) {
      try {
        const response = await axios.post('mq/forgotPassword', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async verifyForgotPassword({ commit }, params) {
      try {
        const response = await useJwt.verifyForgotPassword(params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS && data.data === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetPassword({ commit }, params) {
      try {
        const response = await useJwt.resetPassword(params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
          username: data.data,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
