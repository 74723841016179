import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    maxOrderNo: 0,
    categoryLogsSelection: [],
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
    categoryLogsSelection: state => state.categoryLogsSelection,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => {
      state.dataSources = data
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_CATEGORY_LOGS_SELECTION: (state, selection) => {
      state.categoryLogsSelection = selection
    },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/factCategoryLogs', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('uni/factCategoryLogs', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`uni/factCategoryLogs/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`uni/factCategoryLogs/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('uni/factCategoryLogs/max/order-no/', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCategoryLogsSelection({ commit }) {
      try {
        const response = await axios.get('uni/factCategoryLogs/selection')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CATEGORY_LOGS_SELECTION', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
