import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import id from 'vue2-datepicker/locale/es/id'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.factAdmissionCriteriaTypes,
  maxOrderNoPath: ApiExtRoutes.factAdmissionCriteriaTypesMaxOrderNo,
})
export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
    maxOrderNo: 0,
    dataAdmissionCriteriaTypes: [],
    codeById: ''
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
    maxOrderNo: state => state.maxOrderNo,
    dataAdmissionCriteriaTypes: state => state.dataAdmissionCriteriaTypes,
    codeById: state => state.codeById,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => { state.maxOrderNo = maxOrderNo },
    SET_ADMISSION_CRITERIA_TYPES: (state, dataAdmissionCriteriaTypes) => { state.dataAdmissionCriteriaTypes = dataAdmissionCriteriaTypes },
    SET_CODE_BY_ID: (state, codeById) => { state.codeById = codeById },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionCriteriaTypes({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/allActive`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_CRITERIA_TYPES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCodeById({ commit }, id) {
      try {
        const response = await axios.get(`categories/factAdmissionCriteriaTypes/getById?id=${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.code;
          commit('SET_CODE_BY_ID', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
