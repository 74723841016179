<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="programmeSubjectSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="programmeSubjectSaveModal"
        centered
        size="lg"
        :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-form-group label-for="departmentId">
            <template v-slot:label>
              Khoa/Bộ môn <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Khoa/Bộ môn"
              rules="required"
            >
              <v-select
                v-model="targetData.departmentId"
                :options="departments"
                :reduce="option => option.value"
                :disabled="!isCreated"
                @input="onDepartmentChange"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="subjectId">
            <template v-slot:label>
              Học phần <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Học phần"
              rules="required"
            >
              <v-select
                v-model="targetData.subjectId"
                :options="subjects"
                :reduce="option => option.value"
                :disabled="!isCreated"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Học kỳ (dự kiến)"
            label-for="courseSemesterId"
          >
            <v-select
              v-model="targetData.courseSemesterId"
              :options="courseSemesters"
              :reduce="option => option.value"
            />
          </b-form-group>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Là học phần bắt buộc?"
                label-for="isOptional"
              >
                <template v-slot:label>
                  Là học phần bắt buộc <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Là học phần bắt buộc"
                  rules="required"
                >
                  <v-select
                    v-model="targetData.isOptional"
                    :options="subjectTypeOptions"
                    :reduce="option => option.value"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group label-for="status">
                <template v-slot:label>
                  Trạng thái <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
                >
                  <v-select
                    v-model="targetData.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group label-for="orderNo">
                <template v-slot:label>
                  Thứ tự <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Thứ tự"
                  rules="required"
                >
                  <b-form-input
                    id="orderNo"
                    v-model="targetData.orderNo"
                    name="orderNo"
                    placeholder="Nhập thứ tự"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Nhóm học phần"
                label-for="programmeSubjectGroupId"
              >
                <v-select
                  v-model="targetData.groupId"
                  :options="programmeSubjectGroups"
                  :reduce="option => option.value"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label-for="replaceThesis"
              >
                <b-form-checkbox
                  id="replaceThesis"
                  v-model="targetData.replaceThesis"
                  value="1"
                  unchecked-value="0"
                  class="mt-3"
                >
                  Thay thế KLTN
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="isCreated"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('programmeSubjectSaveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'ProgramSubjectSave',
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    extData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        courseId: parseInt(this.extData.courseId, 10),
        courseSemesterId: 0,
        programmeId: parseInt(this.extData.parentId || this.$route.params.id, 10),
        subjectId: '',
        groupId: '',
        isOptional: 0,
        status: 1,
        orderNo: '',
        replaceThesis: '',
      },
      subjectFilter: {
        organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 1000,
        departmentId: '',
        subjectTypeId: '',
        subjectName: '',
        subjectCode: '',
        status: 1,
        sort: '1_asc',
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'programmeSubject/statuses',
      programmeSubjectTypes: 'programmeSubject/programmeSubjectTypes',
      resourceName: 'programmeSubject/resourceName',
      maxOrderNo: 'programmeSubject/maxOrderNo',
      subjects: 'programmeSubject/subjects',
      courseSemesters: 'dropdown/courseSemesters',
      programmeSubjectGroups: 'dropdown/programmeSubjectGroups',
      departments: 'dropdown/departments',
    }),
    statusOptions() {
      return this.statuses
    },
    subjectTypeOptions() {
      return this.programmeSubjectTypes
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'programmeSubject/createData',
      updateData: 'programmeSubject/updateData',
      getMaxOrderNo: 'programmeSubject/getMaxOrderNo',
      getSubjects: 'programmeSubject/getSubjects',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getProgrammeSubjectGroups: 'dropdown/getProgrammeSubjectGroups',
      getDepartments: 'dropdown/getDepartments',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onDepartmentChange(event) {
      this.targetData.subjectId = null
      this.subjectFilter.departmentId = event
      await this.getSubjects(this.subjectFilter)
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = { ...this.mainData }
      }
      if (this.isCreated) {
        this.targetData.programmeId = parseInt(this.extData.parentId || this.$route.params.id, 10)
        await this.getMaxOrderNo(this.extData.parentId || this.$route.params.id)
        this.targetData.orderNo = this.maxOrderNo + 1
      } else {
        this.subjectFilter.departmentId = this.targetData.departmentId
        await this.getSubjects(this.subjectFilter)
      }
      await Promise.all([
        this.getDepartments({ organizationId: this.user.orgId }),
        this.getCourseSemesters({ organizationId: getUser().orgId, courseId: parseInt(this.extData.courseId, 10), status: '', typeId: 1 }),
        this.getProgrammeSubjectGroups({ organizationId: getUser().orgId, programmeId: this.extData.parentId }),
      ])
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .programmeSubjectSaveFormRef
        .reset()
      this.targetData = {
        courseId: '',
        courseSemesterId: 0,
        programmeId: this.extData.parentId || (this.$route.params.id),
        subjectId: 0,
        groupId: '',
        isOptional: 0,
        status: 1,
        orderNo: 0,
        replaceThesis: '',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .programmeSubjectSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('programmeSubjectSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
