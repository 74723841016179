<template>
  <div>
    <b-modal
      id="conductCriteriaModal"
      title="Danh sách tiêu chí"
      size="xl"
      body-class="position-static"
      centered
      @shown="onShow"
      :no-close-on-backdrop="true"
    >
      <div class="modal-body">
        <div class="body-content-overlay" />
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="todo-task-list-wrapper list-group scroll-area mt-1"
        >
          <draggable
            v-model="tasks"
            handle=".draggable-task-handle"
            tag="ul"
            class="todo-task-list media-list"
          >
            <li
              v-for="task in tasks"
              :key="task.id"
              class="todo-item"
              :class="{ completed: task.isCompleted }"
            >
              <div class="todo-title-wrapper">
                <div class="todo-title-area">
                  <div class="title-wrapper">
                    <b-form-checkbox
                      v-model="task.isCompleted"
                    />
                    <span class="todo-title">{{ task.title }}</span>
                  </div>
                </div>
              </div>
            </li>
          </draggable>
          <div v-if="!tasks.length" class="no-results">
            <h5>No Items Found</h5>
          </div>
        </vue-perfect-scrollbar>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('conductCriteriaModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity="0.75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../conduct-criteria/api'
export default {
  name: 'ConductCriteria',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    draggable,
    VuePerfectScrollbar,
    BButton,
    BOverlay,
  },
  props: {
    conductId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        name: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      tasks: [],
      targetItem: {
        conductId: null,
        conductCriteriaReqs: [],
      },
      listConductCriteria: [],
    }
  },
  computed: {
    statusOptions() {
      return STATUSES
    },
    user() {
      return getUser()
    },
  },
  methods: {
    async onSave() {
      try {
        this.targetItem.conductId = this.conductId
        this.targetItem.conductCriteriaReqs = this.tasks
        const response = await api.createConductToCriteria(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onShow() {
      const data = await api.getListConductCriterias(this.filter)
      this.listConductCriteria = data
      this.tasks = this.listConductCriteria.map(item => ({
        id: item.id,
        title: item.name,
        isCompleted: item.checked,
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;
  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}

.todo-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
  &.completed {
    color: red;
  }
  .todo-title-wrapper {
    display: flex;
    align-items: center;
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.no-results {
  text-align: center;
  color: #6c757d;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
