<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Hệ đào tạo"
                  label-for="trainingSystem"
                >
                  <v-select
                    id="trainingSystem"
                    v-model="filter.trainingSystemId"
                    label="name"
                    :options="trainingSystems"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="course"
                >
                  <v-select
                    id="course"
                    v-model="filter.courseId"
                    label="name"
                    :options="courses"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Học kỳ"
                  label-for="semester"
                >
                  <v-select
                    id="semester"
                    v-model="filter.courseSemesterId"
                    label="name"
                    :options="semesters"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="8"
                xl="8"
              >
                <b-form-group
                  label="Học phần"
                  label-for="subject"
                >
                  <v-select
                    id="subject"
                    v-model="filter.subjectId"
                    label="name"
                    :options="subjects"
                    :reduce="(option) => option.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="getDataSourcesFromStore"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="SearchIcon" /> Tìm kiếm
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    class="mr-1"
                    @click="onResetFilter"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="RefreshCcwIcon" /> Đặt lại
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { Workbook } from '@syncfusion/ej2-excel-export'

export default {
  name: 'CountStudentCancelSubject',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormGroup,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: null,
        currentPage: 1,
        itemsPerPage: 10,
        creditClassCode: null,
        subjectName: null,
        totalCreditNum: null,
        className: null,
        sort: null,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        teacherId: null,
        subjectId: null,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      totalRows: 'countStudentCancelSubject/totalRows',
      dataSources: 'countStudentCancelSubject/dataSources',
      trainingSystems: 'trainingSystem/trainingSystems',
      courses: 'course/courses',
      semesters: 'semester/semesters',
      subjects: 'subject/subjects',
    }),
    columns() {
      return [
        {
          label: 'STT',
          field: 'rowNum',
          width: '5%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Mã học phần',
          field: 'code',
          thClass: 'text-center',
        },
        {
          label: 'Học phần',
          field: 'name',
          thClass: 'text-center',
        },
        {
          label: 'Số lượng sinh viên đã hủy đăng ký',
          field: 'countStudents',
          thClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ courseId: null, courseSemesterId: null })
        this.setCourses([])
        this.setSemesters([])
        this.setSubjects([])
      }
    },
    'filter.courseId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSemestersByCourseId({ courseId: val })
          if (this.semesters.length > 0) {
            this.updateParams({ courseSemesterId: this.semesters[0].id })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ courseSemesterId: null })
        this.setSemesters([])
        this.setSubjects([])
      }
    },
    'filter.courseSemesterId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          if (this.isShowTeachersSelect) {
            await this.getTeachersByCourseSemesterId({ courseSemesterId: val })
          } else {
            await this.getSubjectsByCourseSemesterId({
              courseSemesterId: val,
            })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else if (this.isShowTeachersSelect) {
        this.updateParams({
          teacherId: null,
          subjectId: null,
        })
        this.setSubjects([])
      } else {
        this.updateParams({ subjectId: null })
        this.setSubjects([])
      }
    },
  },
  async created() {
    this.setDataSources({ total: 0, records: [] })
    this.setSubjects([])
    // this.updateParams({ organizationId: this.user.orgId })
    if (!this.isShowTeachersSelect) {
      this.updateParams({ teacherId: this.user.teacherId })
    }
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({
        organizationId: this.user.orgId,
      })
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setCourses: 'course/SET_COURSES',
      setSemesters: 'semester/SET_SEMESTERS',
      setSubjects: 'subject/SET_SUBJECTS',
      setDataSources: 'countStudentCancelSubject/SET_DATA_SOURCES',
    }),
    ...mapActions({
      getDataSources: 'countStudentCancelSubject/getDataSources',
      getAllData: 'countStudentCancelSubject/getAllData',
      getDataPrint: 'countStudentCancelSubject/getDataPrint',
      getTrainingSystemsByOrganizationId:
        'trainingSystem/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'course/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'semester/getSemestersByCourseId',
      getSubjectsByCourseSemesterId:
        'subject/getSubjectsByCourseSemesterId',
      getTeachersByCourseSemesterId: 'teacher/getTeachersByCourseSemesterId',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onResetFilter() {
      this.updateParams({
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
      })
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        switch (field) {
          case 'creditClassCode':
            this.updateParams({ sort: `1_${type}` })
            break
          case 'subjectName':
            this.updateParams({ sort: `2_${type}` })
            break
          case 'className':
            this.updateParams({ sort: `3_${type}` })
            break
          default:
            this.updateParams({ sort: `4_${type}` })
            break
        }
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      await this.getDataSourcesFromStore()
    },
    async exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.updateParams({ currentPage: 1, itemsPerPage: 100000 })
      const dataAll = await this.getAllData(this.filter)
      const columns = [
        { index: 1, width: 50 },
        { index: 2, width: 150 },
        { index: 3, width: 250 },
        { index: 4, width: 250 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'STT',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'Mã học phần',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'Học phần',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'Số lượng sinh viên đã hủy đăng ký',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      rows.push(headerRow)
      for (let i = 0; i < dataAll.length; i++) {
        const index = 1
        const rowCell = []
        const item = dataAll[i]
        const col1 = {
          index,
          value: i + 1,
        }
        const col2 = {
          index: index + 1,
          value: item.code,
        }
        const col3 = {
          index: index + 2,
          value: item.name,
        }
        const col4 = {
          index: index + 3,
          value: item.countStudents,
        }
        rowCell.push(col1, col2, col3, col4)
        const rowsNew = {
          index: i + 2,
          cells: rowCell,
        }
        rows.push(rowsNew)
      }
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('export_sinh_vien_huy_dang_ky.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
