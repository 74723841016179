import axios from '@/libs/axios'
import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'

export default {
  async getTrainingSystemsByOrganizationId() {
    try {
      const response = await axios.get('uni/trainingSystems/getByOrganizationId')
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
    return []
  },
  async getCoursesByTrainingSystemId(params) {
    try {
      const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
    return []
  },
}
