export const DossierStatus = Object.freeze({
  WAITING_FOR_RECEPTION: 0,
  WAITING_FOR_PROCESSING: 1,
  COMPLETED: 2,
  REJECT: 3,
  REQUIRED_ADDITION_DOCUMENTS: 4,
  DRAFT: 5,
  CANCEL: 6,
  ADDITION_DOSSIER: 7,
})

export const FileDossierStatus = Object.freeze({
  TEMPLATE_ATTACHMENT_DOSSIER: 1,
  TEMPLATE_RESULT: 2,
})

export const TemplateFileStatus = Object.freeze({
  TEMPLATE_ATTACHMENT_DOSSIER: 1,
  TEMPLATE_RESULT: 2,
  RESULT_FILE: 3,
})
