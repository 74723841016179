<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="nav-vertical"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Thông tin chung</span>
      </template>

      <CurriculumVitaeInfo
        :student-info="studentInfo"
        :student-id="studentId"
        @succeed="$emit('succeed')"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="AwardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Kết quả học tập</span>
      </template>
      <CurriculumVitaeLearning
        :curriculum-vitae-learnings="curriculumVitaeLearnings"
        :student-class-id="studentClassId"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="ApertureIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Thưởng phạt</span>
      </template>
      <CurriculumVitaeScholarshipAwardDiscipline
        :curriculum-vitae-scholarships="curriculumVitaeScholarships"
        :curriculum-vitae-awards="curriculumVitaeAwards"
        :curriculum-vitae-disciplines="curriculumVitaeDisciplines"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="GiftIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Trợ cấp</span>
      </template>
      <CurriculumVitaeSubsidize
        :curriculum-vitae-policies="curriculumVitaePolicies"
        :curriculum-vitae-allowances="curriculumVitaeAllowances"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import CurriculumVitaeInfo from '@/views/curriculum-vitae/CurriculumVitaeInfo.vue'
import CurriculumVitaeLearning from '@/views/curriculum-vitae/CurriculumVitaeLearning.vue'
import CurriculumVitaeScholarshipAwardDiscipline from '@/views/curriculum-vitae/CurriculumVitaeScholarshipAwardDiscipline.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import CurriculumVitaeSubsidize from '@/views/curriculum-vitae/CurriculumVitaeSubsidize.vue'

export default {
  name: 'CurriculumVitae',
  components: {
    CurriculumVitaeSubsidize,
    CurriculumVitaeScholarshipAwardDiscipline,
    CurriculumVitaeLearning,
    CurriculumVitaeInfo,
    BTabs,
    BTab,
  },
  props: {
    studentId: {
      type: Number,
      default: 0,
    },
    classId: {
      type: Number,
      default: 0,
    },
    studentClassId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      studentInfo: 'curriculumVitae/studentInfo',
      curriculumVitaeLearnings: 'curriculumVitae/curriculumVitaeLearnings',
      curriculumVitaeScholarships: 'curriculumVitae/curriculumVitaeScholarships',
      curriculumVitaeAwards: 'curriculumVitae/curriculumVitaeAwards',
      curriculumVitaeDisciplines: 'curriculumVitae/curriculumVitaeDisciplines',
      curriculumVitaePolicies: 'curriculumVitae/curriculumVitaePolicies',
      curriculumVitaeAllowances: 'curriculumVitae/curriculumVitaeAllowances',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      await this.getStudentInfo({ studentId: this.studentId, classId: this.classId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getStudentInfo: 'curriculumVitae/getStudentInfo',
    }),
  },
}
</script>
