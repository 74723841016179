<template>
  <validation-observer
    ref="saveFormRef"
  >
    <b-modal
      id="historyDossierModal"
      centered
      title="Lịch sử hồ sơ"
      size="lg"
      :no-close-on-backdrop="true"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="code">
              <h5>Mã hồ sơ</h5>
              <p>{{ targetItem.dossierCode }}</p>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="name">
              <h5>Tên thủ tục</h5>
              <p>{{ targetItem.procedureName }}</p>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="studentCode">
              <h5>Mã sinh viên</h5>
              <p>{{ targetItem.studentCode }}</p>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="studentName">
              <h5>Tên sinh viên</h5>
              <p>{{ targetItem.studentName }}</p>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h5>Lịch sử xử lý hồ sơ</h5>
            <b-row>
              <b-col cols="12">
                <div class="timeline">
                  <div
                    v-for="(historyDossier, index) in dossiers.historiesDossiers"
                    :key="index"
                    class="timeline-step"
                  >
                    <div class="timeline-header">
                      <h6>Tên bước: {{ historyDossier.stepProcessName }}</h6>
                      <p>Người xử lý: {{ historyDossier.employeeName }}</p>
                    </div>

                    <div class="timeline-content">
                      <div
                        v-for="(history, subIndex) in historyDossier.histories"
                        :key="subIndex"
                        class="timeline-item"
                      >
                        <div
                          :class="['timeline-dot', getDotClass(history.statusProcess)]"
                        />
                        <div class="timeline-details">
                          <p><strong>Trạng thái:</strong> {{ getStatusText(history.statusProcess) }}</p>
                          <p><strong>Thời gian:</strong> {{ formatDate(history.createdAt) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('historyDossierModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Đóng
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BModal, BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { DOSSIER_STATUS } from '@/const/status'
import axios from '@/libs/axios'
import { handleCatch } from '@/utils'
import { DossierStatus } from '@/const/dossier-status'
import moment from 'moment/moment'

export default {
  name: 'HistoryDossierSave',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      dossiers: [],
      targetItem: {},
      required,
      tableFields: [
        { key: 'statusProcess', label: 'Trạng thái xử lý' },
        { key: 'createdAt', label: 'Thời gian xử lý' },
      ],
      statuses: DOSSIER_STATUS.filter(status => [
        DossierStatus.WAITING_FOR_PROCESSING,
        DossierStatus.COMPLETED,
        DossierStatus.REJECT,
        DossierStatus.REQUIRED_ADDITION_DOCUMENTS,
      ].includes(status.value)),
    }
  },
  computed: {
    DossierStatus() {
      return DossierStatus
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      try {
        const response = await axios.get(`oneGate/dossiers/getHistoryDossier/${this.targetItem.id}`)
        const { data } = response
        this.dossiers = data.data
      } catch (e) {
        handleCatch(e)
      } finally {
        this.isLoading = false
      }
    },
    formatDate(date) {
      return date ? moment(date, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('YYYY/MM/DD HH:mm:ss') : ''
    },
    getDotClass(status) {
      if (status === DossierStatus.COMPLETED) return 'timeline-dot-completed'
      if (status === DossierStatus.WAITING_FOR_RECEPTION) return 'timeline-dot-pending'
      return 'timeline-dot-processing'
    },
    getStatusText(status) {
      if (status === DossierStatus.WAITING_FOR_RECEPTION) return 'Chờ xử lý'
      if (status === DossierStatus.WAITING_FOR_PROCESSING) return 'Đang xử lý'
      if (status === DossierStatus.COMPLETED) return 'Đã hoàn thành'
      if (status === DossierStatus.REJECT) return 'Từ chối'
      if (status === DossierStatus.REQUIRED_ADDITION_DOCUMENTS) return 'Yêu cầu bổ sung hồ sơ'
      return 'Không xác định'
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
        this.fetchData()
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        status: 0,
        reasonReject: '',
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>

<style scoped>
/* Timeline Styles */
.timeline {
  position: relative;
  margin-top: 20px;
  padding-left: 20px;
  border-left: 2px solid #007bff;
}

.timeline-step {
  margin-bottom: 20px;
}

.timeline-header h6 {
  font-weight: bold;
  margin-bottom: 8px;
}

.timeline-header p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}

.timeline-content {
  margin-top: 10px;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-left: 20px;
  padding-bottom: 10px;
}

.timeline-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: -6px;
  z-index: 1;
}

.timeline-dot-pending {
  background-color: #007bff; /* Màu xanh */
}

.timeline-dot-processing {
  background-color: #ffc107; /* Màu vàng */
}

.timeline-dot-completed {
  background-color: #dc3545; /* Màu đỏ */
}

.timeline-details {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.timeline-details p {
  margin: 0 0 5px 0;
  font-size: 14px;
}

.timeline-details p:last-child {
  margin-bottom: 0;
}
</style>
