import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    students: [],
    studentByCode: null,
  },
  getters: {
    students: state => state.students,
    studentByCode: state => state.studentByCode,
  },
  mutations: {
    SET_STUDENTS: (state, students) => { state.students = students },
    SET_STUDENT_BY_CODE: (state, studentByCode) => { state.studentByCode = studentByCode },
  },
  actions: {
    async getStudentsNotLinkingAccount({ commit }) {
      try {
        const response = await axios.get('uni/students/notLinkingAccount')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentByCode({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getStudentByCode', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_STUDENT_BY_CODE', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getStudentsByCourse({ commit }, params) {
      try {
        const response = await axios.get('uni/students/getStudentsByCourse', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          commit('SET_STUDENTS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
