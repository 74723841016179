var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"StepProcessSaveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"stepProcessSaveModal","body-class":"position-static","centered":"","size":"xl","title":"Cập nhật các bước của quy trình","no-close-on-backdrop":""},on:{"show":_vm.onShow,"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('stepProcessSaveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_vm._l((_vm.targetData),function(step,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"code"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mã bước "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Mã bước","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","name":"code","placeholder":"Nhập mã bước","state":_vm.getElementState(errors)},model:{value:(step.code),callback:function ($$v) {_vm.$set(step, "code", $$v)},expression:"step.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên bước "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên bước","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Nhập tên bước","state":_vm.getElementState(errors)},model:{value:(step.name),callback:function ($$v) {_vm.$set(step, "name", $$v)},expression:"step.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"departmentId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phòng ban "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Phòng ban","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.departments,"reduce":function (option) { return option.id; },"label":"name"},on:{"input":function($event){return _vm.onChangeDepartments(index)}},model:{value:(step.departmentId),callback:function ($$v) {_vm.$set(step, "departmentId", $$v)},expression:"step.departmentId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"employeeId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nhân viên xử lý "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nhân viên xử lý","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":step.employeeOptions,"reduce":function (option) { return option.employeeId; },"label":"name"},model:{value:(step.employeeId),callback:function ($$v) {_vm.$set(step, "employeeId", $$v)},expression:"step.employeeId"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getElementState(errors)}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"orderNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thứ tự "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Thứ tự","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNo","name":"orderNo","placeholder":"Nhập thứ tự","state":_vm.getElementState(errors),"disabled":index === 0},model:{value:(step.orderNo),callback:function ($$v) {_vm.$set(step, "orderNo", $$v)},expression:"step.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Trạng thái "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Trạng thái","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.statuses,"reduce":function (option) { return option.value; }},model:{value:(step.status),callback:function ($$v) {_vm.$set(step, "status", $$v)},expression:"step.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"description"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ghi chú ")]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Ghi chú"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","name":"description","placeholder":"Nhập ghi chú bước","state":_vm.getElementState(errors)},model:{value:(step.description),callback:function ($$v) {_vm.$set(step, "description", $$v)},expression:"step.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(index !== 0)?_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteStep(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_vm._v(" Xóa bước ")],1)],1):_vm._e(),_c('div',{staticStyle:{"width":"100%"}},[_c('hr')])],1)}),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.addStep}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" Thêm bước ")],1)],2),_c('b-overlay',{attrs:{"no-wrap":"","variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }