import axios from '@/libs/axios'
import { handleCatch } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async importScheduler(params) {
    try {
      const response = await axios.post('uni/schedules/importScheduler', { params })
      const { data } = response
      return data;
    } catch (e) {
      handleCatch(e)
    }
  },
}
