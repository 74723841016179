import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async importScoreDhhl(body) {
    try {
      const response = await axios.post('uni/hluv/importScore', body)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
}
