import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  // eslint-disable-next-line consistent-return
  async getSubjectByExaminationScheduler(params) {
    try {
      const response = await axios.get('uni/examination_schedulers_hbh/getSubjects', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data ? data.data : []
      }
    } catch (e) {
      handleCatch(e)
    }
  },

  async insertExaminationRooms(params) {
    try {
      const response = await axios.post('uni/examinationRooms/createExaminationRoomCdspHoaBinh', params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
}
