import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  async getDataSources(params) {
    try {
      const response = await axios.get('uni/conductCriterias', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async getListConductCriterias(params) {
    try {
      const response = await axios.get('uni/conductCriterias/listConductCriteria', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async createConductCriteria(params) {
    try {
      const response = await axios.post('uni/conductCriterias', params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async createConductToCriteria(params) {
    try {
      const response = await axios.post('uni/conductToCriterias', params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async updateConductCriteria(params) {
    try {
      const response = await axios.put(`uni/conductCriterias/${params.id}`, params)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async deleteConductCriteria(id) {
    try {
      const response = await axios.delete(`uni/conductCriterias/${id}`)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
}
