<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  title="1. Hệ đào tạo"
                  label-for="trainingSystemSelect"
                >
                  <v-select
                    v-model="trainingSystemSelect"
                    :options="trainingSystemsOptions"
                    :reduce="(option) => option.value"
                    placeholder="1. Hệ đào tạo"
                    @input="onChangeTrainingSystem"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  title="2. Khóa - Học kỳ"
                  label-for="coursesSemestersSelected"
                >
                  <v-select
                    v-model="coursesSemestersSelected"
                    :options="coursesSemestersOptions"
                    :reduce="(option) => option.value"
                    placeholder="2. Khóa - Học kỳ"
                    @input="onChangeCoursesSemester"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="2"
              >
                <b-form-group
                  title="Ngày bắt đầu"
                  label-for="startDate"
                >
                  <b-form-datepicker
                    id="startDate"
                    v-model="startDate"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="vi"
                    format="dd/mm/yyyy"
                    placeholder="Ngày bắt đầu"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="1"
              >
                <b-form-group
                  title="Số lượng sinh viên trong phòng"
                >
                  <b-form-input
                    v-model="studentInRoom"
                    :step="1"
                    min="1"
                    type="number"
                    class="text-bold text-danger"
                    placeholder="Số lượng phòng"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <div style="padding-top: 8px">
                  <span
                    style="font-weight: bold; cursor: pointer; text-decoration: underline"
                    @click="showStudent"
                  >{{ studentsRemake.length }} sinh viên thi lại</span>
                </div>
              </b-col>
              <b-col
                cols="7"
              >
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSearchRoom"
                  >
                    <span class="text-nowrap text-right">  <feather-icon icon="ListIcon" /> Danh sách</span>
                  </b-button>

                  <b-dropdown
                    v-show="examinationRooms.length > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    text="Xuất PDF"
                    class="mr-1"
                    right
                    variant="outline-primary"
                  >
                    <b-dropdown-item
                      v-if="hasExportPdfStudentsExaminationRoom"
                      @click="exportPdfStudentsExaminationRoom"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In DS phòng thi
                      </span>
                    </b-dropdown-item>
                    <!--                    <b-dropdown-item v-if="hasExportPdfStudentsExaminationScore" @click="exportPdfStudentsExaminationScoreFile">-->
                    <!--                      <span class="text-nowrap text-right">-->
                    <!--                        <feather-icon icon="FileIcon" /> In phiếu ghi điểm SBD-->
                    <!--                      </span>-->
                    <!--                    </b-dropdown-item>-->
                    <b-dropdown-item
                      v-show="exportStudentsHeaderCode"
                      @click="exportPdfStudentsHeaderCodeFile"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In DS phách sinh viên
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-show="studentExaminationRoomReadable"
                      @click="exportPdfStudentsHeaderCodeScoreFile"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> In DS phách vào điểm sinh viên
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    v-show="examinationRooms.length > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    text="Xuất Excel"
                    class="mr-1"
                    right
                    variant="outline-success"
                  >
                    <b-dropdown-item @click="exportStudentsExamination">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> Xuất danh sách sinh viên
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportSupervisorsExamination">
                      <span class="text-nowrap text-right">
                        <feather-icon icon="FileIcon" /> Xuất danh sách giám thị
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-button
                    v-show="enableCreateRoom"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    style="margin-right: 5px"
                    @click="onCreateExaminationRoom"
                  >
                    <span class="text-nowrap text-right">  <feather-icon icon="PlusIcon" /> Tạo phòng thi lại</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="examinationRooms"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button-group>
                        <b-button
                          v-if="updatable && props.row.status !== 2"
                          v-b-modal.examinationRoomRemakeSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onEditExaminationRoom(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                        >
                          <b-dropdown-item
                            v-if="studentExaminationRoomReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowsStudentExaminationRoom(props.row)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Danh sách học viên
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="examInvigilatorReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onAssignExamInvigilator(props.row.id, props.row.status)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Phân công coi thi
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <examinationRoomSave
      ref="examinationRoomsSaveFormRef"
      :examination-room="currentExaminationRoom"
      @succeed="onSucceed"
    />
    <b-modal
      id="studentExaminationRoomModal"
      centered
      :title="`Danh sách học viên: ${(currentExaminationRoom)?currentExaminationRoom.name : ''}`"
      size="xl"
      body-class="position-static"
      no-close-on-backdrop
      hide-footer
    >
      <StudentExaminationRoom
        :ext-data="currentExaminationRoom"
        :status-examination-room="currentExaminationRoomStatus"
      />
    </b-modal>
    <ExaminationRoomTeacher
      :examination-room-id="currentExaminationRoomId"
      :subject-id="selectedSubjectId"
      :status-examination-room="currentExaminationRoomStatus"
    />

    <b-modal
      id="modal-info"
      ref="modal-info"
      ok-only
      centered
      size="xl"
      title="Danh sách thí sinh thi lại"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <div>

        <span
          class="text-nowrap text-right btn btn-primary mt-1 mb-1"
          @click="exportExcel()"
        >
          <feather-icon icon="DownloadIcon" /> Xuất Excel
        </span>
        <vue-good-table
          :columns="table.columns"
          :rows="table.rows"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPageDropdown: [10, 20, 50],
            dropdownAllowAll: true
          }"
        />
      </div>
    </b-modal>

    <examinationRoomRemakeSave
      ref="examinationRoomRemakeSaveModal"
      :examination-room="currentExaminationRoom"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow, BFormDatepicker, BDropdown, BDropdownItem, BModal, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import examinationRoomSave from '@/views/examination-room/ExaminationRoomSave.vue'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import ExaminationRoomTeacher from '@/views/examination-room/examination-room-teacher/ExaminationRoomTeacher.vue'
import StudentExaminationRoom from '@/views/student-examination-room/StudentExaminationRoom.vue'
import * as XLSX from 'xlsx'
import examinationRoomRemakeSave from '@/views/examination-room-remake/ExaminationRoomRemakeSave.vue'

export default {
  name: 'ExaminationRoom',
  directives: {
    Ripple,
  },
  components: {
    examinationRoomRemakeSave,
    BFormInput,
    BModal,
    StudentExaminationRoom,
    ExaminationRoomTeacher,
    BDropdownItem,
    BDropdown,
    examinationRoomSave,
    BFormGroup,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemSelect: undefined,
      coursesSemestersSelected: undefined,
      courseSemesterSubjectSelect: undefined,
      examinationSchedulerSelect: undefined,
      listCreditClass: undefined,
      startDate: undefined,
      isExistExaminationRoom: 0,
      isCountStudent: 0,
      isThiLai: 1,
      isLoading: false,
      countRoom: 1,
      filter: {
        training_system_id: null,
        course_semester_id: null,
        currentPage: 1,
        itemsPerPage: 10,
        subject_id: null,
        status: 1,
        separate: 0,
      },
      currentExaminationRoom: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên phòng thi lai',
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Địa điểm thi lại',
          field: 'room',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số sinh viên',
          field: 'countStudent',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'subject',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giám thị',
          field: 'supervisorNum',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Bắt đầu',
          field: 'start_at',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Kết thúc',
          field: 'end_at',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      currentExaminationRoomId: null,
      currentExaminationRoomStatus: null,
      table: {
        columns: [
          {
            label: 'Môn học',
            field: 'subjectName',
          },
          {
            label: 'Lớp độc lập',
            field: 'creditClassCode',
          },
          {
            label: 'Tên học sinh',
            field: 'studentName',
          },
          {
            label: 'Mã học sinh',
            field: 'userName',
          },
          {
            label: 'Điểm',
            field: 'score',
          },
          {
            label: 'Ghi chú',
            field: 'note',
          },
        ],
        rows: [],
      },
      studentInRoom: 25,
    }
  },
  computed: {
    ...mapGetters({
      supervisorExamination: 'examinationRoom/supervisorExamination',
      studentExamination: 'studentExaminationRoom/studentExamination',
      examinationRooms: 'examinationRoom/examinationRooms',
      totalRows: 'examinationRoom/totalRows',
      allTrainingSystems: 'examinationRoom/allTrainingSystems',
      coursesSemesters: 'examinationRoom/coursesSemesters',
      courseSemesterSubject: 'examinationRoom/listCourseSemesterSubject',
      courseSemesterSubjectThiLai: 'examinationRoom/listCourseSemesterSubjectThiLai',
      examinationSchedulers: 'dropdown/examinationSchedulers',
      examinationSchedulerSubjects: 'dropdown/examinationSchedulerSubjects',
      examinationSchedulerSubjectDates: 'dropdown/examinationSchedulerSubjectDates',
      studentsRemake: 'examinationRoom/studentRemake',
    }),
    coursesSemestersOptions() {
      return this.coursesSemesters.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CREATE_EXAMINATION_ROOM)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.CREATE_EXAMINATION_ROOM)
    },
    selectedSubjectId() {
      try {
        // eslint-disable-next-line eqeqeq
        return this.courseSemesterSubject.find(e => e.code === this.courseSemesterSubjectSelect).id || 0
      } catch (e) {
        return 0
      }
    },
    studentExaminationRoomReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.STUDENT_EXAMINATION_ROOM)
    },
    examInvigilatorReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.EXAMINATION_ROOM_TEACHER)
    },
    exportStudentsHeaderCode() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.HEADER_CODE_EXAM)
    },
    hasExportPdfStudentsExaminationRoom() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.EXPORT_PDF_STUDENT_EXAMINATION_ROOM)
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({ organizationId: this.user.orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getAllRooms: 'examinationRoom/getAllRooms',
      getAllSubjects: 'examinationRoom/getAllSubjects',
      getTrainingSystemsByOrganizationId: 'examinationRoom/getTrainingSystemsByOrganizationId',
      getExaminationRooms: 'examinationRoom/getExaminationRooms',
      getExaminationRoomsRemake: 'examinationRoom/getExaminationRoomsRemake',
      getStudentRemake: 'examinationRoom/getStudentRemake',
      getCourseSemesters: 'examinationRoom/getCourseSemesters',
      getCourseSemesterSubjectSelect: 'examinationRoom/getCourseSemesterSubject',
      getCourseSemesterSubjectSelectThiLai: 'examinationRoom/getCourseSemesterSubjectThiLai',
      insertExaminationRoom: 'examinationRoom/insertExaminationRooms',
      insertExaminationRoomRemake: 'examinationRoom/insertExaminationRoomsRemake',
      getExaminationSchedulers: 'dropdown/getExaminationSchedulers',
      getExaminationSchedulerSubjects: 'dropdown/getExaminationSchedulerSubjects',
      exportStudentsExaminationRoom: 'examinationRoom/exportExaminationRoomRemakePdf',
      exportPdfStudentsHeaderCode: 'examinationRoom/exportPdfStudentsHeaderCodeFile',
      exportPdfStudentsHeaderCodeScore: 'examinationRoom/exportPdfStudentsHeaderCodeScoreFile',
      exportPdfStudentsExaminationScore: 'studentExaminationRoom/exportPdfStudentsExaminationRemakeScore',
      getAllStudentExamination: 'studentExaminationRoom/getAllStudentExamination',
      getAllSupervisorExamination: 'examinationRoom/getAllSupervisorExamination',
    }),
    enableCreateRoom() {
      return this.isCountStudent > 0 && this.countRoom > 0 && this.creatable && this.isExistExaminationRoom === 0
    },

    exportExcel() {
      let data = []
      if (this.table.rows.length > 0) {
        data = this.table.rows.map((element, index) => ({
          STT: index + 1,
          MonThiLai: element.subjectName,
          Lop: element.className,
          LopDocLap: element.creditClassCode,
          HoTen: element.studentName,
          MaSV: element.userName,
          Diem: element.score,
          GhiChu: element.note,
        }))
      }
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'scores')
      XLSX.writeFile(wb, 'DanhSachHocSinhThiLai.xlsx')
    },

    async onSearchRoom() {
      this.isLoading = true
      try {
        if (!this.filter.course_semester_id || !this.coursesSemestersSelected) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vui lòng chọn học kỳ',
              icon: 'XCircleIcon',
              variant: 'warning',
            },
          })
        } else {
          await this.getExaminationRoomsRemake(this.filter)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    showStudent() {
      this.$bvModal.show('modal-info')
    },
    onShowsStudentExaminationRoom(row) {
      const { originalIndex, vgt_id, ...rest } = row
      this.currentExaminationRoom = { ...rest }
      this.currentExaminationRoomStatus = rest.status
      this.$bvModal.show('studentExaminationRoomModal')
    },

    onAssignExamInvigilator(id, status) {
      this.currentExaminationRoomId = id
      this.currentExaminationRoomStatus = status
      this.$bvModal.show('examinationRoomTeacherModal')
    },

    async onChangeTrainingSystem() {
      if (this.trainingSystemSelect == null) {
        this.reset()
        this.$store.commit('examinationRoom/SET_COURSESSEMESTERS', {
          data: [],
        })
        this.$store.commit('examinationRoom/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.coursesSemestersSelected = null
        this.courseSemesterSubjectSelect = null
      } else {
        await this.getCourseSemesters({
          organizationId: getUser().orgId,
          training_system_id: this.trainingSystemSelect,
        })
      }
    },
    async onChangeCoursesSemester() {
      this.examinationSchedulerSelect = null
      if (this.coursesSemestersSelected == null) {
        this.reset()
        this.$store.commit('examinationRoom/SET_COURSE_SEMESTER_SUBJECT', {
          data: [],
        })
        this.courseSemesterSubjectSelect = null
      } else {
        // Lay danh sách phòng thi lại theo học kỳ
        this.filter.course_semester_id = this.coursesSemestersSelected
        await this.getStudentRemake({ courseSemesterId: this.coursesSemestersSelected })
        this.table.rows = this.studentsRemake
        await this.getExaminationRoomsRemake(this.filter)
      }
    },

    reset() {
      this.listCreditClass = null
      this.isExistExaminationRoom = 0
      this.isCountStudent = 0
      this.countRoom = 0
      this.startDate = undefined
      this.$store.commit('examinationRoom/SET_EXAMINATIONROOMS', {
        total: 0,
        records: [],
      })
    },
    async onCreateExaminationRoom() {
      if (!this.startDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ngày bắt đầu không được trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (parseInt(this.studentInRoom) < 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Số sinh viên trong phòng phải lớn hn 0',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$swal({
        title: 'Bạn muốn tạo phòng thi lại cho kỳ học này',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.insertExaminationRoomRemake({
              courseSemesterId: this.coursesSemestersSelected,
              start_date: this.startDate,
              studentInRoom: this.studentInRoom,
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                this.isExistExaminationRoom = 1
                this.filter.subject = this.selectedSubjectId
                this.filter.separate = this.isSeparateRoom
                await this.getExaminationRoomsRemake(this.filter)
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onEditExaminationRoom(examinationRoom) {
      const {
        rowNum, originalIndex, vgt_id, ...rest
      } = examinationRoom
      this.currentExaminationRoom = rest
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getExaminationRoomsFromStore()
    },
    async getExaminationRoomsFromStore() {
      this.isLoading = true
      try {
        await this.getExaminationRooms(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getExaminationRoomsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
        else if (field === 'code') this.updateParams({ sort: `2_${type}` })
        else this.updateParams({ sort: `3_${type}` })
      }
      await this.getExaminationRoomsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'room')) this.updateParams({ room: columnFilters.room })
      if (Object.hasOwn(columnFilters, 'subject')) this.updateParams({ subject: columnFilters.subject })
      await this.getExaminationRoomsFromStore()
    },
    async onSucceed() {
      await this.getExaminationRoomsFromStore()
    },
    async exportPdfStudentsExaminationRoom() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportStudentsExaminationRoom({
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
          isRetest: this.isThiLai,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportExcelStudentsExaminationRoom() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        this.filterExport.type = 'excel'
        this.filterExport.courseSemesterId = this.coursesSemestersSelected
        this.filterExport.subjectId = this.selectedSubjectId
        this.filterExport.isRetest = this.isThiLai
        const data = await this.downloadExcelStudentExaminationRoom(this.filterExport)
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'danh_sach_phong_thi_hoc_phan.xls')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportSupervisorsExamination() {
      const rest = {
        courseSemesterId: this.coursesSemestersSelected,
        trainingSystemId: this.trainingSystemSelect,
        again: 1,
      }
      await this.getAllSupervisorExamination(rest)
      if (this.supervisorExamination.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có giám thị được phân công',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const maxTeachers = Math.max(
          ...this.supervisorExamination.map(supervisor => supervisor.teachers.length),
        )
        const headers = ['STT', 'Tên phòng thi']
        for (let i = 1; i <= maxTeachers; i++) {
          headers.push(`Giám thị ${i}`)
        }
        const worksheetData = [headers]
        this.supervisorExamination.forEach((supervisor, index) => {
          const rowData = [
            index + 1,
            supervisor.roomName,
          ]
          supervisor.teachers.forEach(teacher => {
            rowData.push(teacher.teacherName)
          })
          while (rowData.length < headers.length) {
            rowData.push('')
          }
          worksheetData.push(rowData)
        })
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = { c: col, r: row }
            const cellRef = XLSX.utils.encode_cell(cellAddress)
            if (!worksheet[cellRef]) continue
            worksheet[cellRef].s = {
              alignment: { horizontal: 'center', vertical: 'center' },
              border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              },
            }
          }
        }

        worksheet['!cols'] = [
          { wch: 5 },
          { wch: 30 },
          ...Array(maxTeachers).fill({ wch: 20 }),
        ]
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách giám thị')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        saveAs(new Blob([excelBuffer]), 'Danh sách giám thị.xlsx')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đã xảy ra lỗi khi xuất danh sách giám thị',
            description: error.message || 'Vui lòng thử lại sau',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfStudentsExaminationScoreFile() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfStudentsExaminationScore({
          courseSemesterId: this.coursesSemestersSelected,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfStudentsHeaderCodeFile() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfStudentsHeaderCode({
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
          isRetest: this.isThiLai,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportStudentsExamination() {
      const rest = {
        courseSemesterId: this.coursesSemestersSelected,
        trainingSystemId: this.trainingSystemSelect,
        again: 1,
      }
      await this.getAllStudentExamination(rest)
      if (this.studentExamination.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu sinh viên để xuất',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const worksheetData = [
          ['STT', 'Mã sinh viên', 'Số báo danh', 'Họ và tên', 'Ngày sinh', 'Lớp học'],
        ]
        this.studentExamination.forEach((student, index) => {
          worksheetData.push([
            index + 1,
            student.code,
            student.bib_number,
            student.name,
            student.birthday,
            student.class_name,
          ])
        })
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        for (let row = range.s.r; row <= range.e.r; row++) {
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = { c: col, r: row }
            const cellRef = XLSX.utils.encode_cell(cellAddress)
            if (!worksheet[cellRef]) continue
            worksheet[cellRef].s = {
              alignment: { horizontal: 'center', vertical: 'center' },
              border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              },
            }
          }
        }
        worksheet['!cols'] = [
          { wch: 5 },
          { wch: 15 },
          { wch: 5 },
          { wch: 30 },
          { wch: 15 },
          { wch: 20 },
        ]
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách sinh viên')
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
        saveAs(new Blob([excelBuffer]), 'Danh sách sinh viên.xlsx')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Đã xảy ra lỗi khi xuất danh sách sinh viên',
            description: error.message || 'Vui lòng thử lại sau',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportPdfStudentsHeaderCodeScoreFile() {
      if (this.examinationRooms.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdfStudentsHeaderCodeScore({
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
          isRetest: this.isThiLai,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Phòng thi chưa có sinh viên nào được đánh phách.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
