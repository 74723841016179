<template>
  <validation-observer
    ref="examinationSchedulerSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="examinationSchedulerSaveModal"
      body-class="position-static"
      centered
      size="lg"
      no-close-on-backdrop
      :title="isCreated ? 'Thêm mới dự kến thi' : 'Cập nhật dự kiến thi' "
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="8">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Hệ đào tạo <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hệ đào tạo"
                rules="required"
              >
                <v-select
                  v-model="targetData.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  id="selectCourses"
                  v-model="targetData.courseId"
                  :options="courses"
                  :reduce="option => option.value"
                  @input="onCoursesChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="courseSemesterId">
              <template v-slot:label>
                Học kỳ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kỳ"
                rules="required"
              >
                <v-select
                  v-model="targetData.courseSemesterId"
                  :options="courseSemesters"
                  :reduce="option => option.value"
                  @input="onCoursesSemesterChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên kỳ thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên kỳ thi"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetData.name"
                  name="name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="creditClassIds">
              <template v-slot:label>
                Tổ chức cho các lớp <span class="text-danger">*</span>
              </template>
              <v-select
                id="creditClassIds"
                v-model="targetData.creditClassIds"
                multiple
                :options="classes"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="startDate">
              <template v-slot:label>
                Ngày bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <b-form-datepicker
                  id="datepicker-dateformat1"
                  v-model="targetData.startDate"
                  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  locale="vi"
                  format="dd/mm/yyyy"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Ngày kết thúc -->
          <b-col cols="4">
            <b-form-group label-for="endDate">
              <template v-slot:label>
                Ngày kết thúc <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <b-form-datepicker
                  id="datepicker-dateformat2"
                  v-model="targetData.endDate"
                  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  locale="vi"
                  format="dd/mm/yyyy"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('examinationSchedulerSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import apiCommon from '@/views/apiCommon'
import apiMain from '@/views/cdsphoabinh/examination-scheduler/api'
import { STATUSES } from '@/const/status'

export const Attr = Object.freeze({
  store: 'examinationScheduler',
})

export default {
  name: 'ExaminationSchedulerSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        id: 0,
        name: '',
        trainingSystemId: 0,
        courseId: 0,
        creditClassIds: null,
        courseSemesterId: 0,
        semesterId: 0,
        status: 0,
        startDate: '',
        endDate: '',
      },
      required,
      classes: [],
      trainingSystems: [],
      courses: [],
      courseSemesters: [],
      totalRows: 0,
      statuses: STATUSES,
    }
  },
  computed: {
    events() {
      return events
    },
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: `${Attr.store}/createData`,
      updateData: `${Attr.store}/updateData`,
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.targetData.courseId = null
      const data = await apiCommon.getCourses({ trainingSystemId: event, currentPage: 1, itemsPerPage: 100 })
      this.courses = data.courses
    },
    async onCoursesChange(event) {
      this.targetData.courseSemesterId = null
      this.courseSemesters = await apiCommon.getCourseSemesters({ courseId: event, currentPage: 1, itemsPerPage: 100 })
    },
    async onCoursesSemesterChange(event) {
      const param = {
        courseSemesterId: event,
      }
      this.classes = await apiMain.getCreditClasses(param)
    },

    async onShow() {
      this.isLoading = true
      try {
        if (this.mainData) {
          this.targetData = this.mainData
        }
        if (this.isCreated) {
          this.trainingSystems = await apiCommon.getTrainingSystems({ currentPage: 1, itemsPerPage: 100 })
          if (this.trainingSystems.length > 0) {
            const response = await apiCommon.getCourses({ trainingSystemId: this.targetData.trainingSystemId, currentPage: 1, itemsPerPage: 100 })
            this.courses = response.courses
          }
          this.targetData.status = 0
        } else {
          this.trainingSystems = await apiCommon.getTrainingSystems({ currentPage: 1, itemsPerPage: 100 })
          const response = await apiCommon.getCourses({ trainingSystemId: this.targetData.trainingSystemId, currentPage: 1, itemsPerPage: 100 })
          this.courses = response.courses
          this.courseSemesters = await apiCommon.getCourseSemesters(
            {
              courseId: this.targetData.courseId,
              trainingSystemId: this.targetData.trainingSystemId,
              currentPage: 1,
              itemsPerPage: 1000,
            },
          )

          const param = {
            courseSemesterId: this.targetData.courseSemesterId,
            examinationSchedulerId: this.targetData.id,
          }
          this.classes = await apiMain.getCreditClasses(param)
        }
      } catch (e) {

      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .examinationSchedulerSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        name: '',
        trainingSystems: 0,
        creditClassIds: null,
        courseId: 0,
        courseSemesterId: 0,
        semesterId: 0,
        status: 0,
        startDate: '',
        endDate: '',
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .examinationSchedulerSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await apiMain.createData(this.targetData)
            : await apiMain.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('examinationSchedulerSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>
