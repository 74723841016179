import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.examinationScheduler,
})
export default {
  async getMainData(params) {
    try {
      const response = await axios.get('uni/examination_schedulers_hbh', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { total, records } = data.data
        return { total, records }
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  async createData(body) {
    try {
      const response = await axios.post('uni/examination_schedulers_hbh', body)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async updateData(params) {
    try {
      const response = await axios.put(`uni/examination_schedulers_hbh/${params.id}`, params)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async getClasses(params) {
    try {
      const response = await axios.get('uni/examination_schedulers_hbh/getClasses/', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { records } = data.data
        return records.map(item => ({
          value: item.id,
          label: item.name,
        }))
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async getCreditClasses(params) {
    try {
      const response = await axios.get('uni/examination_schedulers_hbh/getCreditClass/', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const records = data.data
        if (records.length > 0) {
          return records.map(item => ({
            value: item.id,
            label: `${item.subjectName}-${item.code}`,
          }))
        }
        return null
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async deleteData(id) {
    try {
      const response = await axios.delete(`uni/examination_schedulers_hbh/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
}
