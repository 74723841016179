<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="5">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="1.Hệ đào tạo:"
                      label-for="trainingSystemSelected"
                    >
                      <v-select
                        v-model="trainingSystemSelected"
                        :options="trainingSystems"
                        :reduce="(option) => option.value"
                        :disabled="!!$route.params.id"
                        :clearable="false"
                        placeholder="1.Chọn hệ đào tạo"
                        @input="onChangeTrainingSystem"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="2.Khóa - Học kỳ:"
                      label-for="coursesSemestersSelected"
                    >
                      <v-select
                        v-model="coursesSemestersSelected"
                        :options="coursesSemestersOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!$route.params.id"
                        :clearable="false"
                        placeholder="2.Chọn khóa - học kỳ"
                        @input="onChangeCoursesSemester"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="7">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="3.Học phần:"
                      label-for="subjectsSelected"
                    >
                      <v-select
                        v-model="subjectsSelected"
                        :options="subjectsOptions"
                        :reduce="(option) => option.value"
                        :disabled="!!$route.params.id"
                        :clearable="false"
                        placeholder="3.Chọn học phần:"
                        @input="onChangeSubjects"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label-cols="2"
                      label-cols-lg="2"
                      label="4.Lớp độc lập:"
                      label-for="creditClassSelected"
                    >
                      <v-select
                        v-model="creditClassSelected"
                        :options="creditClass"
                        :reduce="(option) => option.value"
                        :disabled="!!$route.params.id"
                        :clearable="false"
                        placeholder="4.Chọn phòng thi"
                        @input="onChangeCreditClasses"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <validation-observer
              ref="studentExaminationPointManualFormRef"
              #default="{invalid}"
            >
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-show="filter.creditClassId > 0"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mr-1"
                      @click="onSelectStudentExaminationRoom"
                    >
                      <span
                        class="text-nowrap text-right"
                      ><feather-icon icon="ListIcon"/> Danh sách sinh viên</span>
                    </b-button>
                    <b-button
                      v-show="saveScoreShowable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="invalid"
                      class="mr-1"
                      variant="primary"
                      @click="onSave(scoreByCreditClass)"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="CheckIcon" /> Lưu lại
                      </span>
                    </b-button>
                    <b-dropdown
                      v-show="saveScoreShowable"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      text="Nhập file"
                      right
                      variant="outline-primary"
                    >
                      <b-dropdown-item @click="downloadFile">
                        <span class="text-nowrap text-right">
                          <feather-icon icon="DownloadIcon" /> Tải về file mẫu
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="$refs.inputFileRef.$el.click()">
                        <span class="text-nowrap text-right">
                          <feather-icon icon="UploadIcon" /> Tải lên file
                        </span>
                      </b-dropdown-item>
                      <b-form-file
                        ref="inputFileRef"
                        accept=".xls, .xlsx"
                        :hidden="true"
                        plain
                        @change="importExcel"
                      />
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="scoreByCreditClass"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    class="my-table"
                    style-class="my-table vgt-table striped bordered"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'score'">
                        <validation-provider
                          #default="{ errors }"
                          rules="required|between:0,10"
                          name="Điểm"
                        >
                          <b-form-input
                            v-model="props.row.score"
                            :tabindex="props.row.originalIndex + 1"
                            @change="
                              changeCell(
                                props.row.score,
                                props.column.field,
                                props.row.originalIndex
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </span>
                      <span v-else-if="props.column.field === 'note'">
                        <b-form-input
                          v-model="props.row.note"
                          type="text"
                          @change="
                            changeCell(
                              props.row.note,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </span>
                      <!-- Column: Common -->
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormGroup,
  BOverlay,
  BRow,
  BFormInput,
  BDropdownItem,
  BFormFile,
  BDropdown,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@core/utils/validations/validations'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'StudentExaminationPointManual',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BFormFile,
    BDropdownItem,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
    vSelect,
    BFormInput,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemSelected: undefined,
      coursesSemestersSelected: undefined,
      creditClassSelected: undefined,
      subjectsSelected: undefined,
      isLoading: false,
      filter: {
        organizationId: getUser().orgId,
        creditClassId: this.$route.params.id || 0,
      },
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Mã sinh viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Điểm bằng số',
          field: 'score',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      required,
      between,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      scoreByCreditClass: 'studentExaminationRoom/scoreByCreditClass',
      examinationRoomSubjects: 'studentExaminationRoom/examinationRoomSubjects',
      coursesSemesters: 'studentExaminationRoom/coursesSemesters',
      subjects: 'studentExaminationRoom/subjects',
      creditClass: 'dropdown/creditClasses',
    }),
    coursesSemestersOptions() {
      return this.coursesSemesters.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    examinationRoomSubjectsOptions() {
      return this.examinationRoomSubjects.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    subjectsOptions() {
      return this.subjects.map(item => ({
        value: item.code,
        label: item.name,
      }))
    },
    saveScoreShowable() {
      return this.scoreByCreditClass.length > 0 && this.filter.creditClassId
    },
    creditClassCode() {
      const found = this.creditClass.find(element => element.value === this.creditClassSelected)
      if (found) return found.label
      return ''
    },
    examinationPointUpdatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.MANUAL_EXAMINATION_POINT)
    },
    selectedSubjectId() {
      return this.subjects.find(e => e.code === this.subjectsSelected).id || 0
    },
    isSeparateRoom() {
      return this.subjectsSelected.includes('_THCLASS_') ? 1 : 0
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getTrainingSystems({ organizationId: this.user.orgId })
      if (this.$route.params.id > 0) {
        await this.getScoreByCreditClass(this.filter)
      } else {
        this.reset()
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setScoreByCreditClass: 'studentExaminationRoom/SET_SCORE_BY_CREDIT_CLASS',
    }),
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getScoreByCreditClass: 'studentExaminationRoom/getScoreByCreditClass',
      getCoursesSemesters: 'studentExaminationRoom/getCoursesSemesters',
      getSubjects: 'studentExaminationRoom/getSubjects',
      getExaminationRoomSubjects:
        'studentExaminationRoom/getExaminationRoomSubjects',
      saveScoresByCode: 'studentExaminationRoom/saveScoresByCode',
      getCreditClasses: 'dropdown/getCreditClasses',
    }),

    async onChangeTrainingSystem() {
      this.isLoading = true
      try {
        this.coursesSemestersSelected = null
        this.creditClassSelected = null
        this.subjectsSelected = null
        if (this.trainingSystemSelected == null) {
          this.reset()
          this.$store.commit('studentExaminationRoom/SET_COURSESSEMESTERS', {
            data: [],
          })
          this.$store.commit(
              'studentExaminationRoom/SET_EXAMINATIONROOMSUBJECTS',
              { data: [] },
          )
          this.$store.commit('studentExaminationRoom/SET_SUBJECT', { data: [] })
        } else {
          await this.getCoursesSemesters({
            organizationId: getUser().orgId,
            training_system_id: this.trainingSystemSelected,
          })
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    async onChangeCoursesSemester() {
      this.isLoading = true
      try {
        this.creditClassSelected = null
        if (this.coursesSemestersSelected == null) {
          this.reset()
          this.$store.commit('studentExaminationRoom/SET_SUBJECT', { data: [] })
          this.$store.commit(
              'studentExaminationRoom/SET_EXAMINATIONROOMSUBJECTS',
              { data: [] },
          )
        } else {
          await this.getSubjects({
            organizationId: getUser().orgId,
            courses_semester_id: this.coursesSemestersSelected,
            hasExam: 1,
          })
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    async onChangeSubjects() {
      this.isLoading = true
      try {
        this.creditClassSelected = null
        if (this.subjectsSelected == null) {
          this.reset()
          this.$store.commit('dropdown/SET_CREDIT_CLASSES', { data: [] })
        } else {
          await this.getCreditClasses({
            organizationId: getUser().orgId,
            courseSemesterId: this.coursesSemestersSelected,
            subjectId: this.selectedSubjectId,
            isSeparate: this.isSeparateRoom,
          })
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },
    onChangeCreditClasses() {
      if (this.creditClassSelected == null) {
        this.reset()
      } else {
        this.filter.creditClassId = this.creditClassSelected
      }
    },
    reset() {
      this.filter.creditClassId = 0
      this.setScoreByCreditClass({ records: [] })
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    async onSelectStudentExaminationRoom() {
      this.isLoading = true
      try {
        await this.getScoreByCreditClass(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    changeCell(data, column, row) {
      this.scoreByCreditClass[row][column] = data
    },
    async onSave(data) {
      if (!this.examinationPointUpdatable) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Bạn không có quyền nhập điểm thi',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score === null || element.score === undefined)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => isNaN(element.score))) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đúng kiểu dữ liệu điểm!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => element.score < 0 || element.score > 10)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Điểm chỉ được nằm trong khoảng từ 0 đến 10!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      const scoresByCode = data.map(element => ({
        id: element.id,
        score: element.score !== null && element.score !== undefined ? element.score : null,
        note: element.note || null,
        examinationRoomId: element.examinationRoomId,
        studentCreditClassId: element.studentCreditClassId,
      }))
      try {
        const response = await this.saveScoresByCode({
          scoresByCode,
          courseSemesterId: this.coursesSemestersSelected,
          subjectId: this.selectedSubjectId,
          creditClassId: this.filter.creditClassId,
          type: 2,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.onSelectStudentExaminationRoom()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getCreditClassCode(value) {
      const found = this.creditClass.find(element => element.value === value)
      if (found) return found.label
      return ''
    },
    downloadFile() {
      let data = []
      if (this.scoreByCreditClass.length > 0) {
        data = this.scoreByCreditClass.map(element => ({
          id: element.id,
          examinationRoomId: element.examinationRoomId,
          studentCreditClassId: element.studentCreditClassId,
          studentCode: element.code,
          studentName: element.name,
          score: element.score,
          note: element.note,
        }))
      }
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'scores')
      XLSX.writeFile(wb, `NhapDiemThiLopDocLap_${this.creditClassCode}.xlsx`)
    },
    importExcel(e) {
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File dữ liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đầu vào phải có kiểu định dạng xlsx hoặc xls',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      const fileReader = new FileReader()
      fileReader.addEventListener(
        'load',
        async ev => {
          const { result } = ev.target
          const workbook = XLSX.read(result, { type: 'binary' })
          const wsName = workbook.SheetNames[0]
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[wsName])
          if (data.length > 0) {
            const dataMerge = this.scoreByCreditClass.map(item => {
              const found = data.find(bItem => bItem.studentCreditClassId === item.studentCreditClassId)
              return { ...item, ...{ score: found.score, note: found.note } }
            })
            this.setScoreByCreditClass({ records: dataMerge })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File không có dữ liệu',
                icon: 'InfoIcon',
                variant: 'warning',
              },
            })
          }
          this.resetInputFile()
        },
        false,
      )
      fileReader.readAsBinaryString(files[0])
      return true
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Call the API query method when the URL changes
    this.reset()
    next()
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
