import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  async importDefenseCertificates(body) {
    try {
      const response = await axios.post('uni/hluv/importDefenseCertificates', body)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async importPhysicalCertificates(body) {
    try {
      const response = await axios.post('uni/hluv/importPhysicalCertificates', body)
      const { data } = response
      return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
      handleCatch(e)
    }
    return null
  }
}
