<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-if="updatable"
                    v-b-modal.stepProcessSaveModal
                    variant="success"
                    title="Xem chi tiết"
                  >
                    <span class="text-nowrap text-right"><feather-icon icon="PlusIcon" />Thêm / Cập nhật bước</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="stepsProcess"
                  :total-rows="totalRows"
                  line-numbers
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'status'"
                      :class="props.row.status==1?'text-success':''"
                    >
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <StepProcessSave
      ref="StepProcessSaveFormRef"
      :step-process-data="stepsProcess"
      :process-id="processId"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BOverlay, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { handleCatch, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import axios from '@/libs/axios'
import { STATUSES } from '@/const/status'
import StepProcessSave from './StepProcessSave.vue'

export const Attr = Object.freeze({
  store: 'courseSemester',
})
export default {
  name: 'StepProcess',
  directives: {
    Ripple,
  },
  components: {
    StepProcessSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    VueGoodTable,
  },
  props: {
    dataProcess: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      isLoading: false,
      processId: this.$route.params.id || this.dataProcess.id,
      stepsProcess: [],
      totalRows: 0,
      statuses: STATUSES,
    }
  },
  computed: {
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: 'Tên bước',
          field: 'name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Mã bước',
          field: 'code',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên quy trình',
          field: 'processName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Phòng ban xử lý',
          field: 'departmentName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Nhân viên xử lý',
          field: 'employeeName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'description',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.OG_PROCESS)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.fetchStepProcess()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    async fetchStepProcess() {
      try {
        const response = await axios.get(`oneGate/stepProcess/getAllStepByProcessId/${this.processId}`)
        if (response) {
          this.stepsProcess = response.data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.fetchStepProcess()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.fetchStepProcess()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.fetchStepProcess()
    },
  },
}
</script>
