<template>
  <validation-observer
      ref="saveModalRef"
      #default="{invalid}"
  >
    <b-modal
        id="reportPaymentModal"
        body-class="position-static"
        size="lg"
        centered
        :title="'Xem chi tiết khoản thu'"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
          >
            <b-form-group label-for="merchantServiceId">
              <template v-slot:label>
                Mã dịch vụ của merchant
              </template>
              <b-form-input
                  id="merchantServiceId"
                  v-model="targetItem.merchantServiceId"
                  name="merchantServiceId"
                  placeholder="Mã dịch vụ của merchant"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="merchantOrderId">
              <template v-slot:label>
                Mã đơn hàng
              </template>
              <b-form-input
                  id="merchantOrderId"
                  v-model="targetItem.merchantOrderId"
                  name="merchantOrderId"
                  placeholder="Mã đơn hàng"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="amount">
              <template v-slot:label>
                Số tiền thanh toán
              </template>
              <b-form-input
                  id="amount"
                  v-model="targetItem.amount"
                  name="amount"
                  placeholder="Số tiền thanh toán"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="paymentAction">
              <template v-slot:label>
                Hình thức thanh toán
              </template>
              <b-form-input
                  id="paymentAction"
                  v-model="targetItem.paymentAction"
                  name="paymentAction"
                  placeholder="Hình thức thanh toán"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="serviceCategory">
              <template v-slot:label>
                Mã loại hình dịch vụ
              </template>
              <b-form-input
                  id="serviceCategory"
                  v-model="targetItem.serviceCategory"
                  name="serviceCategory"
                  placeholder="Mã loại hình dịch vụ"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="channelId">
              <template v-slot:label>
                Mã kênh thanh toán
              </template>
              <b-form-input
                  id="channelId"
                  v-model="targetItem.channelId"
                  name="channelId"
                  placeholder="Mã kênh thanh toán"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="device">
              <template v-slot:label>
                Mã thiết bị
              </template>
              <b-form-input
                  id="device"
                  v-model="targetItem.device"
                  name="device"
                  placeholder="Mã thiết bị"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="paymentMethod">
              <template v-slot:label>
                Phương thức thanh toán
              </template>
              <b-form-input
                  id="paymentMethod"
                  v-model="targetItem.paymentMethod"
                  name="paymentMethod"
                  placeholder="Phương thức thanh toán"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="description">
              <template v-slot:label>
                Mô tả đơn hàng
              </template>
              <b-form-input
                  id="description"
                  v-model="targetItem.description"
                  name="description"
                  placeholder="Mô tả đơn hàng"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="createdAt">
              <template v-slot:label>
                Thời gian giao dịch
              </template>
              <b-form-input
                  id="createdAt"
                  v-model="targetItem.createdAt"
                  name="createdAt"
                  placeholder="Mô tả đơn hàng"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="clientIp">
              <template v-slot:label>
                IP của khách hàng
              </template>
              <b-form-input
                  id="clientIp"
                  v-model="targetItem.clientIp"
                  name="clientIp"
                  placeholder="IP của khách hàng"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="responseCode">
              <template v-slot:label>
                Trạng thái thanh toán
              </template>
              <b-form-input
                  id="responseCode"
                  v-model="targetItem.responseCode"
                  name="responseCode"
                  placeholder="Trạng thái thanh toán"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
          >
            <b-form-group label-for="responseDescription">
              <template v-slot:label>
                Mô tả trạng thái
              </template>
              <b-form-input
                  id="responseDescription"
                  v-model="targetItem.responseDescription"
                  name="responseDescription"
                  placeholder="Mô tả trạng thái"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái giao dịch<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái giao dịch"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="$bvModal.hide('reportPaymentModal')"
          >
            <span class="text-right">
               OK
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
          no-wrap
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import {RECEIPT_STATUSES} from "@/const/status";

export default {
  name: 'ReportPaymentModal',
  components: {
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        merchantServiceId: null,
        merchantOrderId: null,
        amount: null,
        paymentAction: null,
        serviceCategory: null,
        channelId: null,
        device: null,
        paymentMethod: null,
        description: null,
        createdAt: null,
        clientIp: null,
        responseCode: null,
        responseDescription: null,
        status: 1,
      },
      required,
    }
  },
  computed: {
    isCreated() {
      return !this.item
    },
    statusOptions(){
      return RECEIPT_STATUSES
    },
  },
  methods: {
    ...mapActions({
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.item) {
        this.targetItem = this.item
      }
    },
    onHide() {
      this.$refs
          .saveModalRef
          .reset()
      this.targetItem = {
        merchantServiceId: null,
        merchantOrderId: null,
        amount: null,
        paymentAction: null,
        serviceCategory: null,
        channelId: null,
        device: null,
        paymentMethod: null,
        description: null,
        createdAt: null,
        clientIp: null,
        responseCode: null,
        responseDescription: null,
        status: 1,
      }
    },
    async onSave(type = null) {

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
