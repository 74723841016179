<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
          :show="isLoading"
          rounded="sm"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="6">
            </b-col>
            <b-col cols="6">
              <div class="text-right">
                <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.typeProcedureSaveModal
                    variant="success"
                    class="ml-md-1 mt-1 mt-md-0"
                    @click="onCreateProcedure"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon"/> Thêm mới
                  </span>
                </b-button>
              </div>
            </b-col>

            <!-- Per Page -->
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ params.itemsPerPage * (params.page - 1) + 1 }}
                  đến {{
                    (params.itemsPerPage * (params.page) >= totalRows) ? totalRows : params.itemsPerPage * (params.page)
                  }} &nbsp; của {{ totalRows }} bản ghi
                </span>
              </div>
              <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered research-table"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="procedures"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  line-numbers
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
              >
                <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                  <span v-else-if="props.column.field === 'type'">
                    {{ getTypeName(props.row.type) }}
                  </span>

                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                        v-if="updatable"
                        v-b-modal.typeProcedureSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEditTypeProcedure(props.row)"
                    >
                      <feather-icon icon="Edit2Icon"/>
                    </b-button>
                    <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDeleteTypeProcedure(props.row)"
                    >
                      <feather-icon icon="TrashIcon"/>
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb- mt-1">
                      <span class="text-nowrap"> Hiển thị {{ params.itemsPerPage * (params.page - 1) + 1 }}
                        đến {{ params.itemsPerPage * (params.page) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị </span>
                      <b-form-select
                          v-model="params.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="params.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <TypeProcedureSave
        ref="saveModalRef"
        :item="currentTypeProcedure"
        @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import {handleCatch, hasPermissionForResource, isSystemAdmin} from '@/utils'
import {PermissionCode, ResourceCode} from '@/const/code'
import axios from '@/libs/axios'
import {STATUSES, OG_PROCEDURE_TYPE} from '@/const/status'
import {ApiCode} from '@/const/api'
import TypeProcedureSave from './TypeProcedureSave.vue'

export default {
  name: 'Procedure',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    TypeProcedureSave,
  },
  data() {
    return {
      departmentSelected: undefined,
      isLoading: false,
      params: {
        page: 1,
        itemsPerPage: 10,
        name: '',
        code: '',
        status: 1,
        type: null,
        sort: '',
      },
      totalRows: 0,
      procedures: [],
      statuses: STATUSES,
      procedureType: OG_PROCEDURE_TYPE,
      currentTypeProcedure: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã loại thủ tục',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên loại thủ tục',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'description',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.OG_TYPE_PROCEDURE)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.OG_TYPE_PROCEDURE)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.OG_TYPE_PROCEDURE)
    },
  },
  async created() {
    try {
      await this.fetchData()
      const statusesCV = this.statuses.map(item => ({value: item.value, text: item.label}))
      this.$set(this.columns[4].filterOptions, 'filterDropdownItems', statusesCV)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    }
  },
  methods: {
    isSystemAdmin,
    async fetchData() {
      this.isLoading = true
      try {
        const response = await axios.get('oneGate/typeProcedure', {params: this.params})
        this.procedures = response.data.data.records
        this.totalRows = response.data.data.total
      } catch (e) {
        handleCatch(e)
      } finally {
        this.isLoading = false
      }
    },
    onCreateProcedure() {
      this.currentTypeProcedure = undefined
    },
    onEditTypeProcedure(procedure) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = procedure
      this.currentTypeProcedure = rest
    },
    async deleteTypeProcedure(id) {
      try {
        const response = await axios.delete(`oneGate/typeProcedure/${id}`)
        const {data} = response
        return {isSuccessful: data.code === ApiCode.SUCCESS, message: data.message}
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    onDeleteTypeProcedure(procedure) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa loại thủ tục <span class="text-danger">${procedure.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTypeProcedure(procedure.id)
            if (response) {
              const {isSuccessful, message} = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.fetchData()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.params = {...this.params, ...newProps}
    },
    async onPageChange(param) {
      this.updateParams({page: param.currentPage})
      await this.fetchData()
    },
    async onPerPageChange(param) {
      this.updateParams({itemsPerPage: param.currentPerPage})
      await this.fetchData()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const {field, type} = param[0]
        if (field === 'name') this.updateParams({sort: `1_${type}`})
        else if (field === 'code') this.updateParams({sort: `2_${type}`})
      }
      await this.fetchData()
    },
    async onColumnFilter(param) {
      const {columnFilters} = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({name: columnFilters.name})
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({code: columnFilters.code})
      if (Object.hasOwn(columnFilters, 'type')) this.updateParams({type: columnFilters.type})
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({status: columnFilters.status})
      await this.fetchData()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getTypeName(id) {
      return this.procedureType.find(type => type.value === id).label
    },
    async onSucceed() {
      await this.fetchData()
    },
  },
}
</script>

<style scoped>

</style>
