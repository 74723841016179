import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/staffProcess',
    name: 'staffProcess',
    component: () => import('@/views/one-gate/staff-process/StaffProcess.vue'),
    meta: {
      pageTitle: 'Danh sách cán bộ',
      breadcrumb: [
        {
          text: 'Quản lý nhân sự',
          active: false,
        },
        {
          text: 'Danh sách cán bộ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_STAFF_PROCESS,
    },
  },
  {
    path: '/typeProcedure',
    name: 'typeProcedure',
    component: () => import('@/views/one-gate/type-procedure/TypeProcedure.vue'),
    meta: {
      pageTitle: 'Danh sách loại thủ tục',
      breadcrumb: [
        {
          text: 'Quản lý loại thủ tục',
          active: false,
        },
        {
          text: 'Danh sách loại thủ tục',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_TYPE_PROCEDURE,
    },
  },
  {
    path: '/procedure',
    name: 'procedure',
    component: () => import('@/views/one-gate/procedure/Procedure.vue'),
    meta: {
      pageTitle: 'Danh sách thủ tục',
      breadcrumb: [
        {
          text: 'Quản lý thủ tục',
          active: false,
        },
        {
          text: 'Danh sách thủ tục',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_PROCEDURE,
    },
  },
  {
    path: '/template-file',
    name: 'template-file',
    component: () => import('@/views/one-gate/template-files/index.vue'),
    meta: {
      pageTitle: 'Quản lý biểu mẫu đính kèm',
      breadcrumb: [
        {
          text: 'Dịch vụ 1 cửa',
          active: false,
        },
        {
          text: 'Quản lý biểu mẫu đính kèm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_TEMPLATE_RESULT,
    },
  },
  {
    path: '/process',
    name: 'process',
    component: () => import('@/views/one-gate/process/Process.vue'),
    meta: {
      pageTitle: 'Danh sách quy trình',
      breadcrumb: [
        {
          text: 'Dịch vụ một cửa',
          active: false,
        },
        {
          text: 'Danh sách quy trình',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_PROCESS,
    },
  },
  {
    path: '/dossier',
    name: 'dossier',
    component: () => import('@/views/one-gate/dossier/Dossier.vue'),
    meta: {
      pageTitle: 'Danh sách hồ sơ',
      breadcrumb: [
        {
          text: 'Quản lý hồ sơ',
          active: false,
        },
        {
          text: 'Danh sách hồ sơ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.OG_DOSSIER,
    },
  },
]
