<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row v-if="creatable">
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.scholarshipFundSaveModal
                    variant="success"
                    @click="currentScholarshipFund = undefined"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusIcon" /> Thêm quỹ học bổng
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row :class="{'mt-2': creatable}">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    
                    <span v-else-if="props.column.field === 'action'">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="updatable"
                          v-b-modal.scholarshipFundSaveModal
                          @click="onEditScholarshipFund(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Sửa</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="scholarshipFundCriteriaReadable"
                          v-b-modal.scholarshipFundCriteriaModal
                          @click="currentScholarshipFundId = props.row.id"
                        >
                          <feather-icon icon="ListIcon" />
                          <span class="align-middle ml-50">Danh sách tiêu chí</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="deletable"
                          @click="onDeleteScholarshipFund(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Xóa</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                    
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <Save
      :scholarshipFund="currentScholarshipFund"
      @succeed="onSucceed"
    />
    <ScholarshipFundCriteria :scholarship-fund-id="currentScholarshipFundId" />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BDropdown, BDropdownItem, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import Save from '@/views/admin/category/scholarship-fund/Save.vue'
import ScholarshipFundCriteria from '@/views/admin/category/scholarship-fund/scholarship-fund-criteria/ScholarshipFundCriteria.vue'
import { STATUSES } from '@/const/status'
import { getUser } from '@/auth/utils'

export default {
  name: 'ScholarshipFund',
  directives: {
    Ripple,
  },
  components: {
    Save,
    ScholarshipFundCriteria,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        organizationId: null,
        currentPage: 1,
        itemsPerPage: 10,
        name: '',
        status: '',
        sort: '',
      },
      currentScholarshipFund: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên quỹ học bổng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên quỹ học bổng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      currentScholarshipFundId: null,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'scholarshipFund/dataSources',
      totalRows: 'scholarshipFund/totalRows',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SCHOLARSHIP_FUND)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.SCHOLARSHIP_FUND)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SCHOLARSHIP_FUND)
    },
    scholarshipFundCriteriaReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.SCHOLARSHIP_FUND_CRITERIA)
    },
  },
  async created() {
    this.updateParams({ organizationId: this.user.orgId })
    this.isLoading = true
    try {
      await this.getDataSourcesFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'scholarshipFund/getDataSources',
      deleteScholarshipFund: 'scholarshipFund/deleteScholarshipFund',
    }),
    onEditScholarshipFund(scholarshipFund) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = scholarshipFund
      this.currentScholarshipFund = rest
    },
    onDeleteScholarshipFund(scholarshipFund) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa quỹ học bổng <span class="text-danger">${scholarshipFund.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
          buttonsStyling: false,
        })
        .then(async result => {
          if ( result.value ) {
            this.isLoading = true
            try {
              const response = await this.deleteScholarshipFund(scholarshipFund.id)
              if ( response ) {
                const {
                  isSuccessful,
                  message
                } = response
                if ( isSuccessful ) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  try {
                    await this.getDataSources(this.filter)
                  } catch ( e ) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `[${e.code}] ${e.message}`,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch ( e ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch ( e ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({
        currentPage: 1,
        itemsPerPage: param.currentPerPage
      })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(param) {
      if ( param.length > 0 ) {
        const {
          field,
          type
        } = param[0]
        if ( field === 'name' ) this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if ( Object.hasOwn(columnFilters, 'name') ) this.updateParams({ name: columnFilters.name })
      if ( Object.hasOwn(columnFilters, 'status') ) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },
}
</script>
