<template>
  <validation-observer
    ref="StepProcessSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="stepProcessSaveModal"
      body-class="position-static"
      centered
      size="xl"
      title="Cập nhật các bước của quy trình"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row
          v-for="(step, index) in targetData"
          :key="index"
        >
          <b-col cols="3">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã bước <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Mã bước"
                  rules="required"
              >
                <b-form-input
                    id="code"
                    v-model="step.code"
                    name="code"
                    placeholder="Nhập mã bước"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên bước <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên bước"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="step.name"
                  name="name"
                  placeholder="Nhập tên bước"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="departmentId">
              <template v-slot:label>
                Phòng ban <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Phòng ban"
                rules="required"
              >
                <v-select
                  v-model="step.departmentId"
                  :options="departments"
                  :reduce="option => option.id"
                  label="name"
                  @input="onChangeDepartments(index)"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="employeeId">
              <template v-slot:label>
                Nhân viên xử lý <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Nhân viên xử lý"
                rules="required"
              >
                <v-select
                  v-model="step.employeeId"
                  :options="step.employeeOptions"
                  :reduce="option => option.employeeId"
                  label="name"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="orderNo">
              <template v-slot:label>
                Thứ tự <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thứ tự"
                rules="required"
              >
                <b-form-input
                  id="orderNo"
                  v-model="step.orderNo"
                  name="orderNo"
                  placeholder="Nhập thứ tự"
                  :state="getElementState(errors)"
                  :disabled="index === 0"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="step.status"
                  :options="statuses"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="description">
              <template v-slot:label>
                Ghi chú
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ghi chú"
              >
                <b-form-input
                  id="description"
                  v-model="step.description"
                  name="description"
                  placeholder="Nhập ghi chú bước"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            v-if="index !== 0"
            class="text-right"
          >
            <b-button
              variant="danger"
              @click="deleteStep(index)"
            >
              <feather-icon icon="TrashIcon" /> Xóa bước
            </b-button>
          </b-col>
          <div style="width: 100%;">
            <hr>
          </div>
        </b-row>
        <b-button
          variant="success"
          @click="addStep"
        >
          <feather-icon icon="PlusIcon" /> Thêm bước
        </b-button>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('stepProcessSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BModal, BOverlay, BButton, BRow, BCol, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import vSelect from 'vue-select'
import { handleCatch } from '@/utils'
import axios from '@/libs/axios'
import { STATUSES } from '@/const/status'
import { ApiCode } from '@/const/api'

const IS_STATUS_DEFAULT = STATUSES[0].value
const IS_RECIPIENT = 1

export default {
  name: 'CourseSemesterSave',
  components: {
    BFormInvalidFeedback,
    BFormInput,
    BCol,
    BRow,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    stepProcessData: {
      type: Array,
      default: () => [],
    },
    processId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: [
        {
          departmentId: null,
          employeeId: null,
          name: '',
          code: '',
          orderNo: 1,
          status: IS_STATUS_DEFAULT,
          employeeOptions: [],
        },
      ],
      statuses: STATUSES,
      required,
      departments: [],
      employees: [],
    }
  },
  computed: {
    events() {
      return events
    },
    isCreated() {
      return !this.mainData
    },
  },
  async created() {
    await this.fetchDepartments()
  },
  methods: {
    async fetchDepartments() {
      try {
        const response = await axios.get('uni/departments/getByOrganizationId')
        this.departments = response.data.data
      } catch (e) {
        handleCatch(e)
      }
    },
    async createStepProcess() {
      try {
        const url = `oneGate/process/${this.processId}/steps`
        const body = {
          stepProcess: this.targetData,
        }
        const response = await axios.post(url, body)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async onChangeDepartments(index) {
      const { departmentId } = this.targetData[index]
      let employeeOptions = []
      if (departmentId) {
        if (index === 0) {
          employeeOptions = await this.fetchEmployees(departmentId, IS_RECIPIENT)
        } else {
          employeeOptions = await this.fetchEmployees(departmentId)
        }
      }
      this.$set(this.targetData[index], 'employeeOptions', employeeOptions)
      this.$set(this.targetData[index], 'employeeId', employeeOptions.length > 0 ? employeeOptions[0].employeeId : null)
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      this.$refs.StepProcessSaveFormRef.reset()
      if (!this.stepProcessData) {
        this.targetData = []
        this.isLoading = false
        return
      }
      this.targetData = await Promise.all(
        this.stepProcessData.map(async (item, index) => {
          let employeeOptions = []
          let orderNo = index + 1
          let isRecipient = null
          if (index === 0) {
            isRecipient = IS_RECIPIENT
          }
          if (item.departmentId) {
            employeeOptions = await this.fetchEmployees(item.departmentId, isRecipient)
          }
          if (item.orderNo) {
            orderNo = item.orderNo
          }
          return {
            ...item,
            orderNo,
            employeeOptions,
          }
        }),
      )
      this.isLoading = false
    },
    onHide() {
      this.$refs.StepProcessSaveFormRef.reset()
      this.targetData = [
        {
          departmentId: null,
          employeeId: null,
          name: '',
          code: '',
          orderNo: 1,
          status: IS_STATUS_DEFAULT,
        },
      ]
    },
    async onSave(type = null) {
      const valid = this.$refs.StepProcessSaveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.createStepProcess()
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('stepProcessSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async fetchEmployees(departmentId, isRecipient) {
      try {
        const response = await axios.get('oneGate/staff-process/getDepartmentEmployees', {
          params: { departmentId, isRecipient },
        })
        return response.data.data
      } catch (e) {
        handleCatch(e)
        return []
      }
    },
    addStep() {
      const newStep = JSON.parse(JSON.stringify({
        departmentId: null,
        employeeId: null,
        name: '',
        code: '',
        orderNo: this.targetData.length + 1,
        status: IS_STATUS_DEFAULT,
      }))
      this.targetData.push(newStep)
    },
    deleteStep(index) {
      if (index === 0) {
        return
      }
      this.targetData.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
