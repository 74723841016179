import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSources: [],
    totalRows: 0,
  },
  getters: {
    dataSources: state => state.dataSources,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSources = data },
    SET_TOTAL_ROWS: (state, data) => { state.totalRows = data },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('uni/factResearchArticleRoles', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_DATA_SOURCES', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createDataSource({ commit }, params) {
      try {
        const response = await axios.post('uni/factResearchArticleRoles', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateDataSource({ commit }, params) {
      try {
        const response = await axios.put(`uni/factResearchArticleRoles/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteDataSource({ commit }, id) {
      try {
        const response = await axios.delete(`uni/factResearchArticleRoles/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAll({ commit }, params) {
      try {
        const response = await axios.get('factResearchArticleRoles/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_SOURCES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
