import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/import_score_dhhl',
    name: 'importScoreDHHL',
    component: () => import('@/views/ninhbinh/hluv-import-score/ImportScore.vue'),
    meta: {
      pageTitle: 'Import điểm theo khóa DHHL',
      breadcrumb: [
        {
          text: 'Hệ thống',
          active: false,
        },
        {
          text: 'Import điểm theo khóa DHHL',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.HLUV_IMPORT_SCORE,
    },
  },
  {
    path: '/import-certificate',
    name: 'importCertificate',
    component: () => import('@/views/ninhbinh/importCertificate/ImportCertificate.vue'),
    meta: {
      title: 'Import chứng chỉ của sinh viên',
      pageTitle: 'Import chứng chỉ của sinh viên',
      breadcrumb: [
        {
          text: 'Quản lý sinh viên',
          active: false,
        },
        {
          text: 'Import chứng chỉ của sinh viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.NBH_IMPORT_CERTIFICATE,
    },
  },
]
