<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-form-group>
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="filter.trainingSystemId"
                      :options="trainingSystems"
                      :reduce="option => option.value"
                      placeholder="Hệ đào tạo"
                      @input="onTrainingSystemsChange"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="filter.courseId"
                      title="Khóa học"
                      :options="courses"
                      :reduce="option => option.value"
                      placeholder="Khóa học"
                      @input="onCoursesChange"
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="filter.courseSemesterId"
                      :options="courseSemesters"
                      :reduce="option => option.value"
                      placeholder="Học kỳ"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Tìm theo Chương trình đào tạo"
                    class="border border-primary rounded p-50"
                    label-class="pb-25"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="filter.majorId"
                          :options="majors"
                          :reduce="option => option.value"
                          placeholder="Ngành học"
                          @input="onMajorChange"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="filter.programmeId"
                          :options="programmes"
                          :reduce="option => option.value"
                          placeholder="Chương trình đào tạo"
                          @input="onProgrammeChange"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <v-select
                          v-model="filter.classId"
                          :options="classes"
                          :reduce="option => option.value"
                          placeholder="Lớp ưu tiên"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <b-form-group
                    label="Tìm theo Khoa/Bộ môn"
                    class="border border-primary rounded p-50"
                    label-class="pb-25"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <v-select
                          v-model="filter.departmentId"
                          :options="departments"
                          :reduce="option => option.value"
                          placeholder="Khoa/Bộ môn"
                          @input="onDepartmentChange"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="7"
                      >
                        <v-select
                          v-model="filter.subjectId"
                          :options="subjects"
                          :reduce="option => option.value"
                          placeholder="Học phần"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tìm theo Giảng viên"
                    class="border border-primary rounded p-50"
                    label-class="pb-25"
                  >
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <v-select
                        v-model="filter.teacherId"
                        :options="teachers"
                        :reduce="option => option.value"
                        :clearable="allCreditClassesReadable"
                        placeholder="Giảng viên"
                      />
                    </b-col>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="shouldShowApproveButton"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1 mt-1 mt-md-0"
                    variant="warning"
                    @click="onApproveCreditClass"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusSquareIcon" /> Duyệt đăng ký lớp
                    </span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1 mt-1 mt-md-0"
                    variant="primary"
                    @click="onSearchMainData"
                  >
                    <feather-icon icon="SearchIcon" /> Tìm kiếm
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.creditClassSaveModal
                    class="ml-1 mt-1 mt-md-0"
                    variant="success"
                    @click="onCreateMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="PlusSquareIcon" /> Thêm mới
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.creditClassSaveModal
                    class="ml-1 mt-1 mt-md-0"
                    variant="success"
                    @click="onCopyMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="CopyIcon" /> Thêm từ lớp cố định
                    </span>
                  </b-button>
                  <b-button
                    v-if="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="ml-1 mt-1 mt-md-0"
                    variant="success"
                    @click="onBulkInsertMainData"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="SettingsIcon" /> Thêm theo CTĐT
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-1">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  style-class="vgt-table striped bordered research-table"
                  row-style-class="vgt-row"
                  :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'lớp được chọn', clearSelectionText: 'Bỏ chọn' }"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                  @on-selected-rows-change="selectionChanged"
                >
                  <div
                    slot="emptystate"
                    class="text-center font-weight-bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'capacity'">
                      {{ `${props.row.currentQty?props.row.currentQty:0}/${props.row.maxQty}` }}
                    </span>
                    <span v-else-if="props.column.field === 'code'">
                      <template v-if="props.row.autoCreated > 0">
                        <span
                          v-b-tooltip.hover.top="autoDescription"
                          class="text-info"
                        >
                          <feather-icon icon="SettingsIcon" />
                        </span>
                      </template>
                      {{ props.row.code }}
                    </span>
                    <span v-else-if="props.column.field === 'subjectName'">
                      {{ getClassName(props.row) }}
                    </span>
                    <span v-else-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                          v-if="creditClassUpdatable"
                          v-b-modal.creditClassSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown
                          right
                          variant="success"
                          no-caret
                          size="sm"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreHorizontalIcon"
                              size="16"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item
                            v-if="creditClassRoomReadable"
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            @click="onAssignRoom(props.row)"
                          >
                            <feather-icon icon="GridIcon" />
                            Chọn phòng học
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creditClassTeacherReadable"
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            @click="onAssignTeacher(props.row)"
                          >
                            <feather-icon icon="UserPlusIcon" />
                            Phân công giảng viên
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="props.row.syncLms === 1"
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            @click="progressLms(props.row)"
                          >
                            <feather-icon icon="NavigationIcon" />
                            Tiến trình học tập LMS
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creditClassStudentReadable"
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            @click="onAddStudent(props.row)"
                          >
                            <feather-icon icon="UsersIcon" />
                            Danh sách sinh viên
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="props.row.syncLms === 1 && props.row.lmsCourseId"
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            @click="syncScoreLms(props.row)"
                          >
                            <feather-icon icon="RepeatIcon" />
                            Đồng bộ điểm LMS
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="studentCreditClassCreatable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="importStudent(props.row)"
                          >
                            <feather-icon icon="FileTextIcon" />
                            Import sinh viên
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creditClassDocumentReadable"
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowCreditClassDocumentModal(props.row.id)"
                          >
                            <feather-icon icon="BookIcon" /> Giáo trình
                          </b-dropdown-item>

                          <b-dropdown-item
                            v-if="processScoreReadable"
                            v-b-modal.processScoreModal
                            variant="success"
                            class="btn-icon"
                            size="sm"
                            @click="onShowProcessScores(props.row)"
                          >
                            <feather-icon icon="SlidersIcon" /> {{ processScoreActionTitle(props.row) }}
                          </b-dropdown-item>

                          <b-dropdown-item
                            variant="warning"
                            class="btn-icon"
                            size="sm"
                            @click="openNotifications(props.row)"
                          >
                            <feather-icon icon="Volume2Icon" /> Gửi thông báo
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="creditClassDeletable"
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            title="Rút bớt lớp độc lập"
                            @click="onDeleteMainData(props.row)"
                          >
                            <feather-icon icon="ScissorsIcon" />
                            Rút bớt lớp
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

    <CreditClassSave
      ref="creditClassSaveFormRef"
      :main-data="currentMainData"
      :is-copy="isCopy"
      @succeed="onSucceed"
    />
    <b-modal
      id="modal-assign-teacher"
      ref="modal-assign-teacher"
      ok-title="Chấp nhận"
      cancel-title="Đóng"
      centered
      size="xl"
      :title="`Phân công giảng viên dạy lớp độc lập - [${currentCode}] ${currentName}`"
      :no-close-on-backdrop="true"
      @ok="onSucceed"
    >
      <CreditClassTeacher :data-send="currentMainData" @succeedCreditClass="reloadPage"/>
    </b-modal>
    <b-modal
      id="modal-assign-student"
      ref="modal-assign-student"
      ok-title="Chấp nhận"
      cancel-title="Đóng"
      centered
      size="xl"
      :title="`Danh sách sinh viên lớp độc lập - [${currentCode}] ${currentName}`"
      :no-close-on-backdrop="true"
      @ok="onSucceed"
    >
      <CreditClassStudent
        :data-send="currentMainData"
        @onSuccessModal="onSuccessModal"
      />
    </b-modal>
    <b-modal
      id="modal-assign-room"
      ref="modal-assign-room"
      ok-title="Chấp nhận"
      cancel-title="Đóng"
      centered
      size="lg"
      :title="`Chọn phòng học cho độc lập - [${currentCode}] ${currentName}`"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <CreditClassRoom :data-send="currentMainData" />
    </b-modal>
    <b-modal
      id="modal-import-student"
      ref="modal-import-student"
      hide-footer
      centered
      size="xl"
      :title="`Import sinh viên cho lớp độc lập - [${currentCode}] ${currentName}`"
      :no-close-on-backdrop="true"
    >
      <ImportStudentCreditClass
        :data-send="currentMainData"
        @onSuccessModal="onSuccessModal"
      />
    </b-modal>

    <ProcessScore
      :credit-class="currentMainData"
      :updatable="updatable"
      :subject-type-code="subjectTypeCode"
      @succeed="onSucceed"
    />

    <CreditClassBulkSave
      :course-id="filter.courseId"
      :major-id="filter.majorId"
      :course-semester-id="filter.courseSemesterId"
      :programme-id="filter.programmeId"
      @succeed="onSucceed"
    />

    <CreditClassDocument :credit-class-id="creditClassId" />

    <b-modal
      id="modal-class-notification"
      ref="modal-class-notification"
      centered
      size="xl"
      title="Quản lý thông báo lớp học"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <Notification :data-send="dataSendClass" />
    </b-modal>

    <b-modal
      id="modal-class-progress-lms"
      ref="modal-class-progress-lms"
      centered
      size="xl"
      title="Tiến trình học tập LMS"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <ProgressLms :data-send-progress-lms="dataSendProgressLms" />
    </b-modal>

    <b-modal
      id="modal-sync-lms"
      ref="modal-sync-lms"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="xl"
      title="Đồng bộ điểm LMS"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <SyncScoreLms :data-sync-score-lms="dataSyncScoreLms" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow, BFormGroup, BForm, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import CreditClassSave from '@/views/credit-class/CreditClassSave.vue'
import CreditClassTeacher from '@/views/credit-class-teacher/CreditClassTeacher.vue'
import CreditClassStudent from '@/views/credit-class-student/CreditClassStudent.vue'
import CreditClassRoom from '@/views/credit-class-room/CreditClassRoom.vue'
import SyncScoreLms from '@/views/credit-class/SyncScoreLms.vue'
// eslint-disable-next-line import/extensions
import ImportStudentCreditClass from '@/views/admin/studentManagement/importStudentCreditClass/ImportStudentCreditClass'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import CreditClassBulkSave from '@/views/credit-class/CreditClassBulkSave.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode, RoleCode } from '@/const/code'
import ProcessScore from '@/views/credit-class/InputScore/ProcessScore.vue'
import CreditClassDocument from '@/views/credit-class/CreditClassDocument/CreditClassDocument.vue'
import Notification from '@/views/notification/Notification.vue'
import ProgressLms from '@/views/credit-class/ProgressLms.vue'

export default {
  name: 'CreditClass',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    Notification,
    CreditClassDocument,
    ProcessScore,
    CreditClassStudent,
    CreditClassTeacher,
    CreditClassSave,
    CreditClassRoom,
    ImportStudentCreditClass,
    CreditClassBulkSave,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
    ProgressLms,
    SyncScoreLms,
  },
  data() {
    return {
      cUser: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        departmentId: null,
        teacherId: null,
        majorId: null,
        programmeId: null,
        subjectId: null,
        classId: null,
        status: null,
        sort: null,
        name: null,
        code: null,
      },
      currentMainData: undefined,
      subjectTypeCode: undefined,
      currentName: '',
      currentCode: '',
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      isCopy: false,
      creditClassId: null,
      creditClassCode: '',
      practiceScoreEditable: false,
      planCreditClasses: [],
      autoDescription: 'Được tạo tự động từ CTĐT',
      updatable: true,
      selectedMainData: [],
      dataSendClass: {},
      isSysAdmin: false,
      dataSendProgressLms: {},
      dataSyncScoreLms: {},
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'creditClass/dataLists',
      totalRows: 'creditClass/totalRows',
      statuses: 'creditClass/statuses',
      resourceName: 'creditClass/resourceName',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
      programmeSubjects: 'dropdown/programmeSubjects',
      subjects: 'dropdown/subjects',
      teachers: 'dropdown/teachers',
      classes: 'dropdown/classes',
      systemParameter: 'creditClass/systemParameter',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    shouldShowApproveButton() {
      // Cac lop duoc chon phai la lop dang mo dang ky hoac dang ky mo rong
      if (!this.selectedMainData) return false
      return (this.selectedMainData.length > 0 && this.selectedMainData.length === this.selectedMainData.filter(item => (item.status > 0 && item.status < 3)).length)
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên học phần',
          field: 'subjectName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lượng ĐK',
          field: 'capacity',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Giảng viên',
          field: 'teacherNames',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          width: '100px',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'courseName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Lớp ưu tiên',
          field: 'className',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          maxWidth: '130px',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '60px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.CREDIT_CLASS)
    },
    creditClassUpdatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.CREDIT_CLASS)
    },
    creditClassDeletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.CREDIT_CLASS)
    },
    creditClassDocumentReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CREDIT_CLASS_DOCUMENT)
    },
    creditClassRoomReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CREDIT_CLASS_ROOM)
    },
    creditClassTeacherReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CREDIT_CLASS_TEACHER)
    },
    creditClassStudentReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.CREDIT_CLASS_STUDENT)
    },
    studentCreditClassCreatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.STUDENT_CREDIT_CLASS)
    },
    allCreditClassesReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.ALL_CREDIT_CLASSES)
    },
    processScoreReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.PROCESS_SCORE)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.isSysAdmin = !!this.cUser.roles.find(item => item.code === 'SYSTEM_ADMIN')
      this.filter.organizationId = (this.cUser.orgId ? this.cUser.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
        this.getSystemParameter('MANAGEMENT_ROLES'),
      ])
      const managementRoles = this.systemParameter ? JSON.parse(this.systemParameter) : []
      if (this.cUser.teacherId && !this.cUser.roles.some(item => managementRoles.includes(item.code))) {
        this.setTeachers([{
          value: this.cUser.teacherId,
          label: this.cUser.fullName,
        }])
        this.filter.teacherId = this.cUser.teacherId
      } else {
        await this.getTeacher({ organizationId: this.filter.organizationId, itemsPerPage: 1000 })
      }

      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.onTrainingSystemsChange(this.trainingSystems[0].value)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setTeachers: 'dropdown/SET_TEACHERS',
    }),
    ...mapActions({
      getMainData: 'creditClass/getData',
      deleteMainData: 'creditClass/deleteData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
      getProgrammeSubjects: 'dropdown/getProgrammeSubjects',
      createPlanData: 'creditClass/createPlanData',
      bulkApproveData: 'creditClass/bulkApproveData',
      getSubjects: 'dropdown/getSubjects',
      getTeacher: 'dropdown/getTeachers',
      getClasses: 'dropdown/getClasses',
      getSystemParameter: 'creditClass/getSystemParameter',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses({ trainingSystemId: this.filter.trainingSystemId })
      if (event !== null) {
        if (this.courses.length > 0) {
          this.filter.courseId = this.courses[0].value
        }
      }
      await this.onCoursesChange(this.filter.courseId)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      const request = { ...this.filter }
      delete request.itemsPerPage
      await Promise.all([
        this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.filter.courseId, status: null }),
        this.getMajors(request),
        this.getProgrammes(request),
      ])
      if (this.courseSemesters.length > 0) {
        this.filter.courseSemesterId = this.courseSemesters[0].value
      }
      if (this.majors.length > 0) {
        this.filter.majorId = this.majors[0].value
      }
      await this.getClasses({
        organizationId: getUser().orgId,
        trainingSystemId: this.filter.trainingSystemId,
        courseId: this.filter.courseId,
        majorId: this.filter.majorId,
      })
    },
    async onDepartmentChange(event) {
      this.filter.subjectId = null
      await this.getSubjects({ organizationId: getUser().orgId, departmentId: this.filter.departmentId, programmeId: this.filter.programmeId })
      if (event !== null && this.subjects.length > 0) {
        this.filter.subjectId = this.subjects[0].value
      }
    },
    async onProgrammeChange(event) {
      this.filter.subjectId = null
      await this.getSubjects({ organizationId: getUser().orgId, programmeId: this.filter.programmeId })
      if (event !== null && this.subjects.length > 0) {
        this.filter.subjectId = this.subjects[0].value
      }
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      this.filter.classId = null
      await this.getProgrammes(this.filter)
      if (event !== null) {
        if (this.programmes.length > 0) {
          this.filter.programmeId = this.programmes[0].value
        }
      }
      await this.getClasses({
        organizationId: getUser().orgId,
        trainingSystemId: this.filter.trainingSystemId,
        courseId: this.filter.courseId,
        majorId: this.filter.majorId,
      })
    },
    onCreateMainData() {
      this.currentMainData = undefined
      this.isCopy = false
    },
    onCopyMainData() {
      this.currentMainData = undefined
      this.isCopy = true
    },
    onEditMainData(mainData) {
      this.isCopy = false
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onBulkInsertMainData() {
      if (!this.filter.courseId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn khóa học',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.filter.majorId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn chuyên ngành',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.filter.programmeId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn chương trình đào tạo',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.filter.courseSemesterId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn học kỳ',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.$bvModal.show('planCreditClassModal')
    },
    onAssignTeacher(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.setModalTitle(mainData)
      this.$refs['modal-assign-teacher'].show()
    },
    async reloadPage() {
      await this.getMainDataFromStore()
    },
    async importStudent(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.setModalTitle(mainData)
      this.$refs['modal-import-student'].show()
    },
    async syncScoreLms(data) {
      this.dataSyncScoreLms = data
      this.$bvModal.show('modal-sync-lms')
    },
    onAddStudent(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.setModalTitle(mainData)
      this.$refs['modal-assign-student'].show()
    },
    onAssignRoom(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.setModalTitle(mainData)
      this.$refs['modal-assign-room'].show()
    },
    openNotifications(data) {
      this.dataSendClass.groupId = data.id
      this.dataSendClass.type = 2
      this.$refs['modal-class-notification'].show()
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn Rút bớt lớp ${this.resourceName.fullName}? <span class="text-danger">${mainData.code}</span>`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainDataFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'subjectName')) this.updateParams({ name: columnFilters.subjectName })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    selectionChanged(params) {
      this.selectedMainData = params ? params.selectedRows : []
    },
    getClassName(data) {
      let name = ''
      name = data.subjectName
      if (data.theoryCreditClassId) {
        name += ' (Lớp TH)'
      }
      return name
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    setModalTitle(mainData) {
      this.currentName = mainData.subjectName
      this.currentCode = mainData.code
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    async onSearchMainData() {
      await this.getMainDataFromStore()
    },
    progressLms(data) {
      this.$bvModal.show('modal-class-progress-lms')
      this.dataSendProgressLms = data
    },
    onShowProcessScores(data) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = data
      this.currentMainData = rest
      this.subjectTypeCode = this.currentMainData.subjectTypeCode
      this.updatable = this.processScoreUpdatable(data)
    },
    onCreatePlanCreditClass() {
      // VALIDATE
      if (this.planCreditClasses.length <= 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Chưa tạo được lớp tín chỉ theo CTĐT! Vui lòng chọn lớp cần tạo theo danh sách!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }

      this.$swal({
        title: `Bạn chắc chắn muốn tạo ${this.resourceName.fullName} theo danh sách dự kiến này?`,
        text: 'Bạn có thể cập nhật thông tin và sinh viên trong lớp sau khi tạo!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.createPlanData(this.planCreditClasses)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainDataFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async onSuccessModal(modal) {
      this.$bvModal.hide(modal)
      await this.getMainDataFromStore()
    },
    onApproveCreditClass() {
      this.$swal({
        title: `Bạn chắc chắn muốn duyệt các ${this.resourceName.fullName} đã chọn?`,
        text: `${this.selectedMainData.map(item => item.code).join(', ')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const request = this.selectedMainData.map(creditClass => ({ id: creditClass.id, code: creditClass.code }))
            const response = await this.bulkApproveData(request)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainDataFromStore()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onShowCreditClassDocumentModal(id) {
      this.creditClassId = id
      this.$bvModal.show('creditClassDocumentModal')
    },
    processScoreActionTitle(creditClass) {
      return this.processScoreUpdatable(creditClass) ? 'Nhập điểm sinh viên' : 'Xem điểm sinh viên'
    },
    processScoreUpdatable(creditClass) {
      if (creditClass.isScoreLocked) return false
      if (creditClass.isPractice) {
        return this.cUser.roles.some(item => item.code === RoleCode.P_DAO_TAO)
      }
      const { teacherId } = this.cUser
      if (!teacherId || !creditClass.teacherIds) return false
      const teacherIds = creditClass.teacherIds.split(',')
      return teacherIds.includes(teacherId.toString())
    },
  },
}
</script>
<style lang="scss">
.research-table {
  min-height: 300px;
}
</style>
